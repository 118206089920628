import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, AlertController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { I18nService } from '@app/core';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { EmailService } from '@app/core/mail/email.service';
import { AccountService } from '@app/core/auth/account.service';
export class AgentNotificationComponent {
    constructor(navParams, userActivityService, emailService, alertCtrl, i18nService, accountService) {
        this.navParams = navParams;
        this.userActivityService = userActivityService;
        this.emailService = emailService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.accountService = accountService;
    }
    ngOnInit() {
        this.createForm();
        this.getPopData();
    }
    createForm() {
        this.formData = new FormGroup({
            name: new FormControl(''),
            phone: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
            note: new FormControl('', Validators.required)
        });
        this.noteFormCtrl = this.formData.controls['note'];
    }
    getPopData() {
        if (this.navParams.data.propType === '') {
            this.defaultMsg = null;
        }
        else {
            this.defaultMsg = this.navParams.data.defaultMsg;
            this.noteFormCtrl.setValue(this.defaultMsg);
            this.listingId = this.navParams.data.listingId;
            this.brokerId = this.navParams.data.brokerId;
            this.popupCtrl = this.navParams.data.popupCtrl;
            this.pageURLFrom = this.navParams.data.pageURLFrom;
        }
        const currentUser = this.accountService.getCurrentUser;
        if (currentUser != null) {
            this.formData.controls['name'].setValue(`${currentUser.firstName} ${currentUser.lastName}`);
            this.formData.controls['email'].setValue(`${currentUser.email}`);
            const phones = currentUser.phones;
            if (phones != null && phones.length > 0) {
                let phone = phones.find(phone => phone.phoneType === 'PHONE_NUMBER');
                if (phone != null && phone.phoneNumber.length > 0) {
                    this.formData.controls['phone'].setValue(phone.phoneNumber);
                }
                else {
                    phone = phones.find(phone => phone.phoneType === 'WHATSAPP');
                    if (phone != null) {
                        this.formData.controls['phone'].setValue(phone.phoneNumber);
                    }
                }
            }
        }
    }
    sendMessage() {
        if (this.formData.invalid) {
            this.showAlert('Please enter the required fields');
            return;
        }
        else {
            // send contact email
            this.registerUserActivity(UserActivityType.BROKER_SENDMESSAGE);
            this.sendEmailNotification();
        }
    }
    registerUserActivity(userActivityType) {
        const userActivity = {
            module: userActivityType,
            brokerId: this.brokerId,
            listingId: this.listingId
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
    sendEmailNotification() {
        const listingAgentEmailRequest = {
            name: this.formData.value.name,
            phone: this.formData.value.phone,
            email: this.formData.value.email,
            message: this.noteFormCtrl.value,
            listingId: this.listingId,
            listingAgentId: this.brokerId,
            pageURLFrom: this.pageURLFrom
        };
        const wasEmailSent = this.emailService.sendListingMessage(listingAgentEmailRequest);
        const alertMsg = wasEmailSent
            ? this.i18nService.get('global.form.sendMsgToAgent.emailSuccessMessage')
            : this.i18nService.get('global.form.sendMsgToAgent.emailFailureMessage');
        this.showAlert(alertMsg);
    }
    showAlert(alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl
                .create({
                header: this.i18nService.get('global.form.sendMsgToAgent.notification'),
                subHeader: alertMsg,
                buttons: [
                    {
                        text: this.i18nService.get('global.form.sendMsgToAgent.ok'),
                        handler: () => {
                            if (this.formData.valid) {
                                this.popupCtrl.dismiss();
                            }
                        }
                    }
                ]
            })
                .then(alert => {
                alert.present();
            });
        });
    }
    rowsFromTextArea() {
        if (this.noteFormCtrl.invalid && this.formData.controls['email'].invalid) {
            return 4;
        }
        else if (this.noteFormCtrl.invalid || this.formData.controls['email'].invalid) {
            return 6;
        }
        return 7;
    }
}
