import { I18nService } from '../i18n.service';
import * as i0 from "@angular/core";
import * as i1 from "../i18n.service";
export var CustomSpotSearchType;
(function (CustomSpotSearchType) {
    CustomSpotSearchType["MARKET"] = "MARKET";
    CustomSpotSearchType["STATE"] = "STATE";
    CustomSpotSearchType["CITY"] = "CITY";
})(CustomSpotSearchType || (CustomSpotSearchType = {}));
export class AppRouteNames {
}
AppRouteNames.MARKET_URL_PARAM = 'market';
AppRouteNames.MARKET_URL_PARAM_CL = 'mercado';
AppRouteNames.SEARCH = 'search';
AppRouteNames.SEARCH_COUNTRYLANG = 'resultado';
AppRouteNames.LEASE_URL_PARAM = 'lease';
AppRouteNames.LEASE_URL_PARAM_CL = 'renta';
AppRouteNames.SALE_URL_PARAM = 'sale';
AppRouteNames.SALE_URL_PARAM_CL = 'venta';
AppRouteNames.OFFICE_URL_PARAM = 'office';
AppRouteNames.OFFICE_URL_PARAM_CL = 'oficina';
AppRouteNames.INDUSTRIAL_URL_PARAM_CL = 'industrial';
AppRouteNames.INDUSTRIAL_URL_PARAM = 'warehouse';
AppRouteNames.SAVED_SEARCH_URL_PARAM = 'saved-search';
AppRouteNames.SAVED_SEARCH_URL_PARAM_CL = 'salvar-busqueda';
AppRouteNames.FILTER_URL_PARAM = 'filter';
AppRouteNames.FILTER_URL_PARAM_CL = 'filtro';
AppRouteNames.SPOTSET_URL_PARAM = 'spotset';
AppRouteNames.SPOTSET_URL_PARAM_CL = 'busqueda';
AppRouteNames.COMPANY_ROUTE = 'company';
AppRouteNames.COMPANY_ROUTE_CL = 'empresa';
AppRouteNames.USR_PROFILE_ROUTE = 'profile';
AppRouteNames.USR_PROFILE_ROUTE_CL = 'perfil';
AppRouteNames.TERMS_ROUTE = 'terms-of-use';
AppRouteNames.TERMS_ROUTE_CL = 'terminos-de-uso';
AppRouteNames.PRIVACY_POLICY_ROUTE = 'privacy-policy';
AppRouteNames.PRIVACY_POLICY_ROUTE_CL = 'politica-de-privacidad';
AppRouteNames.MY_SPOTS_ROUTE = 'my-spots';
AppRouteNames.MY_SPOTS_ROUTE_CL = 'mis-spots';
export class AppRouteNamesService {
    constructor(i18n) {
        this.i18n = i18n;
        this.getSearchRouteName = () => {
            let searchRouteName = '';
            if (this.i18n.isLangEN()) {
                searchRouteName = AppRouteNames.SEARCH;
            }
            else {
                searchRouteName = AppRouteNames.SEARCH_COUNTRYLANG;
            }
            return searchRouteName;
        };
        this.getDefaultInitSearchRouteName = () => {
            let lease = `${AppRouteNames.LEASE_URL_PARAM_CL}`;
            let office = `${AppRouteNames.OFFICE_URL_PARAM_CL}`;
            if (this.i18n.isLangEN()) {
                lease = `${AppRouteNames.LEASE_URL_PARAM}`;
                office = `${AppRouteNames.OFFICE_URL_PARAM}`;
            }
            return `/${lease}/${office}`;
        };
        this.getSavedSearchRouteName = () => this.i18n.isLangEN() ? AppRouteNames.SAVED_SEARCH_URL_PARAM : AppRouteNames.SAVED_SEARCH_URL_PARAM_CL;
        this.getFilterRouteName = () => this.i18n.isLangEN() ? AppRouteNames.FILTER_URL_PARAM : AppRouteNames.FILTER_URL_PARAM_CL;
        this.getSpotSetRouteName = () => this.i18n.isLangEN() ? AppRouteNames.SPOTSET_URL_PARAM : AppRouteNames.SPOTSET_URL_PARAM_CL;
        this.getCompanyRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.COMPANY_ROUTE : AppRouteNames.COMPANY_ROUTE_CL);
        this.getTermsRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.TERMS_ROUTE : AppRouteNames.TERMS_ROUTE_CL);
        this.getPrivacyPolyRouteName = () => this.i18n.isLangEN() ? AppRouteNames.PRIVACY_POLICY_ROUTE : AppRouteNames.PRIVACY_POLICY_ROUTE_CL;
        this.getMySpotsRouteName = () => (this.i18n.isLangEN() ? AppRouteNames.MY_SPOTS_ROUTE : AppRouteNames.MY_SPOTS_ROUTE_CL);
        this.getMarketRoutePathName = () => this.i18n.isLangEN() ? AppRouteNames.MARKET_URL_PARAM : AppRouteNames.MARKET_URL_PARAM_CL;
        this.routeNamesDictionary = new Map();
        this.routeNamesDictionaryCL = new Map();
        this.routeNamesDictionary.set(AppRouteNames.MY_SPOTS_ROUTE, AppRouteNames.MY_SPOTS_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.USR_PROFILE_ROUTE, AppRouteNames.USR_PROFILE_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.SEARCH, AppRouteNames.SEARCH_COUNTRYLANG);
        this.routeNamesDictionary.set(AppRouteNames.MARKET_URL_PARAM, AppRouteNames.MARKET_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.LEASE_URL_PARAM, AppRouteNames.LEASE_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.OFFICE_URL_PARAM, AppRouteNames.OFFICE_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.SAVED_SEARCH_URL_PARAM, AppRouteNames.SAVED_SEARCH_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.FILTER_URL_PARAM, AppRouteNames.FILTER_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.SPOTSET_URL_PARAM, AppRouteNames.SPOTSET_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.COMPANY_ROUTE, AppRouteNames.COMPANY_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.TERMS_ROUTE, AppRouteNames.TERMS_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.PRIVACY_POLICY_ROUTE, AppRouteNames.PRIVACY_POLICY_ROUTE_CL);
        for (const [english, cl] of this.routeNamesDictionary.entries()) {
            this.routeNamesDictionaryCL.set(cl, english);
        }
    }
    removeLeadingAndTrailingSlashes(route) {
        return route.replace(/^\/|\/$/g, '');
    }
    findRouteAlternative(routeName) {
        let tmpRouteName = this.i18n.removeLanguageFromURL(routeName);
        routeName = this.removeLeadingAndTrailingSlashes(tmpRouteName);
        let newRoute = null;
        if (this.i18n.isLangEN()) {
            newRoute = this.translateSentence(this.routeNamesDictionaryCL, routeName);
        }
        else {
            newRoute = this.translateSentence(this.routeNamesDictionary, routeName);
        }
        if (!newRoute) {
            newRoute = tmpRouteName;
        }
        return this.i18n.addCurrentLanguageToPath(newRoute);
    }
    translateSentence(translationDictionary, sentence) {
        const joinCh = '/';
        const words = sentence.split(joinCh);
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (translationDictionary.has(word)) {
                words[i] = translationDictionary.get(word);
            }
        }
        return words.join(joinCh);
    }
    findCustomSearchType(url) {
        let result = undefined;
        const urlPathParams = url.split('/');
        if (url.startsWith(`/${this.getSearchRouteName()}`)) {
            if (urlPathParams.length > 5) {
                if (url.includes(this.getMarketRoutePathName())) {
                    result = CustomSpotSearchType.MARKET;
                }
                else if (urlPathParams.length == 7) {
                    result = CustomSpotSearchType.CITY;
                }
                else if (urlPathParams.length == 6) {
                    result = CustomSpotSearchType.STATE;
                }
            }
        }
        return result;
    }
}
AppRouteNamesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppRouteNamesService_Factory() { return new AppRouteNamesService(i0.ɵɵinject(i1.I18nService)); }, token: AppRouteNamesService, providedIn: "root" });
