import { Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyMessageComponent } from './my-message/my-message.component';
import { ListingAnalyticsComponent } from './listing-analytics/listing-analytics.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { CanDeactivateGuard } from '@app/guards/can-deactivate.guard';
const ɵ0 = {
    title: extract('User Account')
}, ɵ1 = {
    title: extract('User Account')
}, ɵ2 = {
    title: extract('My Messages')
}, ɵ3 = {
    title: extract('My Messages')
}, ɵ4 = {
    title: extract('Listing Analytics')
}, ɵ5 = {
    title: extract('Listing Analytics')
};
const routes = [
    Shell.childRoutesRunningGuardsResolversAlways([
        { path: '', redirectTo: '/', pathMatch: 'full' },
        {
            path: 'user-home/my-account',
            component: MyAccountComponent,
            canDeactivate: [CanDeactivateGuard],
            data: ɵ0
        },
        {
            path: 'user-home/my-account/:language',
            component: MyAccountComponent,
            canDeactivate: [CanDeactivateGuard],
            data: ɵ1
        },
        {
            path: 'user-home/my-messages',
            component: MyMessageComponent,
            canActivate: [AuthGuard],
            data: ɵ2
        },
        {
            path: 'user-home/my-messages/:language',
            component: MyMessageComponent,
            canActivate: [AuthGuard],
            data: ɵ3
        },
        {
            path: 'user-home/listing-analytics',
            component: ListingAnalyticsComponent,
            canActivate: [AuthGuard],
            data: ɵ4,
            runGuardsAndResolvers: 'always'
        },
        {
            path: 'user-home/listing-analytics/:language',
            component: ListingAnalyticsComponent,
            canActivate: [AuthGuard],
            data: ɵ5,
            runGuardsAndResolvers: 'always'
        }
    ])
];
export class UserHomeRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
