import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
export class LocationService {
    constructor(http) {
        this.http = http;
        this.statesAPI = `/api/states`;
        this.statesByCompanyAndTypeAPI = `/api/states/company/`;
        this.citiesEndpoint = `/api/cities/with-listings`;
        this.countryStatesEndpoint = `/api/states/with-listings`;
        this.stateByCountryEndpoint = '/api/statesByCountry/properties?buildingTypeId=';
        this.citiesByStateEndpoint = '/api/citiesByMultipleStates/properties?buildingTypeId=';
        this.statesWithSpotsEndpoint = '/api/statesByCountry/spots?buildingTypeId=';
        this.citiesByStateWithSpotsEndpoint = '/api/citiesByMultipleStates/spots?buildingTypeId=';
    }
    getStatesByIds(stateIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.statesAPI, stateIds)
                .toPromise()
                .then((response) => {
                const listings = response;
                return listings;
            });
        });
    }
    getStatesByCompanyAndSpotBuildingType(companyId, spotType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.statesByCompanyAndTypeAPI}${companyId}/${spotType}`).toPromise();
        });
    }
    getStatesByBrokerIdAndSpotBuildingType(brokerId, spotType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .get(`${this.statesByCompanyAndTypeAPI}${brokerId}/${spotType}?brokerId=${brokerId}`)
                .toPromise();
        });
    }
    getCities() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.citiesEndpoint}`).toPromise();
        });
    }
    getStates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.countryStatesEndpoint).toPromise();
        });
    }
    getStatesByCountry(buildingTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.stateByCountryEndpoint}${buildingTypeId}&countryId=2001`).toPromise();
        });
    }
    getcitiesByState(buildingTypeId, stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(`${this.citiesByStateEndpoint}${buildingTypeId}&stateIds=${stateId}`).toPromise();
        });
    }
    getStatesWithSpots(buildingTypeId, spotType, propertySubTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!propertySubTypeId) {
                return yield this.http
                    .get(`${this.statesWithSpotsEndpoint}${buildingTypeId}&spotType=${spotType}&countryId=2001`)
                    .toPromise();
            }
            return yield this.http
                .get(`${this.statesWithSpotsEndpoint}${buildingTypeId}&spotType=${spotType}&countryId=2001&propertySubtypeId=${propertySubTypeId}`)
                .toPromise();
        });
    }
    getCitiesByStatesWithSpots(buildingTypeId, stateId, spotType, propertySubtypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!propertySubtypeId)
                return yield this.http
                    .get(`${this.citiesByStateWithSpotsEndpoint}${buildingTypeId}&stateIds=${stateId}&spotType=${spotType}`)
                    .toPromise();
            return yield this.http
                .get(`${this.citiesByStateWithSpotsEndpoint}${buildingTypeId}&stateIds=${stateId}&spotType=${spotType}&propertySubtypeId=${propertySubtypeId}`)
                .toPromise();
        });
    }
    putRioAndSaoPauloOnTop(states) {
        if (states && states.length > 1) {
            for (var i = 0; i < states.length; ++i) {
                var state = states[i];
                if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
                    states.splice(i, 1);
                    if (states[0].name.includes('São Paulo')) {
                        states.splice(1, 0, state);
                    }
                    else {
                        states.splice(0, 0, state);
                    }
                }
            }
        }
        return states;
    }
}
