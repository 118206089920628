import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
export class SpotSearchService extends BaseService {
    constructor(http) {
        super();
        this.http = http;
        this.spotSearchAPI = '/api/spot-search/';
    }
    save(dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .post(this.spotSearchAPI, dto, {
                observe: 'response'
            })
                .pipe(map(val => {
                return val.body;
            }))
                .toPromise();
        });
    }
    update(dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http
                .put(this.spotSearchAPI, dto, {
                observe: 'response'
            })
                .pipe(map(val => {
                return val.body;
            }))
                .toPromise();
        });
    }
    getAll() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.spotSearchAPI).toPromise();
        });
    }
}
