import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, QueryList, OnDestroy } from '@angular/core';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { AccountService } from '@app/core/auth';
import { SiilaChatService } from '@app/chat/siila-chat.service';
import { Socket } from 'ngx-socket-io';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { CommonGenericService } from '@app/core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
export class SiilaChatSupportComponent {
    constructor(chatSupportService, siilaChatService, account, socket, myMessagesServices, accountService, socketIO, commonSvc, deviceDetectorService) {
        this.chatSupportService = chatSupportService;
        this.siilaChatService = siilaChatService;
        this.account = account;
        this.socket = socket;
        this.myMessagesServices = myMessagesServices;
        this.accountService = accountService;
        this.socketIO = socketIO;
        this.commonSvc = commonSvc;
        this.deviceDetectorService = deviceDetectorService;
        this.messageInput = '';
        this.getCurrentUser();
        window.addEventListener('keydown', evt => {
            this.enterPress(evt);
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.messageDataSubscription = this.chatSupportService.messageData.subscribe(messageData => {
                this.messageData = messageData;
            });
            yield this.chatSupportService.populateMessageData();
            this.loadPhotos();
            this.socketIO.updateUnreadMessages();
        });
    }
    ngAfterViewInit() {
        this.ionTextAreaSubscription = this.inputMessageComponent.changes.subscribe((textArea) => {
            textArea.last.getInputElement().then(result => {
                result.focus();
            });
        });
    }
    ngOnDestroy() {
        this.ionTextAreaSubscription.unsubscribe();
        this.messageDataSubscription.unsubscribe();
    }
    enterPress(e) {
        switch (e.keyCode) {
            case 13:
                e.preventDefault();
                this.sendMessage();
                break;
        }
    }
    loadPhotos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let photos = yield this.account.getProfilePicture('spot@siila.com.br').toPromise();
            this.spotAccountName = yield this.chatSupportService.getUsernameInfo('spot@siila.com.br');
            if (this.spotAccountName) {
                this.spotAccountFullname = `${this.spotAccountName.firstName} ${this.spotAccountName.lastName}`;
            }
            if (photos) {
                this.spotAccountImage = '/images/' + photos[photos.length - 1] + '/thumbnail/m';
            }
        });
    }
    sendMessage() {
        if (this.messageInput && this.messageInput.trim().length) {
            const messageEvent = {
                conversationId: this.messageData.conversationId,
                content: this.messageInput,
                userId: this.currentUser.login,
                createdAt: new Date(),
                filename: null,
                type: 'text',
                notifyList: this.messageData.userIds
            };
            this.siilaChatService.sendMessage(messageEvent);
            this.messageInput = null;
        }
    }
    getCurrentUser() {
        this.userProfileImage = localStorage.getItem('profileImage');
        this.currentUser = this.account.currentUser$.getValue();
        if (this.currentUser) {
            this.userFullname = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
        }
    }
    scrollOnFocus(element) {
        const os = this.deviceDetectorService.os;
        const isAndriodIos = os.toLowerCase() === 'android' || os.toLowerCase() === 'ios';
        if (isAndriodIos) {
            element.el.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
