import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NewsService } from '../services/news.service';
import { IonSlides } from '@ionic/angular';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';
export class NewsComponent {
    constructor(newsService, breakpointObserver, i18nService) {
        this.newsService = newsService;
        this.breakpointObserver = breakpointObserver;
        this.i18nService = i18nService;
        this.companyId = null;
        this.currenPage = 0;
        this.isMobile = false;
        this.isFirstEnd = false;
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
            if (this.isMobile) {
                this.slideOpts = {
                    initialSlide: 0,
                    slidesPerView: 1,
                    isBeginningSlide: true
                };
            }
            else {
                if (window.innerWidth > 1000 && window.innerWidth < 1200) {
                    this.slideOpts = {
                        initialSlide: 0,
                        slidesPerView: 2,
                        isBeginningSlide: true
                    };
                }
                else {
                    this.slideOpts = {
                        initialSlide: 0,
                        slidesPerView: 3,
                        isBeginningSlide: true
                    };
                }
            }
        });
        this.lang = this.i18nService.getCurrentLanguage();
    }
    ngOnInit() {
        this.getInitNews();
    }
    getInitNews() {
        this.newsService
            .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 2 : 6, null, 67, this.companyId)
            .subscribe((resp) => {
            this.articles = resp;
        });
    }
    openHomeNews() {
        window.open(environment.serverUrl + '/resource/', this.isMobile ? '_self' : '_blank');
    }
    nextSlide() {
        this.checkisEnd();
    }
    prevSlide() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.checkisBeginning(1);
        });
    }
    checkisBeginning(prev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isBeginning = yield this.slider.isBeginning();
            this.slideOpts.isBeginningSlide = isBeginning;
            if (!isBeginning && prev == 1) {
                this.slider.slidePrev();
                const isBeginning = yield this.slider.isBeginning();
                this.slideOpts.isBeginningSlide = isBeginning;
            }
        });
    }
    checkisEnd(value = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.checkisBeginning(2);
            const isEnd = yield this.slider.isEnd();
            if (isEnd && !this.articles.last) {
                this.currenPage += 1;
                this.newsService
                    .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 1 : 3, null, 67, this.companyId)
                    .subscribe((resp) => {
                    const news = resp;
                    news.content.forEach((n) => this.articles.content.push(n));
                    setTimeout(() => {
                        if (value == null) {
                            this.slider.slideNext();
                        }
                    }, 500);
                });
            }
            else {
                this.slider.slideNext();
            }
        });
    }
    slideReachEnd() {
        this.checkisEnd(1);
    }
}
