import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';
export class PrivacyPolicyDialogComponent {
    constructor(ppDialogRef, accountService, auth, toastCtrl, i18nService, data) {
        this.ppDialogRef = ppDialogRef;
        this.accountService = accountService;
        this.auth = auth;
        this.toastCtrl = toastCtrl;
        this.i18nService = i18nService;
        this.data = data;
        this.acceptedPolicy = false;
        this.loading = false;
        this.toastPresent = false;
        this.date = data.date;
        this.acceptPrivacyPolicy = data.acceptPrivacyPolicy;
        this.openPrivacyReview = data.openPrivacyReview;
    }
    onDismiss() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ppDialogRef.close();
            yield this.auth.logout();
        });
    }
    showPrivacyPolicyErr() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.toastPresent) {
                this.toastPresent = true;
                const toast = yield this.toastCtrl.create({
                    message: this.i18nService.get('global.privacy-policy.dialog.error'),
                    duration: 3000,
                    animated: true,
                    keyboardClose: true,
                    cssClass: 'toast-error'
                });
                toast.onDidDismiss().then(() => {
                    this.toastPresent = false;
                });
                return yield toast.present();
            }
        });
    }
    onSubmit() {
        this.loading = true;
        this.acceptPrivacyPolicy().subscribe(() => {
            this.loading = false;
            const user = Object.assign({}, this.accountService.getCurrentUser, { ppAcceptedVersion: this.date });
            localStorage.setItem('user', JSON.stringify(user));
            this.accountService.currentUser$.next(user);
            this.ppDialogRef.close();
        }, error => {
            this.loading = false;
            this.showPrivacyPolicyErr();
            console.error('There was an error accepting privacy policy: ', error);
        });
    }
}
