import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
export class AccountService {
    constructor(http, platformId) {
        this.http = http;
        this.platformId = platformId;
        this.currentUser$ = new BehaviorSubject(this.getCurrentUser);
        this.seenPersonalDataFlagKey = 'seenPersonalDataPopup';
        this.endpoint = `api/account`;
        this.externalIpEndpoint = `api/account/ip`;
        this.unreadMessagesEndpoint = `api/userChat/unreadConversationsFromSupport`;
        this.publicProfilePhoto = 'api/account/photoByUserLogin';
        this.keepConnectedEndpoint = 'api/account/keep-connected';
        this.acceptPersonalDataEndpoint = '/api/account/acceptPersonalData/';
        this.changeLangAPI = '/api/account/change-language/';
    }
    get getCurrentUser() {
        return !!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    }
    get getPersonalDataFlag() {
        return localStorage.getItem(this.seenPersonalDataFlagKey)
            ? JSON.parse(localStorage.getItem(this.seenPersonalDataFlagKey))
            : null;
    }
    getAccount() {
        return this.http.get(this.endpoint);
    }
    getProfilePicture(login) {
        return this.http.get(`${this.publicProfilePhoto}/${login}`, { observe: 'response' }).pipe(map(val => val.body));
    }
    getAccountFast() {
        return this.http.get(`${this.endpoint}/deviceFast`);
    }
    getUserIP() {
        return this.http.get(`${this.externalIpEndpoint}`).toPromise();
    }
    getUnreadMessagesCount() {
        return this.http.get(`${this.unreadMessagesEndpoint}`);
    }
    saveUserProfile(userProfile) {
        return this.http.post(this.endpoint, userProfile);
    }
    activateUserWithHash(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(`api/account/hash/${hash}/activate`, null).toPromise();
        });
    }
    getUserPhotoList() {
        return this.http.get('api/account/photos');
    }
    changeUserEmail(login, newEmail) {
        return this.http.post(`/api/account/${login}/change-email`, newEmail);
    }
    changeUserPassword(passwordProps) {
        return this.http.post('/api/account/change-password', passwordProps);
    }
    setKeepConnected(keepConnectedData) {
        return this.http.put(this.keepConnectedEndpoint, keepConnectedData).subscribe(() => {
            const user = Object.assign({}, this.getCurrentUser, { keepConnected: keepConnectedData.keepConnected });
            localStorage.setItem('user', JSON.stringify(user));
            this.currentUser$.next(user);
        }, error => {
            throw Error(error);
        });
    }
    getUploadedImageId(imageList) {
        let uploadedImage = {};
        if (!imageList.length) {
            uploadedImage.originalImageId = null;
            uploadedImage.profileImageId = null;
        }
        else if (imageList.length < 2) {
            uploadedImage.originalImageId = imageList[0];
            uploadedImage.profileImageId = imageList[0];
        }
        else {
            uploadedImage.originalImageId = imageList[imageList.length - 2];
            uploadedImage.profileImageId = imageList[imageList.length - 1];
        }
        return uploadedImage;
    }
    acceptPersonalData(date) {
        return this.http.post(`${this.acceptPersonalDataEndpoint}${date}`, {}).subscribe(() => { }, error => { });
    }
    accountLogout() {
        localStorage.removeItem(this.seenPersonalDataFlagKey);
        this.currentUser$.next(null);
    }
    changeLanguage(language) {
        return this.http.post(this.changeLangAPI, language).toPromise();
    }
}
