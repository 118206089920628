import * as tslib_1 from "tslib";
import { HttpService } from '@app/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonGenericService } from '@app/core/common.service';
export class SiilaChatSupportService {
    //Makes api requests to conversation service
    constructor(http, commonSvc) {
        this.http = http;
        this.commonSvc = commonSvc;
        this.messageData = new BehaviorSubject({
            messages: []
        });
    }
    populateMessageData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getSupportMessages(0, 10).then((messageData) => {
                this.messageData.next(messageData);
            });
        });
    }
    appendMessages(newMessage) {
        let currentMessageData = this.messageData.getValue();
        currentMessageData.messages = [...currentMessageData.messages, newMessage];
        currentMessageData.messages = this.commonSvc.sortDateDesc(currentMessageData.messages, 'createdAt');
        this.messageData.next(currentMessageData);
    }
    getSupportMessages(index, count) {
        return this.http
            .get(`/api/conversationWithSupport?messageIndex=${index || 0}&messageCount=${count || 10}`)
            .pipe(map((messageData) => (Object.assign({}, messageData, { messages: messageData.messages ? this.commonSvc.sortDateDesc(messageData.messages, 'createdAt') : [] }))))
            .toPromise();
    }
    refreshUserChat(userId) {
        return this.http.get(`/api/userChat/${userId}/refresh`).toPromise();
    }
    getUsernameInfo(userId) {
        return this.http.get(`api/users/fast/${userId}`).toPromise();
    }
    updateUnreadMessages(userId, conversationId) {
        return this.http.put(`api/conversation/${conversationId}/unread/${userId}`, {}).toPromise();
    }
}
