import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { ImageService } from '@app/core/image.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
export class ChangePasswordComponent {
    constructor(fb, registerService, router, imgService, deviceDetectorService, loadingController, alertCtrl) {
        this.fb = fb;
        this.registerService = registerService;
        this.router = router;
        this.imgService = imgService;
        this.deviceDetectorService = deviceDetectorService;
        this.loadingController = loadingController;
        this.alertCtrl = alertCtrl;
        this.passwordLength = false;
        this.passwordMatch = false;
        this.isCompleted = false;
        this.uniqueEmailError = false;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.lastStep = false;
        this.selectedCompany = [];
        this.allCompanies = [];
        this.stepIndex = 0;
        this.isLoaded = false;
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.ios = false;
        this.android = false;
    }
    ngOnInit() {
        this.createForm();
        this.detectDevice();
    }
    portChange(event) {
        this.selectedCompany = event.value;
    }
    detectDevice() {
        if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
            this.ios = true;
        }
        else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
            this.android = true;
        }
    }
    showUploadError() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.registerService.showUploadErr();
        });
    }
    changePassword() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let changePasswordObj = {};
            changePasswordObj.newPassword = this.registerForm.controls.emailGroup.value.password;
            changePasswordObj.secretKey = this.registerService.getKeyForChangePassword();
            this.registerService
                .changePassword(changePasswordObj)
                .then((response) => {
                this.showPasswordChangeMessage();
            })
                .catch(err => {
                console.error('Password change failed');
            });
        });
    }
    trimValue(formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    }
    showPasswordChangeMessage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: 'New Password',
                subHeader: 'Password changed. Please log in using your new password.',
                buttons: ['OK']
            });
            alert.onDidDismiss().then(() => {
                this.router.navigateByUrl('/');
            });
            yield alert.present();
        });
    }
    createForm() {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                password: ['', Validators.compose([Validators.required])],
                confirmPass: ['', Validators.compose([Validators.required])]
            })
        }, {
            validator: this.checkFormErrors
        });
    }
    checkFormErrors(formGroup) {
        let password = formGroup.controls.emailGroup.controls['password'].value;
        let confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
        if (password.length < 6) {
            return password.length < 6 ? { passwordLength: true } : null;
        }
        else if (password !== confirmPass) {
            return password !== confirmPass ? { passwordMatch: true } : null;
        }
        if (formGroup.controls.emailGroup.status == 'INVALID') {
            return { passwordLength: true };
        }
    }
}
