import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { faChevronLeft, faChevronRight, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../services/company.service';
import { SpotBuildingsSearchService } from '../../spot-buildings/services/spot-buildings-search.service';
import { LocationService } from '../../shared/services/location.service';
import { IonSlides } from '@ionic/angular';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { MetaService } from '@app/core/seo';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
export class CompanyDetailsComponent {
    constructor(activatedRoute, i18NService, breakpointObserver, companyService, routingHelperSrv, platformConfigHelper, imageService, popOverCtrlHelper, userActivityService, spotBuildingSearchService, locationService, metaService, _location) {
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.companyService = companyService;
        this.routingHelperSrv = routingHelperSrv;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.userActivityService = userActivityService;
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.locationService = locationService;
        this.metaService = metaService;
        this._location = _location;
        this.displayScrollToTop = false;
        this.agentsDTO = [];
        this.spotBuildingNames = [];
        this.totalDtos = [];
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.searching = false;
        this.continueReading = true;
        this.screenWidth = window.innerWidth;
        this.findTotalAgentCards();
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.findTotalAgentCards();
    }
    findTotalAgentCards() {
        this.visibleAgentCards = 3;
        if (this.screenWidth < 1024) {
            this.visibleAgentCards = 1;
        }
        else if (this.screenWidth < 1200) {
            this.visibleAgentCards = 2;
        }
        this.sliderPreviewOptions = {
            slidesPerView: this.visibleAgentCards,
            spaceBetween: 100,
            pagination: true
        };
        this.updateDots();
    }
    updateDots() {
        this.totalDtos = [];
        for (const agent in this.agentsDTO) {
            if (parseInt(agent) + this.visibleAgentCards > this.agentsDTO.length) {
                break;
            }
            this.totalDtos.push(agent);
        }
    }
    ngOnInit() {
        this.sharedIcon = faShareAlt;
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.getParamsUrl();
    }
    getParamsUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.activatedRoute.params.subscribe((paramMap) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    this.companyId = paramMap.companyId;
                    this.spotBuildingSearchRequest = { companyId: this.companyId };
                    this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];
                    this.companyService.getCompanyMetadataById(this.companyId).then((companyData) => {
                        this.metaService.setCompanyMetaTagsLight(companyData);
                        window.prerenderReady = true;
                    });
                    this.companyService.getCompanyById(this.companyId).then((company) => {
                        this.company = company;
                        this.registerUserActivity(UserActivityType.PAGEVIEW_COMPANY, null, this.company.id);
                        this.companyName = company ? company.name : '';
                        this.companyBio = this.company ? this.getDefaultTranslation(this.company.biography) : '';
                        this.companyService.getPhotosByCompanyId(this.companyId).then((photos) => {
                            this.companyPhotos = photos;
                            this.companyService.getAgentsWithSpotsFrom(this.companyId).then((result) => {
                                this.agentsDTO = result;
                                this.companyService.getSpotTitlesByCompanyId(this.companyId).then((result) => {
                                    this.spotBuildingNames = result;
                                    //this.metaService.setCompanyMetaTags(this.company, this.agentsDTO, this.spotBuildingNames);
                                });
                                this.updateDots();
                            });
                        });
                    });
                }
                catch (error) {
                    console.error('Error : ', error);
                }
            }));
        });
    }
    getLargeImg(id) {
        return this.imageService.largeThumbnail(id);
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getCroppedOrOriginalImage(photo) {
        if (photo) {
            if (photo.croppedImageId) {
                return photo.croppedImageId;
            }
            return photo.imageId;
        }
    }
    openGalleryImg(event) {
        let photos = [];
        if (this.companyPhotos) {
            photos = this.companyPhotos.map(blp => {
                const photo = {
                    id: blp.id,
                    croppedImageId: blp.croppedImageId,
                    imageId: blp.imageId,
                    orderId: blp.orderId,
                    originalImageId: blp.originalImageId
                };
                return photo;
            });
        }
        this.popOverCtrlHelper.openGalleryImageComponent(event, photos, this.company.name);
    }
    buildTitleMetatag(company) {
        let metaTagTitle = '';
        const companyTitleTag = company.name;
        metaTagTitle = `${companyTitleTag} | SiiLA SPOT`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(companyTitleTag);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag };
    }
    setCompanyMetaTags() {
        const detailsMetaTags = this.metaService.buildCompanyMetatag(this.company, this.agentsDTO, this.spotBuildingNames);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = this.company.biography
            ? this.removeHTMLCode(this.i18NService.getTranslation(this.company.biography))
            : '';
        const locale = this.i18NService.getCurrentLocale();
        const companyTitle = this.i18NService.getTranslation(this.company.name);
        const fullDetailsURL = window.location.href;
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: this.company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
                    : null
            },
            {
                name: 'image',
                content: this.company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: this.company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.metaService.setDynamicTags(tags, fullTitleText);
    }
    removeHTMLCode(htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    }
    getDefaultTranslation(translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.error(e);
        }
    }
    shareCompany(event) {
        const url = window.location.href;
        const sharedTitle = this.company.name;
        const modalTitle = this.i18NService.get('ShareCompanyProfile');
        this.userActivityService.trackCompanyShare(this.company.id);
        const input = { url, sharedTitle, modalTitle };
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    }
    getNextPhoto($event) {
        this.previewSlider.getActiveIndex().then(index => {
            if (index < this.currentPhotoIndex) {
                this.currentPhotoIndex = index;
            }
            else {
                if ($event.type === 'click') {
                    this.previewSlider.slideNext();
                    this.updatePhotoIndex();
                }
                else {
                    this.updatePhotoIndex();
                }
            }
        });
    }
    updatePhotoIndex() {
        this.previewSlider.getActiveIndex().then(index => {
            if (index != this.currentPhotoIndex) {
                this.currentPhotoIndex = index;
                this.syncSliders();
            }
        });
    }
    isStartOfSlide() {
        return this.currentPhotoIndex <= 0;
    }
    isEndOfSlide() {
        return this.currentPhotoIndex + this.visibleAgentCards >= this.agentsDTO.length;
    }
    arrowsNavigation(event, index) {
        const toIndex = this.currentPhotoIndex + index;
        this.slideToIndex(event, toIndex);
    }
    slideToIndex(event, index) {
        event.stopPropagation();
        this.currentPhotoIndex = index;
        this.syncSliders();
    }
    syncSliders() {
        this.previewSlider.slideTo(this.currentPhotoIndex);
    }
    isFooterVisible(event) {
        this.displayScrollToTop = event.visible;
    }
    navigateToBroker(brokerId) {
        this.routingHelperSrv.navigateToBrokerDetailsPage(brokerId);
    }
    registerUserActivity(userActivityType, brokerId, companyId) {
        const userActivity = {
            module: userActivityType,
            brokerId: brokerId,
            companyId: companyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
}
