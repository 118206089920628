import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { ListingService, ListingDetailService, EventCheckerService } from '@app/core/listings';
import { AdminUpdatesComponent } from '@app/user-home/my-listing/admin-updates/admin-updates.component';
import { PurchaseCreditsService, I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterListingService } from '@app/core/register-listing.service';
export class MyListingEditComponent {
    constructor(data, dialog, router, alertCtrl, listingService, listingDetailService, popoverCtrl, purchaseService, _eventChecker, _ts, i18nService, registerListingService) {
        this.data = data;
        this.dialog = dialog;
        this.router = router;
        this.alertCtrl = alertCtrl;
        this.listingService = listingService;
        this.listingDetailService = listingDetailService;
        this.popoverCtrl = popoverCtrl;
        this.purchaseService = purchaseService;
        this._eventChecker = _eventChecker;
        this._ts = _ts;
        this.i18nService = i18nService;
        this.registerListingService = registerListingService;
        if (this.data._containerInstance._config.data.data) {
            let listing = this.data._containerInstance._config.data.data;
            this.fromMyListings = this.data._containerInstance._config.data.myListingPage;
            this.nextListing = listing;
            let listingId = listing.id;
            this.checkListingStatus(listingId);
        }
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.deleteDraftHeader = i18nService.get('global.my-list-menu.deleteDraft');
        this.deleteDraftSubHeader = i18nService.get('global.my-list-menu.deleteDraftSubHeader');
        this.discardChangesLbl = i18nService.get('global.discardChanges');
        this.cancelLbl = i18nService.get('Cancel');
    }
    ngOnInit() { }
    checkListingStatus(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getListing(listingId);
            this.listingStatus = this.tempListing.listingStatus;
            this.setListing(this.tempListing);
            return;
        });
    }
    getListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listingDetail = yield this.listingDetailService.getListingDetails(listingId).toPromise();
            let listingPhotos = yield this.listingDetailService.getListingPhotos(listingId).toPromise();
            this.tempListing = listingDetail.listing;
            this.tempListing.listingPhotos = listingPhotos;
        });
    }
    setListing(listing) {
        this.listing = listing;
        return this.listing;
    }
    dismissAsDialog() {
        this.dialog.closeAll();
    }
    discardDraft(event) {
        this.discardConfirmMessage();
        this.dialog.closeAll();
    }
    reactivateListing(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.displayAlert();
            this.dialog.closeAll();
        });
    }
    deactivateListing(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.deactivateAlert();
            this.dialog.closeAll();
        });
    }
    reviseListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.router.navigateByUrl(`/user-home/my-listings/create-property/${this.listing.id}`, {
                state: { listing: this.listing, myListingPage: this.fromMyListings, date: new Date().getTime() },
                replaceUrl: true,
                queryParams: { date: new Date().getTime() }
            });
            this.dismissAsDialog();
        });
    }
    showSubmitForApprovalOption() {
        return (!this.checkRole('ROLE_LISTING_ADMIN') &&
            !this.checkRole('ROLE_SPOT_ADMIN') &&
            (this.listing.listingStatus.id === 7 || this.listing.listingStatus.id === 8));
    }
    submitForApproval() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let res = yield this.registerListingService.updateToApproval(this.listing);
                if (res) {
                    this._eventChecker.updateListing$.next('refresh');
                }
                this.dismissAsDialog();
            }
            catch (error) {
                console.error('Error Submitting Listing: ', error);
            }
        });
    }
    updateReactivatedListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listing = yield this.purchaseService.reactivateListing(this.listing.id);
            if (listing) {
                this._eventChecker.updateListing$.next(listing);
                this.dismissAsDialog();
            }
        });
    }
    updateDeactivatedListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listing = yield this.purchaseService.deactivateListing(this.listing.id);
            if (listing) {
                this._eventChecker.updateListing$.next(listing);
                this.dismissAsDialog();
            }
        });
    }
    deactivateAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: this._ts.instant('global.deactivateListing'),
                subHeader: this._ts.instant('global.deactivateListingText'),
                cssClass: 'ion-alert-listings',
                buttons: [
                    {
                        text: this._ts.instant('Cancel'),
                        role: 'Cancel',
                        handler: () => { }
                    },
                    {
                        text: this._ts.instant('Ok'),
                        handler: () => {
                            this.updateDeactivatedListing();
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => {
                //this.router.navigateByUrl('/user-home/my-listings');
            });
            yield alert.present();
        });
    }
    displayAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: this._ts.instant('global.reactivateListing'),
                subHeader: this._ts.instant('global.reactivateListingText'),
                cssClass: 'ion-alert-listings',
                buttons: [
                    {
                        text: this._ts.instant('Cancel'),
                        role: 'Cancel',
                        handler: () => { }
                    },
                    {
                        text: this._ts.instant('Ok'),
                        handler: () => {
                            this.updateReactivatedListing();
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => {
                //this.router.navigateByUrl('/user-home/my-listings');
            });
            yield alert.present();
        });
    }
    adminUpdates(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialog.closeAll();
            const popover = yield this.popoverCtrl.create({
                component: AdminUpdatesComponent,
                event: ev,
                cssClass: 'popover-renewal',
                componentProps: { listing: this.listing },
                backdropDismiss: false
            });
            popover.onDidDismiss().then((popoverData) => {
                if (popoverData.data) {
                }
            });
            return yield popover.present();
        });
    }
    discardConfirmMessage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cancelTxt = this.i18nService.get('Cancel');
            const discardTxt = this.i18nService.get('global.discardChanges');
            const alert = yield this.alertCtrl.create({
                header: this.deleteDraftHeader,
                subHeader: this.deleteDraftSubHeader,
                cssClass: 'ion-alert-listings',
                buttons: [
                    {
                        text: cancelTxt,
                        role: 'Cancel',
                        handler: () => {
                            //console.log('Cancelar')
                        }
                    },
                    {
                        text: discardTxt,
                        handler: () => {
                            this.listingService
                                .deleteDraft(this.listing.id)
                                .toPromise()
                                .then((result) => {
                                this.listingService.updateModel('reload');
                                this.router.navigateByUrl('/user-home/my-listings');
                            });
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => {
                //this.router.navigateByUrl('/user-home/my-listings');
            });
            yield alert.present();
        });
    }
    checkRole(role) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    }
    isUserAllowToEditPendingApprovalListing() {
        return this.registerListingService.isUserAllowToEditPendingApprovalListing(this.listing);
    }
    isRegularUserEditingRevisionListing() {
        return (!this.checkRole('ROLE_LISTING_ADMIN') && !this.checkRole('ROLE_SPOT_ADMIN') && this.listing.listingStatus.id === 8);
    }
}
