import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18nService } from '@app/core';
import { SpotSet } from '@app/models/spotSet';
import { MessageModalService } from '@app/shared/services/message-modal.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { AlertController } from '@ionic/angular';
export class SetSpacesComponent {
    constructor(dialog, data, spotBuildingsService, alertCtrl, i18nService, messageFormDialog) {
        this.dialog = dialog;
        this.data = data;
        this.spotBuildingsService = spotBuildingsService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.messageFormDialog = messageFormDialog;
        this.spotSet = new SpotSet();
        this.listSpotSet = [];
        this.setSpotSpace = new SpotSet();
        this.addSetSpot = false;
        this.replaceSetSpot = false;
        this.removeSetSpot = false;
        this.step = 0;
        this.isMobile = false;
        this.isEdit = false;
        this.spotSetTitle = '';
    }
    ngOnInit() {
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        if (this.data.id) {
            this.isEdit = true;
            this.spotSetTitle = this.data.name;
        }
        else {
            this.spacesParameters = this.data.data;
            this.getSpotsSet();
        }
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.spaceInput.nativeElement.focus();
        }, 800);
    }
    close() {
        this.dialog.close();
    }
    changeStep(step) {
        this.step = step.index;
    }
    showMessage(header, message, keepUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const okText = this.i18nService.get('global.OK');
            const alert = yield this.alertCtrl.create({
                header: header,
                message: message,
                buttons: [okText]
            });
            alert.onDidDismiss().then(() => { });
            yield alert.present();
        });
    }
    createSpotSet() {
        let ids = [];
        this.spacesParameters.forEach(param => {
            ids.push(param.id);
        });
        this.spotSet.spotSetValue = `{\"spaceIds\":[${ids}]}`;
        this.spotBuildingsService.setSpotSpace(this.spotSet).then(val => {
            const message = this.i18nService.get('global.changesSaved');
            this.dialog.close(message);
        });
    }
    getSpotsSet() {
        this.spotBuildingsService.getSpotSet().then((val) => {
            this.listSpotSet = val.sort((a, b) => (a.name > b.name ? 1 : -1));
        });
    }
    updateSetSpot() {
        if (this.addSetSpot) {
            let spaceIds = Object.values(JSON.parse(this.setSpotSpace.spotSetValue));
            let ids = spaceIds[0];
            this.spacesParameters.forEach(param => {
                ids.push(param.id);
            });
            this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;
            this.spotBuildingsService.putSpotSet(this.setSpotSpace).then(val => {
                if (val == 200) {
                    const message = this.i18nService.get('global.changesSaved');
                    this.dialog.close(message);
                }
                else {
                    const message = this.i18nService.get('global.errorSetSpaces');
                    this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
        if (this.replaceSetSpot) {
            let ids = [];
            this.spacesParameters.forEach(param => {
                ids.push(param.id);
            });
            this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;
            this.setSpotSpace.action = 'ADD';
            this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
                if (val == 200) {
                    const message = this.i18nService.get('global.changesSaved');
                    this.dialog.close(message);
                }
                else {
                    const message = this.i18nService.get('global.errorSetSpaces');
                    this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
        if (this.removeSetSpot) {
            let ids = [];
            this.spacesParameters.forEach(param => {
                ids.push(param.id);
            });
            this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;
            this.setSpotSpace.action = 'REMOVE';
            this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
                if (val == 200) {
                    const message = this.i18nService.get('global.changesSaved');
                    this.dialog.close(message);
                }
                else {
                    const message = this.i18nService.get('global.errorSetSpaces');
                    this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
    }
    onChangeOpt(opt) {
        if (opt == 1) {
            this.addSetSpot = true;
            this.removeSetSpot = false;
            this.replaceSetSpot = false;
        }
        if (opt == 2) {
            this.addSetSpot = false;
            this.replaceSetSpot = true;
            this.removeSetSpot = false;
        }
        if (opt == 3) {
            this.addSetSpot = false;
            this.replaceSetSpot = false;
            this.removeSetSpot = true;
        }
    }
    ubdateSpotSetTitle() {
        this.setSpotSpace.name = this.spotSetTitle;
        this.setSpotSpace.id = this.data.id;
        this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
            if (val == 200) {
                const message = this.i18nService.get('global.changesSaved');
                this.dialog.close(message);
            }
            else {
                const message = this.i18nService.get('global.errorSetSpaces');
                this.messageFormDialog.openDialogMessage(message);
            }
        });
    }
}
