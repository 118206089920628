import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams } from '@ionic/angular';
import { EventCheckerService } from '@app/core/listings';
export class RenewModalComponent {
    constructor(router, purchaseCreditsService, popoverCtrl, navParams, _eventChecker) {
        this.router = router;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.navParams = navParams;
        this._eventChecker = _eventChecker;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.renewalChoice = 'activate';
    }
    ngOnInit() {
        this.getPopData();
    }
    getPopData() {
        this.expirationDate = this.navParams.data.credit.listingExpirationDate;
        this.listingClass = this.navParams.data.credit.premium;
        this.creditId = this.navParams.data.credit.id;
        this.listingId = this.navParams.data.listingId;
        this.listing = this.navParams.data.listing;
    }
    choiceSelection(event) {
        this.renewalChoice = event.detail.value;
    }
    cancel() {
        this.popoverCtrl.dismiss();
    }
    renew() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.popoverCtrl.dismiss();
            if (this.renewalChoice === 'activate') {
                let listing = yield this.purchaseCreditsService.renewListing(this.creditId, this.listingId);
                this._eventChecker.updateListing$.next(listing);
            }
            else {
                let listing = yield this.purchaseCreditsService.renewDeactivated(this.creditId, this.listingId);
                this._eventChecker.updateListing$.next(listing);
            }
            this._eventChecker.updateListing$.next('refresh');
            this.router.navigateByUrl('/user-home/my-listings');
        });
    }
    redirectTo(uri) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
    }
}
