import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { PlatformHelperService } from './helpers';
import { I18nService } from '../core/i18n.service';
import { environment } from '@env/environment';
import { LocalNumberPipe } from '../shared/pipes/number-pipe';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
export class CommonGenericService {
    constructor(i18n, modalCtrl, platformHelpService, _decimalPipe) {
        this.i18n = i18n;
        this.modalCtrl = modalCtrl;
        this.platformHelpService = platformHelpService;
        this._decimalPipe = _decimalPipe;
        this.EXISTING_REGIONS = { mx: 'Mexico', br: 'Brazil' };
        this.BLUE_PIN = `\/assets/maps/pin-blue.png`;
    }
    getUserRegion() {
        let regionISO = this.EXISTING_REGIONS.br;
        Object.keys(this.EXISTING_REGIONS).forEach(key => {
            regionISO = environment.spotServerUrl.includes(key) ? this.EXISTING_REGIONS[key] : regionISO;
        });
        return regionISO;
    }
    getLeaseTypeTxt(industrialLeaseType) {
        if (industrialLeaseType) {
            if (industrialLeaseType == 'DIRECT') {
                return this.i18n.get('spaceDetail.direct');
            }
            else {
                return this.i18n.get('spaceDetail.sublease');
            }
        }
        return '';
    }
    isBuildingExist(list, building) {
        return list.findIndex(item => item.buildingId === building.buildingId) !== -1;
    }
    getByteString(dataURI) {
        return dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    }
    convertByteToTypeArray(byteString) {
        let imageArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            imageArray[i] = byteString.charCodeAt(i);
        }
        return imageArray;
    }
    convertDataURIToFile(dataURI) {
        let byteString = this.getByteString(dataURI);
        let mimeString = dataURI
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        let imageArray = this.convertByteToTypeArray(byteString);
        return {
            mime: mimeString,
            imageArray: imageArray
        };
    }
    convertBlobToFile(blobFile, fileName) {
        return Object.assign(blobFile, {
            lastModifed: new Date(),
            name: fileName
        });
    }
    isJSON(str) {
        try {
            JSON.parse(str);
        }
        catch (error) {
            return false;
        }
        return true;
    }
    getFileNameFromPath(fileNamePath) {
        return fileNamePath
            .split('\\')
            .pop()
            .split('/')
            .pop();
    }
    getAllMatchingListing(listingList, listing) {
        return listingList.filter(item => item.buildingId === listing.buildingId);
    }
    navgiateToSiilaHomePage() {
        window.open('https://siila.com.mx', '_blank');
    }
    navgiateToSiilaNews() {
        window.open(`${environment.serverUrl}/resource`, '_blank');
    }
    changeMarkerIcon(marker, eventType, icon) {
        google.maps.event.addDomListener(marker, eventType, () => {
            if (!marker.isSelected) {
                marker.setIcon(icon);
            }
        });
    }
    getUniqueBuilding(buildingList) {
        return buildingList.reduce((acccumulator, item) => {
            const itemAlreadyExist = this.isBuildingExist(acccumulator, item);
            if (!itemAlreadyExist) {
                acccumulator.push(item);
            }
            return acccumulator;
        }, []);
    }
    createListingInfoWindow(listings) {
        const infoWindowContent = `
     <div style="height:25px; width: fit-content; height: fit-content">
      <div style="display: flex; flex-flow:column nowrap">
        <div style="color: #043650; font-weight:600"> ${this.i18n.getTranslation(listings[0].buildingTitle)} </div>
        <div> ${this.i18n.getTranslation(listings[0].buildingAddress)} </div>
        <div style="color: #ff9900; padding-top: 10px; padding-bottom: 5px; font-weight: 500">
          ${listings.length} ${listings.length === 1 ? 'listing' : 'listings'} available
        </div>
      <div>
    </div>`;
        return new google.maps.InfoWindow({
            content: infoWindowContent
        });
    }
    createMapInfoWindow(building) {
        const infoWindowContent = `
     <div style="height:25px; width: fit-content; height: fit-content">
      <div style="display: flex; flex-flow:column nowrap">
        <div style="color: #043650; font-weight:600"> ${this.i18n.getTranslation(building.title)} </div>
        <div> ${this.i18n.getTranslation(building.address)} </div>
      <div>
    </div>`;
        return new google.maps.InfoWindow({
            content: infoWindowContent
        });
    }
    createModal(modalProp) {
        return this.modalCtrl.create(modalProp);
    }
    getDefaultIntlPhoneProp() {
        const userRegionISO = this.getUserRegion();
        return {
            preferredCountries: [CountryISO[userRegionISO], CountryISO.UnitedStates],
            enablePlaceholder: true,
            enableAutoCountrySelect: false,
            searchCountryFlag: true,
            selectFirstCountry: false,
            maxLength: 15,
            validation: true,
            selectedCountryIso: '',
            phoneValidation: true,
            tooltipField: TooltipLabel.Name,
            separateDialCode: true
        };
    }
    convertImageBase64ToFile(dataURI, fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.convertImageBase64ToFileSync(dataURI, fileName);
        });
    }
    convertImageBase64ToFileSync(dataURI, fileName) {
        const isSafari = this.platformHelpService.isIosChrome();
        const isChrome = this.platformHelpService.isIosChrome();
        let imageData = this.convertDataURIToFile(dataURI);
        let imageFile;
        if (isSafari) {
            const blobFile = new Blob(imageData.imageArray, { type: imageData.mime });
            imageFile = this.convertBlobToFile(blobFile, fileName);
        }
        else if (isChrome) {
            imageFile = new File([imageData.imageArray], fileName, { type: imageData.mime });
        }
        else {
            imageFile = new File([imageData.imageArray], fileName, { type: imageData.mime });
        }
        return imageFile;
    }
    getValidImageExension() {
        return ['.jpg', '.jpeg', '.bmp', '.png', '.gif', '.tif'];
    }
    validateExtension(exts, fileName) {
        return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$', 'i').test(fileName);
    }
    getLanguageOption() {
        return [
            {
                label: 'global.enUS',
                value: 'en'
            },
            {
                label: 'global.esMX',
                value: 'es'
            },
            {
                label: 'global.ptBR',
                value: 'pt-br'
            }
        ];
    }
    getLanguage(langs, index) {
        const key = langs[index];
        const languages = {
            en: 'global.languageChoice.english',
            es: 'global.languageChoice.spanish',
            'pt-br': 'global.languageChoice.brasilportuguese'
        };
        return languages[key];
    }
    getItemIndex(items, key) {
        return items.findIndex(item => item === key);
    }
    updateLocalStorageData(localStorageName, newData) {
        localStorage.removeItem(localStorageName);
        localStorage.setItem(localStorageName, JSON.stringify(newData));
    }
    isObjectEmpty(object) {
        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    isPropertyType(buildingTypeId, listing) {
        const listingBuildingType = listing.hasOwnProperty('building')
            ? listing.building.buildingType.id
            : listing.buildingTypeId;
        return listingBuildingType === buildingTypeId;
    }
    santizeListingSearchDTO(searchModel) {
        let copyOfSearchModel = Object.assign({}, searchModel);
        let listSearchDTO = {};
        copyOfSearchModel.propertyType =
            copyOfSearchModel.propertyType && copyOfSearchModel.propertyType === 'all'
                ? null
                : copyOfSearchModel.propertyType;
        copyOfSearchModel.listingType =
            copyOfSearchModel.listingType && copyOfSearchModel.listingType === 'all' ? null : copyOfSearchModel.listingType;
        listSearchDTO.buildingTypes = copyOfSearchModel.propertyType ? [copyOfSearchModel.propertyType] : null;
        listSearchDTO.listingLeaseOrSale = copyOfSearchModel.listingType ? [copyOfSearchModel.listingType] : null;
        listSearchDTO.keyword = copyOfSearchModel.keywordText ? copyOfSearchModel.keywordText : '';
        return listSearchDTO;
    }
    santizeListingSearchModel(model) {
        if (model.hasOwnProperty('listingType')) {
            model.listingType = model.listingType === 'all' ? null : model.listingType;
        }
        if (model.hasOwnProperty('propertyType')) {
            model.propertyType = model.propertyType === 'all' ? null : model.propertyType;
        }
        return model;
    }
    createMapMarker(property, infoWindow) {
        return new google.maps.Marker({
            position: {
                lat: property.latitude,
                lng: property.longitude
            },
            map: null,
            draggable: false,
            icon: this.BLUE_PIN
        });
    }
    setArbitraryArray(lists) {
        return lists && lists.length
            ? lists.map((item, index) => {
                return index;
            })
            : [];
    }
    isBeginingOfSlide(currentSlideIndex, prevSlideIndex, numberOfPhoto) {
        return [
            currentSlideIndex === 0 && prevSlideIndex === 1,
            currentSlideIndex === 0 && !prevSlideIndex,
            currentSlideIndex === 0 && prevSlideIndex === numberOfPhoto
        ].some(condition => condition);
    }
    isEndOfSlide(currentSlideIndex, prevSlideIndex, numberOfPhoto) {
        return [
            currentSlideIndex === numberOfPhoto && prevSlideIndex === numberOfPhoto - 1,
            currentSlideIndex === numberOfPhoto && !prevSlideIndex
        ].some(condition => condition);
    }
    checkApplicationEnvironment(environment) {
        return window.location.host.includes(environment);
    }
    transformArrayStringList(lists, attr) {
        let transformList = lists.map(item => {
            return item[attr];
        });
        return transformList.join(', ');
    }
    translateLocaleText(lists, attr) {
        return lists.map(item => {
            const isValidJSON = this.isJSON(item[attr]);
            item[attr] = isValidJSON ? this.i18n.getTranslation(item[attr]) : item[attr];
            return item;
        });
    }
    santizeMultiLanguageRawData(formData) {
        let sanitizeData = {};
        formData.forEach((item) => {
            Object.keys(item).forEach(key => {
                Object.assign(sanitizeData, { [key]: item[key] });
            });
        });
        return JSON.stringify(sanitizeData);
    }
    removeObjectAttr(objects, keys) {
        const newObjests = Object.assign({}, objects);
        keys.forEach(key => {
            if (newObjests.hasOwnProperty(key)) {
                delete newObjests[key];
            }
        });
        return newObjests;
    }
    sortDateDesc(dates, attr) {
        return dates.sort((first, second) => {
            return new Date(second[attr]) - new Date(first[attr]);
        });
    }
    deleteNullAttributesFrom(listingSearchDto) {
        Object.keys(listingSearchDto).forEach(key => listingSearchDto[key] == null && delete listingSearchDto[key]);
        return listingSearchDto;
    }
    modalProps(component, props, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            component: component,
            componentProps: props,
            cssClass: cssClass
        };
    }
    capitalizeFirstLetter(word) {
        if (!word || word.length == 0) {
            return word;
        }
        word = word.toLowerCase();
        const str = word.split(' ');
        for (var i = 0, x = str.length; i < x; i++) {
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
        return str.join(' ');
    }
    getFormatedDate(date) {
        if (date instanceof Date) {
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            if (month - 10 < 0) {
                month = '0' + month;
            }
            if (day - 10 < 0) {
                day = '0' + day;
            }
            return month + '-' + day + '-' + year;
        }
    }
    getPropertyTypeText(buildingTypeId) {
        if (buildingTypeId) {
            switch (buildingTypeId) {
                case 1001:
                    return this.i18n.get('global.list-your-property.industrial');
                case 2001:
                    return this.i18n.get('global.list-your-property.office');
            }
        }
        return 'not-found';
    }
    getTypeText(type) {
        if (type) {
            switch (type) {
                case 'L':
                    return 'Lease';
                case 'S':
                    return 'Sale';
                case 'C':
                    return 'Coworking';
                default:
                    return '';
            }
        }
        return 'not-found';
    }
    getAvailableAreaText(spaceRangesDTO, spotBuildingType) {
        if (spotBuildingType && spotBuildingType == 'C') {
            return this.getPositions(spaceRangesDTO);
        }
        if (spaceRangesDTO) {
            const rangeDTO = spaceRangesDTO;
            const minValue = this.formatNumberTo(rangeDTO.minArea);
            const maxValue = this.formatNumberTo(rangeDTO.maxArea);
            if (minValue != maxValue && parseFloat(minValue) !== 0) {
                return `${minValue} m² - ${maxValue} m²`;
            }
            else if (minValue != maxValue && parseFloat(minValue) === 0) {
                return `${maxValue} m²`;
            }
            else {
                return `${maxValue} m²`;
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAvgAskingRentNearby(spotBuilding, spotBuildingType) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minAskingPriceOrRent;
            const maxValue = rangeDTO.maxAskingPriceOrRent;
            /*  const decimals = spotBuildingType && spotBuildingType == 'S' ? 0 : 2; */
            if (minValue != maxValue && minValue !== 0) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue /* , decimals */)} - ${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue /* , decimals */)}`;
            }
            else if (minValue != maxValue && minValue === 0) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue /* , decimals */)}`;
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue /* , decimals */)}`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAvgAskingRent(spotBuilding, spotBuildingType, currencyType) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = currencyType && currencyType == 'MXN' ? rangeDTO.minAskingPriceOrRent : rangeDTO.minAskingPriceOrRentUsd;
            const maxValue = currencyType && currencyType == 'MXN' ? rangeDTO.maxAskingPriceOrRent : rangeDTO.maxAskingPriceOrRentUsd;
            /*  const decimals = spotBuildingType && spotBuildingType == 'S' ? 0 : 2; */
            if (minValue != maxValue && minValue !== 0) {
                return `${currencyType ? currencyType : spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, 0)} - ${currencyType ? currencyType : spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, 0)}`;
            }
            else if (minValue != maxValue && minValue === 0) {
                return `${currencyType ? currencyType : spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, 0)}`;
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return `${currencyType ? currencyType : spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, 0)}`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAskingPrice(spotBuilding) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minAskingPrice;
            const maxValue = rangeDTO.maxAskingPrice;
            if (minValue != maxValue) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, 0)} - ${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, 0)}`;
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue)}`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAskingPricePerArea(spotBuilding) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minAskingPricePerArea;
            const maxValue = rangeDTO.maxAskingPricePerArea;
            const decimals = 2;
            if (minValue != maxValue) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, decimals)}/m² - ${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, decimals)}/m²`;
            }
            else {
                if (minValue != 0 && minValue != null) {
                    return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, decimals)}/m²`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getMonthlyRentPerPerson(spotBuilding) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minMonthlyCost;
            const maxValue = rangeDTO.maxMonthlyCost;
            if (minValue != maxValue) {
                return `${spotBuilding.preferredCurrency}${this.formatNumberTo(minValue)} - ${spotBuilding.preferredCurrency}${this.formatNumberTo(maxValue)}`;
            }
            else if (minValue != 0 && minValue != null) {
                return `${spotBuilding.preferredCurrency}${this.formatNumberTo(minValue)}`;
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getPositions(spaceRangesDTO) {
        if (spaceRangesDTO) {
            const rangeDTO = spaceRangesDTO;
            let minValue = rangeDTO.minPositions;
            let maxValue = rangeDTO.maxPositions;
            if (minValue != maxValue) {
                minValue = minValue == 0 ? 1 : minValue;
                maxValue = maxValue == 0 ? 1 : maxValue;
                return `${this.formatNumberTo(minValue, 0)} - ${this.formatNumberTo(maxValue, 0)}`;
            }
            else {
                minValue = minValue == 0 ? 1 : minValue;
                return `${this.formatNumberTo(minValue, 0)}`;
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAskingRent(spotBuilding) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minAskingRent;
            const maxValue = rangeDTO.maxAskingRent;
            if (minValue != maxValue && (minValue != 0 && minValue != null)) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue)} - ${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue)}`;
            }
            else {
                if (maxValue != 0 && maxValue != null) {
                    return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue)}`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    getAskingRentPerArea(spotBuilding) {
        if (spotBuilding.spaceRangesDTO) {
            const rangeDTO = spotBuilding.spaceRangesDTO;
            const minValue = rangeDTO.minAskingRentPerArea;
            const maxValue = rangeDTO.maxAskingRentPerArea;
            const decimals = 2;
            if (minValue != maxValue && (minValue != 0 && minValue != null)) {
                return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(minValue, decimals)}/m² - ${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, decimals)}/m²`;
            }
            else {
                if (maxValue != 0 && maxValue != null) {
                    return `${spotBuilding.preferredCurrency} ${this.formatNumberTo(maxValue, decimals)}/m²`;
                }
            }
        }
        return this.i18n.get('global.list-your-property.negotiable');
    }
    formatNumberTo(value, decimals) {
        if (decimals == null || decimals == undefined)
            return this._decimalPipe.transform(value, `1.2-2`);
        if (decimals >= 0) {
            const numberFormat = `1.${decimals}-${decimals}`;
            return this._decimalPipe.transform(value, numberFormat);
        }
    }
    getAvailableAreaLabel(spot) {
        let key = 'global.availableArea';
        if (spot.type == 'C') {
            key = 'spaceDetail.availablePositions';
        }
        return this.i18n.get(key) + ':';
    }
    getAskingRentPriceLabel(spot) {
        let key = 'buildinsDetail.askingRentCard';
        if (spot.type == 'S') {
            key = 'buildinsDetail.askingPriceCard';
        }
        return this.i18n.get(key) + ':';
    }
    isMobileWidth() {
        if (window.innerWidth < 700) {
            return true;
        }
        return false;
    }
    mapCoworkingType(coworkingType) {
        if (CoworkingSpaceType.PRIVATE_OFFICE == coworkingType) {
            return this.i18n.get('buildinsDetail.privateOffice');
        }
        if (CoworkingSpaceType.DEDICATED_WORKSPACE == coworkingType) {
            return this.i18n.get('buildinsDetail.dedicatedWorkspace');
        }
        if (CoworkingSpaceType.OPEN_WORKSPACE == coworkingType) {
            return this.i18n.get('buildinsDetail.openWorkspace');
        }
        if (CoworkingSpaceType.FULL_FLOOR_OFFICES == coworkingType) {
            return this.i18n.get('buildinsDetail.fullFloorOffices');
        }
    }
    goToHomePage() {
        let homePage = '/';
        if (this.i18n.currentUrlContainsCurrentLanguage()) {
            homePage = this.i18n.concatLanguagePathURL(homePage);
        }
        return homePage;
    }
}
