import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
export class EditCropImageModalComponent {
    constructor(modalCtrl, commonService, sanitizer) {
        this.modalCtrl = modalCtrl;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.showCroppingToggle = false;
        this.fileName = null;
        this.UPLOAD_FILE_SIZE_LIMIT = 20000000;
        this.imageCropperOptions = {};
        this.imageChangedEvent = {};
        this.canvasRotation = 0;
        this.rotation = 0;
        this.transform = {};
        /*  Image based 64 */
        this.principalImageToEdit = null;
        this.croppedImage = null;
        this.cropOn = false;
        this.removedImage = false;
        this.replacedImage = false;
        this.restoreToOriginalImage = false;
        this.croppedImageWidth = '200px';
        this.croppedImageHeight = '150px';
        this.originalImageUrl = null;
        this.validImage = true;
    }
    ngOnInit() {
        this.getPrincipalImage();
        this.imageCropperOptions = this.getImageCropperOptions();
        if (this.originalImageId) {
            this.originalImageUrl = `/images/${this.originalImageId}/thumbnail/ml`;
        }
    }
    getImageCropperOptions() {
        return {
            maintainAspectRatio: 'true',
            cropperMinWidth: 128,
            cropperMinHeight: 128,
            resizeToWidth: 200,
            format: 'png',
            roundCropper: false,
            onlyScaleDown: true,
            aspectRatio: 4 / 3
        };
    }
    getPrincipalImage() {
        if (this.newImageBased64) {
            this.principalImageToEdit = this.newImageBased64;
        }
        else if (this.imageUrlToEdit) {
            this.convertToDataURL(this.imageUrlToEdit, (dataURL) => {
                this.principalImageToEdit = dataURL;
            });
        }
    }
    restoreToOriginalImg() {
        if (this.originalImageUrl) {
            this.convertToDataURL(this.originalImageUrl, (dataURL) => {
                this.principalImageToEdit = dataURL;
                this.replacedImage = false;
                this.restoreToOriginalImage = true;
            });
        }
    }
    convertToDataURL(imageUrl, callback) {
        let xhr = new XMLHttpRequest();
        this.fileName = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = () => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    }
    closeModal(data) {
        this.modalCtrl.dismiss(data);
    }
    resetFileInput(ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    fileUploadErrorHandler(action) {
        const infoModalParams = {
            headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
            bodyMessage: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
        };
        this.imageChangedEvent = null;
        this.principalImageToEdit = null;
        this.fileName = null;
        this.resetFileInput(document.getElementById('inputImageFile'));
        this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
    }
    openInfoModal(headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalProps = {
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'generic-info-modal',
                component: InfoModalComponent,
                componentProps: {
                    headerTitle: headerTitle,
                    bodyMessage: bodyMessage,
                    data: data,
                    extraData: extraData
                }
            };
            const infoModal = yield this.commonService.createModal(modalProps);
            infoModal.onWillDismiss().then((result) => { });
            return infoModal.present();
        });
    }
    imageCropped(image) {
        if (image && image.cropperPosition) {
            this.croppedImageWidth = image.cropperPosition.x2 - image.cropperPosition.x1 + 'px';
            this.croppedImageHeight = image.cropperPosition.y2 - image.cropperPosition.y1 + 'px';
            this.croppedImage = image.base64;
        }
    }
    fileChangedEvent(event, remove, replaced) {
        this.removedImage = remove;
        this.replacedImage = replaced;
        this.validImage = false;
        if (remove) {
            this.croppedImage = null;
            this.imageChangedEvent = null;
            this.principalImageToEdit = null;
        }
        else {
            const imageExtensions = this.commonService.getValidImageExension();
            const isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);
            if (!isValidImage) {
                this.fileUploadErrorHandler('invalid-file');
                return;
            }
            else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
                this.fileUploadErrorHandler('exceed-limit');
                return;
            }
            this.validImage = true;
            this.imageChangedEvent = event;
            this.principalImageToEdit = event.srcElement.files[0];
            this.fileName = event.srcElement.files[0].name;
            let fileReader = new FileReader();
            fileReader.onload = () => {
                this.principalImageToEdit = fileReader.result;
            };
            fileReader.readAsDataURL(this.principalImageToEdit);
        }
    }
    cancel() {
        this.closeModal(null);
    }
    convertImageToFile(imgBase64) {
        return this.commonService.convertImageBase64ToFileSync(imgBase64, this.fileName);
    }
    saveImage() {
        const originalImageFile = this.convertImageToFile(this.principalImageToEdit);
        const croppedImageFile = this.croppedImage ? this.convertImageToFile(this.croppedImage) : null;
        this.closeModal({
            originalImage: { imgBase64: this.principalImageToEdit, file: originalImageFile },
            croppedImage: { imgBase64: this.croppedImage, file: croppedImageFile },
            wasImageCropped: this.cropOn,
            wasOriginalImgReplaced: this.replacedImage,
            originalImageId: this.originalImageId,
            wasRestoreToOriginalImage: this.restoreToOriginalImage
        });
    }
    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }
    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }
    flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = Object.assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    }
    flipHorizontal() {
        this.transform = Object.assign({}, this.transform, { flipH: !this.transform.flipH });
    }
    flipVertical() {
        this.transform = Object.assign({}, this.transform, { flipV: !this.transform.flipV });
    }
    resetImage() {
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }
    croppingImageValueChanged(event) { }
    showCroppedImage() {
        if (this.cropOn && !this.removedImage) {
            return true;
        }
        return false;
    }
    showNotCroppedImage() {
        const showCroppedImage = this.showCroppedImage();
        if (!showCroppedImage) {
            if (!this.imageUrlToEdit || this.imageUrlToEdit == null) {
                if (!this.principalImageToEdit || this.principalImageToEdit == null) {
                    return false;
                }
            }
            if (!this.validImage) {
                return false;
            }
            return !this.removedImage;
        }
        return !showCroppedImage;
    }
    getNotCroppedImage() {
        if (this.imageUrlToEdit && !this.cropOn && !this.replacedImage) {
            return this.imageUrlToEdit;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.principalImageToEdit);
    }
    showLoadOption() {
        return this.removedImage || !this.cropOn;
    }
}
