import * as tslib_1 from "tslib";
export class ClassHelperService {
    constructor() {
        this.classes = [
            { name: 'FEATURED', id: 0 },
            { name: 'PREMIUM', id: 1 },
            { name: 'PROMOTED', id: 2 },
            { name: 'BASIC', id: 3 }
        ];
    }
    getClassIds() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let arr = [];
            Object.keys(this.selectClass).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                arr.push(this.selectClass[key].id);
            }));
            return arr;
        });
    }
    updateSelectedClassesBasedOnDTO(listingSearchDto) {
        this.selectClass = [];
        if (listingSearchDto.listingClasses) {
            if (listingSearchDto.listingClasses.length > 0) {
                listingSearchDto.listingClasses.forEach(classTag => {
                    const classFound = this.classes.find(cl => cl.id == classTag);
                    if (classFound) {
                        this.selectClass.push(classFound);
                    }
                });
            }
        }
    }
    removeClass(listingClass) {
        this.selectClass.forEach((item, index) => {
            if (item.id == listingClass.id) {
                this.selectClass.splice(index, 1);
                return;
            }
        });
    }
}
