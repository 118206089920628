import { environment } from '@env/environment';
import { rxStompServiceFactory } from '@stomp/ng2-stompjs';
// TODO REMOVE
import { RxStompConfig } from '@stomp/rx-stomp';
import { SocketIoConfig } from 'ngx-socket-io';
const config = { url: environment.tdmessageUrl, options: {} };
const ɵ0 = RxStompConfig, ɵ1 = rxStompServiceFactory;
// export function SockFactory() {
//   return new (window as any).SockJS(environment.sockJs);
// }
export class UserHomeModule {
}
export { ɵ0, ɵ1 };
