import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { ReportSpacesComponent } from '@app/spot-buildings/components/detail/report-spaces/report-spaces.component';
import { SetSpacesComponent } from '@app/spot-buildings/components/detail/set-spaces/set-spaces.component';
import { faTrash, faEllipsisV, faEdit, faBookOpen, faSearch, faFilter, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { ToastController } from '@ionic/angular';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
export class SpotSetsComponent {
    constructor(favoriteServices, i18nService, dialog, toastCtrl, messageFormDialog, breakpointObserver, router, routingHelperSrv) {
        this.favoriteServices = favoriteServices;
        this.i18nService = i18nService;
        this.dialog = dialog;
        this.toastCtrl = toastCtrl;
        this.messageFormDialog = messageFormDialog;
        this.breakpointObserver = breakpointObserver;
        this.router = router;
        this.routingHelperSrv = routingHelperSrv;
        this.eventSpotSet = new EventEmitter();
        this.pageSize = 10;
        this.page = 0;
        this.collection = 0;
        this.seeSearch = false;
        this.isMobile = false;
        this.filterCount = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        this.trash = faTrash;
        this.ellipsisV = faEllipsisV;
        this.edit = faEdit;
        this.bookOpen = faBookOpen;
        this.search = faSearch;
        this.filter = faFilter;
        this.arrowDown = faArrowCircleDown;
        this.getSpotSets(0);
    }
    getSpotSets(page) {
        this.page = page;
        const lang = this.i18nService.getCurrentLanguage();
        const bodySpotSet = {
            sortBy: this.param,
            direction: this.direction,
            titleFilter: this.filterSpotSet
        };
        this.favoriteServices.getSpotSetFavorite(page, lang, bodySpotSet).then((value) => {
            this.spotSetList = value;
            this.collection = this.spotSetList.totalElements;
            this.pageSize = this.spotSetList.size;
            this.eventSpotSet.emit(this.spotSetList.totalElements);
        });
    }
    changePage(page) {
        this.getSpotSets(page - 1);
    }
    searchProperty() {
        this.seeSearch = !this.seeSearch;
    }
    searchFilter() {
        if (this.filterCount == 0) {
            this.filterCount = 1;
            setTimeout(() => {
                this.getSpotSets(0);
                this.filterCount = 0;
            }, 2000);
        }
    }
    editSet(item) {
        let dialogRef = this.dialog.open(SetSpacesComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                title: true,
                id: item.id,
                name: item.name
            }
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.getSpotSets(0);
                this.presentToast(val);
            }
        });
    }
    createTourBook(item) {
        let dialogRef = this.dialog.open(ReportSpacesComponent, {
            width: this.isMobile ? '100%' : '60%',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                ids: item.spaceIds,
                name: item.name
            }
        });
    }
    deleteSet(id) {
        let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                message: this.i18nService.get('favorites.spotSets.deleteSet'),
                id: id,
                apiName: '/api/spotset/'
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(val => {
            if (val) {
                this.presentToast(this.i18nService.get('favorites.deleteMessage'));
                this.getSpotSets(0);
            }
        });
    }
    nameAscending() {
        this.param = 'name';
        this.direction = 'ASC';
        this.getSpotSets(0);
    }
    nameDescending() {
        this.param = 'name';
        this.direction = 'DESC';
        this.getSpotSets(0);
    }
    oldestFirst() {
        this.param = 'date';
        this.direction = 'ASC';
        this.getSpotSets(0);
    }
    newestFirst() {
        this.param = 'date';
        this.direction = 'DESC';
        this.getSpotSets(0);
    }
    presentToast(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: message,
                duration: 3500,
                animated: true,
                keyboardClose: true,
                cssClass: 'toast-alert-detail'
            });
            toast.onDidDismiss().then(() => { });
            return yield toast.present();
        });
    }
    propertiesText(message) {
        this.messageFormDialog.openDialogMessage(message);
    }
    goBrowseProperties() {
        this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
    }
    goToSpotSetSearch(item) {
        this.routingHelperSrv.navigateToSpotSetSearchPage(item);
    }
}
