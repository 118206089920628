import * as tslib_1 from "tslib";
import { AccountService } from '@app/core/auth/account.service';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { HttpClient } from '@angular/common/http';
export function socketFactory() {
    //return new (window as any).SockJS(environment.sockJs);
}
export class SocketIOService {
    constructor(
    //private socket: Socket,
    chatSupportService, myMessageServices, http, account) {
        this.chatSupportService = chatSupportService;
        this.myMessageServices = myMessageServices;
        this.http = http;
        this.account = account;
        this.unreadMessagesEndpoint = `api/userChat/unreadConversationsFromSupport`;
        this.connected$ = new BehaviorSubject(false);
        this.connected = false;
    }
    connect() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            /* this.setEvents();
            if (!!localStorage.getItem('user') && !!localStorage.getItem('token')) {
              const user = JSON.parse(localStorage.getItem('user'));
              const chatRefresh: any = await this.chatSupportService.refreshUserChat(user.login);
              if (!this.connected) {
                this.connected = true;
                console.log('Open socket connection');
                this.socket.connect();
                this.socket.emit('auth', {
                  token: (chatRefresh || {}).token,
                  user: user.login
                });
              }
              this.connected$.next(true);
            } */
        });
    }
    setEvents() {
        /* this.socket.on('actualConnections', (connection: any) => {
          this.connected$.next(true);
        });
        this.socket.on('validatingConnection', (connection: any) => {
          console.log('validating connection --> ', connection);
        });
        this.socket.on('disconnected', (data: any) => {
          this.connected$.next(false);
          console.log('disconnect event --> ', data);
        });
        this.socket.on('message', (msg: any) => {
          this.updateUnreadMessagesByConversation(msg.conversationId);
          this.chatSupportService.appendMessages(msg);
        }); */
    }
    updateUnreadMessagesByConversation(conversationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (window.location.href.includes('my-messages') && conversationId) {
                this.myMessageServices.setUnreadCount(0);
                this.updateUnreadMessages(conversationId);
            }
            else {
                this.updateUnreadMessageCount();
            }
        });
    }
    updateUnreadMessageCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const unreadMessageInfo = yield this.getUnreadMessagesCount().toPromise();
            this.myMessageServices.setUnreadCount(unreadMessageInfo && unreadMessageInfo.unreadCount ? unreadMessageInfo.unreadCount : 0);
        });
    }
    updateUnreadMessages(msgConvId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { conversationId: currConvId } = this.chatSupportService.messageData.getValue();
            let conversationId = msgConvId || currConvId;
            const user = JSON.parse(localStorage.getItem('user'));
            this.chatSupportService
                .updateUnreadMessages(user.login ? user.login : user.profile.login, conversationId)
                .then((result) => {
                if (result.result == 'updated') {
                    this.myMessageServices.setUnreadCount(0);
                }
            });
        });
    }
    getUnreadMessagesCount() {
        return this.http.get(`${this.unreadMessagesEndpoint}`);
    }
    setCurrentUser() {
        this.currentUser = this.account.currentUser$.getValue();
    }
    disconnect() {
        // TODO: Disconnect the socket!
        this.connected = false;
        //console.log('Disconnect socket');
        //this.socket.emit('disconnect');
        //this.socket.disconnect();
        //this.socket.ioSocket.disconnect();
        //this.socket.ioSocket.io.disconnect();
    }
}
