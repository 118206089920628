import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationService } from '../services/notification.service';
import { I18nService } from '../../core/i18n.service';
export class SendNotificationComponent {
    constructor(dialog, data, notificationService, breakpointObserver, i18n) {
        this.dialog = dialog;
        this.data = data;
        this.notificationService = notificationService;
        this.breakpointObserver = breakpointObserver;
        this.i18n = i18n;
        this.searching = false;
        this.isMobile = false;
        this.predefinedId = null;
        this.yesNo = true;
        this.errorMaxLength = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        this.descriptionFieldOptions = {
            columnLabel: 'notifications.messageNotification',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
        this.notificationService.predefinedNotification().then(resp => {
            this.notificationOptions = resp;
            for (let option of this.notificationOptions) {
                option.name = this.i18n.getTranslation(option.name);
            }
        });
    }
    close() {
        this.dialog.close();
    }
    sendNotification() {
        this.searching = true;
        this.data.notify.predefinedNotificationId = this.predefinedId;
        this.notificationService.sendNotification(this.data.notify).then(() => {
            this.dialog.close(true);
        });
    }
    descriptionChange(value) {
        this.validateMaxLength(value);
        if (value.description.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.description).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.description[key];
                if (obj['en'] === '' || obj['en'] === null || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues ? (this.data.notify.content = '') : (this.data.notify.content = JSON.stringify(mergedObject));
        }
    }
    validateMaxLength(value) {
        let length1 = value.description[0] ? JSON.stringify(value.description[0]).length : '';
        let length2 = value.description[1] ? JSON.stringify(value.description[1]).length : '';
        if (length1 > 120 || length2 > 120) {
            this.errorMaxLength = true;
        }
        else {
            this.errorMaxLength = false;
        }
    }
    yesNotification() {
        this.yesNo = false;
    }
    noNotification() {
        this.dialog.close('close');
    }
}
