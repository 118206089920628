import * as tslib_1 from "tslib";
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
export class SpotCardSliderService {
    constructor(spotBuildingSearchService) {
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.sliderBreakPoints = {
            1: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 0,
                slideNextClass: 'preview-next-margin',
                slidePrevClass: 'preview-prev-margin'
            },
            900: {
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 10
            },
            1300: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 10
            }
        };
        this.defaultSliderOpts = {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            slideVisibleClass: 'swiper-slide-visible',
            grabCursor: false,
            observer: true,
            preventInteractionOnTransition: true,
            breakpoints: this.sliderBreakPoints,
            pagination: false
        };
        this.defaultSearchRequest = {
            //listingClasses: ['FEATURED'],
            isFeaturedWithoutCheckingDate: true,
            randomOrder: true
        };
    }
    searchSpotsByCompany(page, pageSize, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { body: ids } = yield this.findAllSpotBuildingIds(payload);
            const spotBuildings = yield this.getSpotBuildingsPaginated(ids, page, pageSize);
            return { spotBuildingIds: ids, spotBuildings };
        });
    }
    searchAllSpotIdsByCompany(payload) {
        return this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1);
    }
    searchSpotsByCompanyAsync(spotIds, page, pageSize) {
        return this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds);
    }
    findAllSpotBuildingIds(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1);
            return result;
        });
    }
    getSpotBuildingsPaginated(spotIds, page, pageSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let spotBuildingList = [];
            if (!spotIds || spotIds.length === 0)
                return spotBuildingList;
            spotBuildingList = yield this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds);
            return spotBuildingList;
        });
    }
}
