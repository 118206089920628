import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { environment } from '@env/environment';
import { Logger, I18nService, PlatformHelperService, LanguageService } from '@app/core';
import { MetaService } from '@ngx-meta/core';
import { PWAService } from './shared/pwa-service';
import { MatDialog } from '@angular/material';
import { AccessGrantedDialogComponent } from './shared/access-granted/access-granted.component';
import { SwUpdate } from '@angular/service-worker';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnalyticsService } from './google-analytics/analytics-service';
import { MatomoService } from './matomo/matomo-service';
import { Location } from '@angular/common';
import { ReixLangCulture } from './core/reixspotlanguages.service';
import { RoutingHelperService } from './core/helpers/routing-helper.service';
import { AppRouteNames } from './core/helpers/app-route-names.service';
import { urlRedirects } from './shared/util/params';
import { urlPromotedRedirects } from './shared/util/params';
const log = new Logger('App');
export class AppComponent {
    constructor(router, titleService, platform, statusBar, splashScreen, i18nService, meta, pwaService, platformHelperService, languageService, dialog, swUpdate, gaService$, analyticsService, matomoService, location, routingHelperSrv) {
        this.router = router;
        this.titleService = titleService;
        this.platform = platform;
        this.statusBar = statusBar;
        this.splashScreen = splashScreen;
        this.i18nService = i18nService;
        this.meta = meta;
        this.pwaService = pwaService;
        this.platformHelperService = platformHelperService;
        this.languageService = languageService;
        this.dialog = dialog;
        this.swUpdate = swUpdate;
        this.gaService$ = gaService$;
        this.analyticsService = analyticsService;
        this.matomoService = matomoService;
        this.location = location;
        this.routingHelperSrv = routingHelperSrv;
        this.isDataAvailable = false;
        this.redirectionRules();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(event => {
            if (event.url !== event.urlAfterRedirects) {
                this.setNotFoundMeta();
            }
            else {
                this.unsetNotFoundMeta();
            }
            if (event.url && event.url == '/') {
                this.routingHelperSrv.navigateToSpotHomePage();
            }
            if (event.url &&
                (event.url == `/${AppRouteNames.MY_SPOTS_ROUTE}` || event.url == `/${AppRouteNames.MY_SPOTS_ROUTE_CL}`)) {
                this.routingHelperSrv.navigateFavoritesPage();
                return;
            }
            if (!this.i18nService.hasURLLangPathParam(event.url)) {
                event.url = this.i18nService.addDefaultLanguageToPath(event.url);
                if (!this.previousURL || this.previousURL !== event.url) {
                    const state = history.state;
                    this.location.go(event.url, '', state);
                }
                else {
                    return;
                }
            }
            this.i18nService.setLanguageBasedOnURL(event.url);
            this.gaService$.pageView(window.location.pathname, this.titleService.getTitle(), window.location.href, {
                dimension1: this.platformHelperService.isInStandaloneMode() ? 'App' : 'Browser',
                dimension2: localStorage.getItem('userType') ? localStorage.getItem('userType') : 'Visitor',
                dimension3: localStorage.getItem('userCompany') ? localStorage.getItem('userCompany') : 'Visitor'
            });
            setTimeout(() => {
                this.matomoService.trackPageView2(this.titleService.getTitle());
            }, 2500);
            this.previousURL = event.url;
        });
    }
    validateUpdate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem('isDataAvailableReload');
            if (this.swUpdate.isEnabled) {
                this.swUpdate.available.subscribe(evt => {
                    if (sessionStorage.getItem('isDataAvailable') == null) {
                        this.isDataAvailable = true;
                        sessionStorage.setItem('isDataAvailable', 'true');
                        window.location.reload();
                    }
                });
            }
            this.swUpdate
                .checkForUpdate()
                .then(() => {
                this.isDataAvailable = true;
            })
                .catch(err => {
                this.isDataAvailable = true;
            });
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.validateNoIndexNoFollowTag();
            if (environment.production) {
                Logger.enableProductionMode();
            }
            this.languageService.registerCulture(ReixLangCulture.COUNTRY_LANG);
            this.languageService.registerCulture(ReixLangCulture.EN);
            let defaultLang = this.i18nService.getDefaultLang();
            if (this.i18nService.currentURLHasLangParam()) {
                defaultLang = this.i18nService.getLanguagePathURL();
            }
            this.i18nService.init(defaultLang, environment.supportedLanguages);
            if (!this.pwaService.signatureExpired() &&
                (this.platformHelperService.isIosChrome() || this.platformHelperService.isIosSafari())) {
                this.pwaService.appInstalled$.next(true);
            }
            // Cordova platform and plugins initialization
            yield this.platform.ready();
            this.onCordovaReady();
            setTimeout(() => {
                this.matomoService.init();
            }, 1000);
            setTimeout(() => {
                if (!this.isDataAvailable) {
                    this.isDataAvailable = true;
                }
                sessionStorage.setItem('isDataAvailable', 'true');
                window.prerenderReady = true;
                this.analyticsService.init();
            }, 5000);
            setTimeout(() => {
                this.validateUpdate();
                window.addEventListener('beforeinstallprompt', (evt) => {
                    evt.preventDefault();
                    this.pwaService.setDeferredPrompt(evt);
                    this.pwaService.appInstalled$.next(false);
                    this.pwaService.pwaRemoveInstallSignature();
                });
                window.addEventListener('appinstalled', (evt) => {
                    this.pwaService.appInstalled$.next(true);
                    this.pwaService.pwaSaveInstallSignature();
                });
            }, 2000);
        });
    }
    reloadWindow() {
        if (window) {
            window.location.reload();
        }
    }
    setNotFoundMeta() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.meta.setTag('prerender-status-code', '404');
        });
    }
    unsetNotFoundMeta() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.meta.removeTag('name = "prerender-status-code"');
        });
    }
    checkAccess() {
        this.dialog.open(AccessGrantedDialogComponent, { disableClose: true });
    }
    ngOnDestroy() {
        this.i18nService.destroy();
        this.routerObserver.unsubscribe();
    }
    onCordovaReady() {
        if (window['cordova']) {
            window['Keyboard'].hideFormAccessoryBar(true);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        }
    }
    validateNoIndexNoFollowTag() {
        switch (window.location.host) {
            case 'test.siilaspot.com.br':
            case 'stg.siilaspot.com.br':
            case 'stg.siilaspot.com.mx':
            case 'test.siilaspot.com.mx':
            case 'dev.siilaspot.com.br':
            case 'localhost:4200':
            case 'localhost':
                this.addNoIndexMetaTag();
                break;
            case 'siilaspot.com.br':
            case 'www.siilaspot.com.br':
            case 'siilaspot.com.mx':
            case 'www.siilaspot.com.mx':
                break;
        }
    }
    addNoIndexMetaTag() {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'robots');
        metaTag.setAttribute('content', 'noindex, nofollow');
        document.head.appendChild(metaTag);
    }
    redirectionRules() {
        var currentUrl = window.location.href;
        if (urlRedirects.has(currentUrl)) {
            window.location.replace(urlRedirects.get(currentUrl));
        }
        const result = this.findMatchingUrl(currentUrl, urlPromotedRedirects);
        if (result) {
            window.location.replace(result);
        }
    }
    findMatchingUrl(url, urlMap) {
        for (const [regex, value] of urlMap.entries()) {
            const pattern = new RegExp(regex);
            const match = url.match(pattern);
            if (match) {
                // Match found, return the corresponding value
                return value;
            }
        }
        // No match found
        return null;
    }
}
