import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@app/core/seo';
import { ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
export class SpotDetailContainerComponent {
    constructor(activatedRoute, metaService, createSpotBuildingsService) {
        this.activatedRoute = activatedRoute;
        this.metaService = metaService;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.isSpotFetched = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.getMetatags();
            yield this.getParamsUrl();
        });
    }
    getParamsUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.activatedRoute.params.subscribe(paramMap => {
                try {
                    const buildingIdParam = paramMap.buildingId;
                    const listingTypeParam = paramMap.buildingType;
                    this.createSpotBuildingsService
                        .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, paramMap.lang)
                        .then((response) => {
                        this.buildingDetail = response;
                        this.isPromoted = this.buildingDetail.listingType == ListingType.Promoted;
                        setTimeout(() => {
                            this.isSpotFetched = true;
                        }, 300);
                    });
                }
                catch (error) {
                    console.error(error);
                }
            });
        });
    }
    getMetatags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.activatedRoute.params.subscribe(paramMap => {
                try {
                    const buildingIdParam = paramMap.buildingId;
                    const listingTypeParam = paramMap.buildingType;
                    this.createSpotBuildingsService
                        .getSpotBuildingsMetadataFromBuilding(buildingIdParam, listingTypeParam)
                        .then((response) => {
                        this.metaService.setSpotBuildingMetaTags2(response, []);
                        window.prerenderReady = true;
                    });
                }
                catch (error) {
                    console.error(error);
                }
            });
        });
    }
}
