import * as tslib_1 from "tslib";
import { state } from '@angular/animations';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FavoriteSpotService } from '../../../../../../shared/services/favorite-spot.service';
export class SpotMapMobileCardComponent {
    constructor(spotBuildingsService, imageService, router, commonService, i18n, platformConfigHelper, favSpotService) {
        this.spotBuildingsService = spotBuildingsService;
        this.imageService = imageService;
        this.router = router;
        this.commonService = commonService;
        this.i18n = i18n;
        this.platformConfigHelper = platformConfigHelper;
        this.favSpotService = favSpotService;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    }
    ngOnInit() {
        this.faHeart = faHeart;
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getCroppedOrOriginalImage(listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    }
    openCard(id, $event, externalLink = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            $event.stopImmediatePropagation();
            yield this.spotBuildingsService.goToSpotDetailsURL(id).then((spotURL) => {
                this.router.navigateByUrl(spotURL, { state: state });
            });
        });
    }
    getAvailableAreaLabel(spot) {
        return this.commonService.getAvailableAreaLabel(spot);
    }
    getAvailableAreaText(spot) {
        return this.commonService.getAvailableAreaText(spot.spaceRangesDTO, spot.type);
    }
    getAvgAskingRent(spot) {
        return this.commonService.getAvgAskingRent(spot, spot.type);
    }
    buildFavBtnClass(listing) {
        const favClass = this.getFavClass(listing);
        const customClasses = `spot-info-window__title__favorite-button ${favClass} `;
        return customClasses;
    }
    getFavClass(listing) {
        return listing.favorite ? 'is-favorite' : 'no-favorite';
    }
    getCityState(listing) {
        return `${listing.id} - ${this.i18n.getTranslation(listing.cityName)} - ${this.i18n.getTranslation(listing.stateName)}`;
    }
    getSpotImg(listing) {
        const photo = this.spot && this.spot.buildingListingPhotos ? this.spot.buildingListingPhotos[0] : null;
        const photoURL = photo && photo.image ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : this.defaultImage;
        return photoURL;
    }
    favoriteListener(event, spot) {
        event.stopImmediatePropagation();
        let favBtnElement = document.getElementById('favButton');
        this.favSpotService.setFavoriteSpot(event, spot).then((resp) => {
            let currentFavClass = favBtnElement.className;
            currentFavClass = currentFavClass.replace('is-favorite', '');
            currentFavClass = currentFavClass.replace('no-favorite', '');
            favBtnElement.className = `${currentFavClass} ${this.getFavClass(spot)}`;
        });
    }
}
