import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { I18nService } from '@app/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatDialog, MatDialogRef } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { ImageService } from '@app/core/image.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { CommonGenericService } from '@app/core/common.service';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { CompleteProfileComponent } from './complete-profile/complete-profile-modal.component';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { EditImageModalComponent } from '@app/user-home/edit-image/edit-image-modal.component';
export class RegisterFormComponent {
    constructor(fb, registerService, router, commonService, deviceDetectorService, loadingController, i18nService, commonSvc, auth, dialog, analyticsService, userHomeService, userAccountService, imageService, registerDialog) {
        this.fb = fb;
        this.registerService = registerService;
        this.router = router;
        this.commonService = commonService;
        this.deviceDetectorService = deviceDetectorService;
        this.loadingController = loadingController;
        this.i18nService = i18nService;
        this.commonSvc = commonSvc;
        this.auth = auth;
        this.dialog = dialog;
        this.analyticsService = analyticsService;
        this.userAccountService = userAccountService;
        this.imageService = imageService;
        this.registerDialog = registerDialog;
        this.passwordLength = false;
        this.passwordMatch = false;
        this.isCompleted = false;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.lastStep = false;
        this.selectedCompany = [];
        this.allCompanies = [];
        this.stepIndex = 0;
        this.isLoaded = false;
        this.ios = false;
        this.android = false;
        this.intlTelephoneOptions = this.commonService.getDefaultIntlPhoneProp();
        this.logoId = null;
        this.hide = true;
        this.hide1 = true;
        this.uniqueEmailError = false;
        this.inactiveAccountExists = false;
        this.phoneError = false;
        this.companySelectedError = false;
        this.userHomeService = userHomeService;
    }
    ngOnInit() {
        this.isDesktop = this.deviceDetectorService.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetectorService.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetectorService.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.imageService.getLogoId().subscribe(val => {
            this.logoId = val;
        });
        this.createForm();
        this.detectDevice();
        this.getCompanies().then(result => {
            this.allCompanies = result;
            const notListedText = this.i18nService.get('global.register.notListed');
            this.allCompanies.unshift({ name: [notListedText], id: 0, value: null });
            /* this.isLoaded = true; */
        });
    }
    portChange(event) {
        this.selectedCompany = event /* .value */;
        /*  if (event.value.companyPhotoImageId) {
           this.image = this.imageService.mediumThumbnail(event.value.companyPhotoImageId);
           this.imageId = event.value.companyPhotoImageId;
           this.logoId = null;
         } else {
           this.image = null;
           this.imageId = null;
         } */
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    detectDevice() {
        if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
            this.ios = true;
        }
        else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
            this.android = true;
        }
    }
    presentToast() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.registerService.presentToast();
        });
    }
    showWelcomeAlert(onDismiss = () => { }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const title = this.i18nService.get('global.form.registrationComplete');
            const msg = this.i18nService.get('global.form.welcomeNewUser');
            this.registerService.showAlert(title, msg, onDismiss.bind(this));
        });
    }
    checkEmail() {
        if (this.registerForm.controls.emailGroup.value.email != '') {
            this.resetErrors();
            this.registerService
                .getEmailUnique(this.registerForm.controls.emailGroup.value.email)
                .toPromise()
                .then(resp => {
                let emailAccount = resp;
                /*  if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
                   this.inactiveAccountExists = true;
                   return false;
                 } else if (emailAccount && emailAccount.accountExists) {
                   this.uniqueEmailError = true;
                   return false;
                 } */
                if (emailAccount) {
                    this.uniqueEmailError = true;
                    return false;
                }
                return true;
            });
        }
        /*   if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
            this.uniqueEmailError = true;
            this.isCompleted = false;
            this.emailName = this.registerForm.controls.emailGroup.value.email;
            this.presentToast(); //TODO:: change this message to the one listed in ticket and should be popup not a toast
          } else if (emailAccount && emailAccount.accountExists) {
            this.uniqueEmailError = true;
            this.isCompleted = false;
            this.emailName = this.registerForm.controls.emailGroup.value.email;
            this.presentToast();
          } else {
            this.isCompleted = true;
            this.uniqueEmailError = false;
            setTimeout(() => {
              this.goForward();
            }, 0);
          } */
    }
    resetErrors() {
        this.uniqueEmailError = false;
        this.inactiveAccountExists = false;
        this.phoneError = false;
        this.companySelectedError = false;
    }
    openCompleteProfileModal() {
        if (this.auth.$isLoginSubject.value) {
            this.dialog.open(CompleteProfileComponent, {
                closeOnNavigation: true,
                disableClose: false,
                width: '300px'
            });
        }
    }
    removeLogo() {
        this.imageService.setLogoId(null);
    }
    submitBasicAccount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.validateFieldsRequired()) {
                return;
            }
            this.isLoaded = true;
            /* this.lastStep = true;*/
            const phoneNumber = this.sanitizePhoneNumbers();
            /* this.presentLoadingWithOptions(); */
            let mergedForm = Object.assign({}, this.registerForm.value.emailGroup);
            mergedForm.phoneNumber = Object.assign({}, phoneNumber);
            let userObj = this.registerService.formatUserDetails(mergedForm);
            /*  if (this.logoId || this.imageId) {
               userObj.companyLogoImageId = this.image ? this.imageId : this.logoId;
             } */
            /*   mergedForm.companyId = this.registerForm.controls.emailGroup.value.dropdown.id
                ? this.registerForm.controls.emailGroup.value.dropdown.id
                : null;
              mergedForm.companyName = this.registerForm.controls.emailGroup.value.dropdown.name
                ? this.registerForm.controls.emailGroup.value.dropdown.name
                : null;
        
              delete mergedForm['confirmPass'];
              delete mergedForm['dropdown']; */
            yield this.registerService
                .register(userObj)
                .toPromise()
                .then(() => {
                this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_GET_STARTED, this.currentDevice);
                this.isCompleted = true;
                this.isLoaded = false;
                /* this.loadingController.dismiss();
                window.history.go(-1); */
                /* this.showWelcomeAlert(this.openCompleteProfileModal); */
                /*this.auth.submitLoginCredentials(userObj.email, userObj.password).then(() => {
                  this.router.navigate([`/`]);
                  this.showWelcomeAlert(this.openCompleteProfileModal);
                });*/
            })
                .catch(() => {
                /* this.loadingController.dismiss(); */
                this.dismissAsDialog(true);
                this.isLoaded = false;
                const title = this.i18nService.get('global.register.registerErrorTitle');
                const msg = this.i18nService.get('global.register.registerErrorMessage');
                this.registerService.showAlert(title, msg);
            });
        });
    }
    validateFieldsRequired() {
        this.resetErrors();
        /*  if (!this.validatePrimaryPhoneNumber(true)) {
           this.phoneError = true;
           return false;
         }
      */
        if (this.selectedCompany.id === 0 && this.registerForm.controls.emailGroup.value.companySelected.toString() == '') {
            this.companySelectedError = true;
            return false;
        }
        return true;
    }
    /*  goForward() {
       this.stepper.next();
     } */
    /*  goBack() {
       this.lastStep = false;
       this.stepper.previous();
     } */
    /*  backButton() {
       this.uniqueEmailError = false;
       this.isCompleted = false;
       this.stepper.previous();
     } */
    /*  checkFormErrors(formGroup: any) {
       let password = formGroup.controls.emailGroup.controls['password'].value;
       let confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
       if (password.length < 6) {
         return password.length < 6 ? { passwordLength: true } : null;
       } else if (password !== confirmPass) {
         return password !== confirmPass ? { passwordMatch: true } : null;
       }
     } */
    trimValue(formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    }
    presentLoadingWithOptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingController.create({
                spinner: 'lines',
                translucent: true,
                showBackdrop: false,
                cssClass: 'spin-loading'
            });
            return yield loading.present();
        });
    }
    createForm() {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                email: ['', Validators.compose([Validators.required, Validators.email])],
                password: ['', Validators.compose([Validators.required])],
                confirmPass: ['', Validators.compose([Validators.required])],
                firstName: ['', Validators.compose([Validators.required])],
                lastName: ['', Validators.compose([Validators.required])],
                dropdown: [null, Validators.compose([Validators.required])],
                primaryPhone: ['', Validators.compose([Validators.required])],
                companySelected: ['', Validators.compose([])]
            })
        });
        /*  this.registerForm = this.fb.group(
           {
             detailGroup: this.fb.group({
               email: ['', Validators.compose([Validators.required, Validators.email])],
               password: ['', Validators.compose([Validators.required])],
               confirmPass: ['', Validators.compose([Validators.required])],
               firstName: ['', Validators.compose([Validators.required])],
               lastName: ['', Validators.compose([Validators.required])] */
        /*  companySelected: ['', Validators.compose([])],
         dropdown: ['', Validators.compose([Validators.required])],
         primaryPhone: ['', Validators.compose([Validators.required])] */
        /*    })
         },
         { validator: this.checkFormErrors }
       ); */
    }
    validatePrimaryPhoneNumber(showAlerts) {
        const primaryPhone = this.registerForm.value.emailGroup.primaryPhone;
        if (primaryPhone == null) {
            /* if (showAlerts) {
              let bodyMessage: string = 'global.user.profileForm.invalidNumber';
              this.openInfoModal('', bodyMessage);
            } */
            return false;
        }
        if (primaryPhone.errors || primaryPhone.internationalNumber.length === 0) {
            /*  const headerLabel: string = '';
             let bodyMessage: string = 'global.user.profileForm.invalidNumber'; */
            /*  if (
               (primaryPhone.errors && primaryPhone.errors.invalidNumberForRegion) ||
               primaryPhone.internationalNumber.length === 0
             ) {
               bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
             }
             if (showAlerts) {
               this.openInfoModal(headerLabel, bodyMessage);
             } */
            return false;
        }
        return true;
    }
    openInfoModal(headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalProps = {
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: 'generic-info-modal',
                component: InfoModalComponent,
                componentProps: {
                    headerTitle: headerTitle,
                    bodyMessage: bodyMessage,
                    data: data,
                    extraData: extraData
                }
            };
            const infoModal = yield this.commonService.createModal(modalProps);
            infoModal.onWillDismiss().then((result) => { });
            return infoModal.present();
        });
    }
    sanitizePhoneNumbers() {
        const formPrimaryPhoneNumber = this.registerForm.value.emailGroup.primaryPhone;
        let userPhones = {};
        if (formPrimaryPhoneNumber) {
            let obj = {
                phoneType: 'PHONE_NUMBER',
                phoneNumber: formPrimaryPhoneNumber.internationalNumber && formPrimaryPhoneNumber.length > 0
                    ? formPrimaryPhoneNumber.internationalNumber
                    : formPrimaryPhoneNumber.number,
                isoCode: formPrimaryPhoneNumber.countryCode,
                nationalNumber: '',
                countryCode: ''
            };
            obj.nationalNumber = obj.phoneNumber;
            obj.countryCode = obj.isoCode;
            userPhones = Object.assign({}, obj);
        }
        return userPhones;
    }
    getMediumImg() {
        this.image = null;
        this.imageId = null;
        return this.imageService.mediumThumbnail(this.logoId);
    }
    openEditLogoModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let imageLogos;
            let userlogo = [];
            userlogo.push(this.logoId);
            const imageLogo = this.userAccountService.getUploadedImageId(userlogo);
            imageLogos = this.userHomeService.setProfileImageProp(imageLogo);
            const componentProps = {
                commonService: this.commonService,
                openInfoModal: this.openInfoModal,
                userHomeService: this.userHomeService,
                profileImage: imageLogos,
                editLogo: true
            };
            const cssClass = 'edit-image-modal';
            const modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
            const editImageModal = yield this.commonService.createModal(modalProps);
            return yield editImageModal.present();
        });
    }
    dismissAsDialog(error) {
        this.registerDialog.close(error);
    }
}
