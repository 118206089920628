import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { I18nService } from '@app/core';
export class PurchaseCreditsService {
    constructor(http, i18NService) {
        this.http = http;
        this.i18NService = i18NService;
        this.getCredits = `api/listings/purchases`;
        this.getBuildingTypes = `api/buildings/buildingNames?buildingTypeId=`;
        this.getBuildingNamesByProperty = `api/buildings/propertyType/names?buildingTypeId=`;
        this.adminUpdate = '/api/listings/adminUpdate';
        this.listingUpdate = '/api/listings/';
    }
    getCurrentCredits(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`api/listings/user/${id}/purchases`, { observe: 'response' })
                .pipe(map(val => val.body))
                .toPromise();
        });
    }
    getUserPayments(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`api/listings/user/${id}/payments`, { observe: 'response' })
                .pipe(map(val => val.body))
                .toPromise();
        });
    }
    getUserListingPaymentActivity(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`api/listings/user/${id}/listingActivity`, { observe: 'response' })
                .pipe(map(val => val.body))
                .toPromise();
        });
    }
    getBuildingType(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`${this.getBuildingTypes}${id}`, { observe: 'response' })
                .pipe(map(val => val.body))
                .toPromise();
        });
    }
    getBuildingNamesByPropertyType(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`${this.getBuildingNamesByProperty}${id}&lang=${this.i18NService.getCurrentLanguage()}`, {
                observe: 'response'
            })
                .pipe(map(val => val.body))
                .toPromise();
        });
    }
    renewListing(listingPurchaseId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`api/listings/${listingId}/spot-listing-renew?listingPurchaseId=${listingPurchaseId}`, {
                observe: 'response'
            })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    renewDeactivated(listingPurchaseId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`api/listings/${listingId}/spot-listing-renew?listingPurchaseId=${listingPurchaseId}&deactivated=true`, {
                observe: 'response'
            })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    adminListingUpdate(listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.adminUpdate}`, listing)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    applyAction(id, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.listingUpdate}${id}/${action}`, { observe: 'response' })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    approveListing(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.listingUpdate}${id}/confirm-payment`, { observe: 'response' })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    reactivateListing(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.listingUpdate}${id}/reactivate`, { observe: 'response' })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    deactivateListing(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.listingUpdate}${id}/deactivate`, { observe: 'response' })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    spotDeactivate(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${this.listingUpdate}${id}/spot-deactivate`, { observe: 'response' })
                .pipe(map(val => val))
                .toPromise();
        });
    }
}
