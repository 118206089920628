import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
export class RenewExtendModalComponent {
    constructor(navParams, i18NService, popoverCtrl, listingService) {
        this.navParams = navParams;
        this.i18NService = i18NService;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listingIds = this.navParams.data.listingIds;
            let interpolateParams = { totalListings: this.listingIds ? this.listingIds.length : 0 };
            yield this.listingService.countActiveAndExpiredListings(this.listingIds).then((response) => {
                if (response) {
                    interpolateParams.totalListings = response.body.totalActiveExpiredListings;
                }
            });
            this.renewExtendDescription = this.i18NService.getMetatagTranslation('renewExtendListings.description', interpolateParams);
        });
    }
    checkDate(ev) { }
    closeModal(updated) {
        this.popoverCtrl.dismiss({ updated: updated });
    }
    formatDate(date) {
        var d = date, month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    updateNewExpirationDate() {
        if (this.newExpirationDate && this.listingIds) {
            this.listingService.setNewExpirationDateFor(this.listingIds, this.formatDate(this.newExpirationDate));
            this.closeModal(true);
        }
    }
}
