import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, PopoverController } from '@ionic/angular';
export class SpotSpacesBuildingsComponent {
    constructor(createSpotBuildingsService, popOverCtrlHelper, alertCtrl, i18n, popoverCtrl) {
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.alertCtrl = alertCtrl;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.fileName = null;
        this.loading = false;
    }
    ngOnInit() {
        this.spacesParameters = [];
    }
    ngOnChanges(changes) {
        if (this.SpotBuildingsResponse) {
            this.createSpotBuildingsService
                .getSpaceConditions(this.SpotBuildingsResponse.id)
                .then((response) => {
                this.spacesParameters = response;
            });
        }
    }
    downloadExcel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            let url = yield this.createSpotBuildingsService.getUrlExcel(this.SpotBuildingsResponse.id);
            this.createSpotBuildingsService.downloadExcel(url.fileName);
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        });
    }
    fileChangedEvent(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.fileUpload = event.srcElement.files[0];
            this.fileName = event.srcElement.files[0].name;
        });
    }
    processTemplate() {
        this.loading = true;
        this.createSpotBuildingsService
            .createSpacesBuilding(this.fileUpload, this.SpotBuildingsResponse.id)
            .then((resp) => {
            this.createSpotBuildingsService
                .getSpaceConditions(this.SpotBuildingsResponse.id)
                .then((response) => {
                this.spacesParameters = null;
                this.spacesParameters = response;
                this.loading = false;
                let inputFile = document.getElementById('inputImageFile');
                inputFile.value = null;
                this.fileUpload = null;
                this.fileName = null;
                if (resp && resp.messages.length > 0) {
                    this.showAlert(`\n\n${resp.messages[0]}`);
                }
                else {
                    this.showAlert('\n\nProcess completed');
                }
            });
        })
            .catch();
    }
    seeMoreAction(parameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.popOverCtrlHelper.openImageUploadSpaceBuilding(parameters.id, (popoverData) => {
                this.createSpotBuildingsService
                    .getSpaceConditions(this.SpotBuildingsResponse.id)
                    .then((response) => {
                    this.spacesParameters = null;
                    this.spacesParameters = response;
                });
            });
        });
    }
    showAlert(alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl
                .create({
                header: this.i18n.get('global.form.sendMsgToAgent.notification'),
                subHeader: alertMsg,
                buttons: [
                    {
                        text: this.i18n.get('global.form.sendMsgToAgent.ok'),
                        handler: () => {
                            if (this.formData.valid) {
                                this.popoverCtrl.dismiss();
                            }
                        }
                    }
                ]
            })
                .then(alert => {
                alert.present();
            });
        });
    }
}
