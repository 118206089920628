import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { RegisterService } from '@app/core/register.service';
import { BrokersDTO } from '@app/models/bokers.model';
import { CompanyDTO } from '@app/models/company.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { IonicSelectableComponent } from 'ionic-selectable';
export class SpotMoreBuildingsComponent {
    constructor(registerService) {
        this.registerService = registerService;
        this.selectCompany = null;
        this.ownerCompany = null;
        this.company = new CompanyDTO();
        this.brokers = [];
        this.offeredByUser = null;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.multiLangInit();
            this.allCompanies = yield this.getCompanies();
            this.addressArray = this.spotBuildings.address;
        });
    }
    ngOnChanges(changes) {
        if (this.buildingDetail) {
            this.existingComments = this.buildingDetail.comments;
            this.spotBuildings.comments = this.buildingDetail.comments;
            this.existingInvestment = this.buildingDetail.investmentOpportunity;
            this.spotBuildings.investmentOpportunity = this.buildingDetail.investmentOpportunity;
            this.ownerCompany = this.buildingDetail.offeredByCompany;
            this.spotBuildings.offeredByCompany = this.buildingDetail.offeredByCompany;
            if (this.buildingDetail.brokers && this.buildingDetail.brokers.length > 0) {
                this.selectCompany = this.buildingDetail.brokers[0].company;
            }
            else {
                this.selectCompany = this.buildingDetail.offeredByCompany;
            }
            this.getUsersByCompany(this.selectCompany.id);
            this.spotBuildings.brokers = this.buildingDetail.brokers;
            this.offeredByUser = this.buildingDetail.brokers;
            this.spotBuildings.address = this.buildingDetail.address;
            this.spotBuildings.neighborhood = this.buildingDetail.neighborhood;
        }
    }
    multiLangInit() {
        this.descriptionFieldOptions = {
            columnLabel: 'global.registerSpot.description',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
        this.addressFieldOptions = {
            columnLabel: 'global.register.address',
            element: { input: true },
            formArrayName: 'address',
            required: true
        };
        this.InvestmentFieldOptions = {
            columnLabel: 'global.list-your-property.investmentOpportunity',
            element: { richtext: true },
            formArrayName: 'description2',
            required: true
        };
    }
    addressChange(value) {
        if (value.address.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.address).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.address[key];
                if (obj['en'] === '' || obj['es'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues ? (this.spotBuildings.address = '') : (this.spotBuildings.address = JSON.stringify(mergedObject));
        }
        else {
            this.spotBuildings.address = '';
        }
    }
    getCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingCompanies().toPromise();
        });
    }
    getUsersByCompany(companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.offeredByUser = '';
            this.users = yield this.registerService.getUsersByCompany(companyId).toPromise();
            this.users.map((user) => {
                user.userId = user.id;
                return user;
            });
        });
    }
    selectOwnerCompany($event) {
        this.company.id = $event.value.id;
        this.spotBuildings.offeredByCompany = this.company;
    }
    portChange($event) {
        //this.company.id = $event.value.id;
        //this.spotBuildings.offeredByCompany = this.company;
        this.getUsersByCompany($event.value.id);
        this.clearBokers();
    }
    clearBokers() {
        this.spotBuildings.brokers = null;
        this.brokers = [];
    }
    nameChange($event) {
        this.clearBokers();
        $event.value.forEach((element) => {
            let broker = new BrokersDTO();
            broker.userId = element.userId;
            this.brokers.push(broker);
        });
        this.spotBuildings.brokers = this.brokers.length == 0 ? null : this.brokers;
    }
    descriptionChange(value, update) {
        if (value.description.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.description).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.description[key];
                if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues ? (this.spotBuildings.comments = '') : (this.spotBuildings.comments = JSON.stringify(mergedObject));
        }
        else {
            this.spotBuildings.comments = '';
        }
    }
    investmentChange(value, update) {
        if (value.description.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.description).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.description[key];
                if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? (this.spotBuildings.investmentOpportunity = '')
                : (this.spotBuildings.investmentOpportunity = JSON.stringify(mergedObject));
        }
        else {
            this.spotBuildings.investmentOpportunity = '';
        }
    }
}
