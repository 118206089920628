import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { BrokerDetailsComponent } from './broker-details/broker-details.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
const ɵ0 = { title: extract('Broker Detail'), shouldReuse: false }, ɵ1 = { title: extract('Broker Detail'), shouldReuse: false }, ɵ2 = { title: extract('Broker Detail'), shouldReuse: false }, ɵ3 = { title: extract('Broker Detail'), shouldReuse: false };
const routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    Shell.childRoutesRunningGuardsResolversAlways([
        {
            path: `${AppRouteNames.USR_PROFILE_ROUTE}/:name/:brokerId`,
            component: BrokerDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ0
        },
        {
            path: `${AppRouteNames.USR_PROFILE_ROUTE}/:name/:brokerId/:language`,
            component: BrokerDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ1
        },
        {
            path: `${AppRouteNames.USR_PROFILE_ROUTE_CL}/:name/:brokerId`,
            component: BrokerDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ2
        },
        {
            path: `${AppRouteNames.USR_PROFILE_ROUTE_CL}/:name/:brokerId/:language`,
            component: BrokerDetailsComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ3
        }
    ])
];
export class BrokersRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
