import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { MatDialogRef } from '@angular/material';
export class ForgotFormComponent {
    constructor(fb, registerService, forgotDialog) {
        this.fb = fb;
        this.registerService = registerService;
        this.forgotDialog = forgotDialog;
    }
    ngOnInit() {
        this.createForm();
    }
    sendEmail() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.registerService
                .forgotPassword(this.registerForm.controls.emailGroup.value.email)
                .then((response) => { })
                .catch(err => { });
            this.dismissAsDialog(true);
        });
    }
    trimValue(formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    }
    createForm() {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                email: ['', Validators.compose([Validators.required, Validators.email])]
            })
        });
    }
    dismissAsDialog(val) {
        this.forgotDialog.close(val);
    }
}
