import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
export class ImageUploadService {
    constructor(http) {
        this.http = http;
        this.buildingListingEndpoint = 'api/buildinglistingPhotos';
        this.buildingListingPhotosEndpoint = 'api/buildinglistingPhotosByListing/image-formats-only';
        this.spaceEndpoint = 'api/spacePhotos';
        this.spacePhotoEndpoint = 'api/spacePhotosBySpace';
    }
    createImage(formData) {
        return this.http
            .post(`${this.buildingListingEndpoint}/no-listing/upload`, formData)
            .pipe(map(val => val))
            .toPromise();
    }
    createSpaceImage(formData) {
        return this.http
            .post(`${this.spaceEndpoint}/no-space/upload`, formData)
            .pipe(map(val => val))
            .toPromise();
    }
    associatePhotosToBuildingListing(buildingListingId, imgList) {
        if (!buildingListingId) {
            return imgList;
        }
        let buildingListingPhotos = imgList.map((photo) => {
            photo.buildingListingId = buildingListingId;
            return photo;
        });
        const photoIds = buildingListingPhotos.map((img) => img.id);
        this.http
            .put(`${this.buildingListingEndpoint}/listing/${buildingListingId}`, photoIds)
            .pipe(map(val => val))
            .toPromise();
        return buildingListingPhotos;
    }
    associatePhotosToSpace(spaceId, imgList) {
        if (!spaceId) {
            return imgList;
        }
        let spacePhotos = imgList.map((photo) => {
            photo.spaceId = spaceId;
            return photo;
        });
        const photoIds = spacePhotos.map((img) => img.id);
        this.http
            .put(`${this.spaceEndpoint}/space/${spaceId}`, photoIds)
            .pipe(map(val => val))
            .toPromise();
        return spacePhotos;
    }
    getBuildingListingPhotos(buildingListingId) {
        return this.http
            .get(`${this.buildingListingPhotosEndpoint}/?listingId=${buildingListingId}`, { observe: 'response' })
            .pipe(map(val => val.body))
            .toPromise();
    }
    getSpacePhotos(spaceId) {
        return this.http
            .get(`${this.spacePhotoEndpoint}?spaceId=${spaceId}`, { observe: 'response' })
            .pipe(map(val => val.body))
            .toPromise();
    }
    logListingPhotonDeletion(deletedListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post('api/deletedphotolog/', deletedListingPhoto)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    deleteBuildingListingPhoto(bildingListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .delete(`${this.buildingListingEndpoint}/${bildingListingPhoto.id}`)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    deleteSpacePhoto(spacePhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .delete(`${this.spaceEndpoint}/${spacePhoto.id}`)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    uploadCroppedImage(file, caption, BuildingListingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('listingPhotoId', BuildingListingPhotoId);
            formData.append('caption', caption);
            formData.append('file', file);
            return this.http
                .post(`${this.buildingListingEndpoint}/cropped/`, formData)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    uploadCroppedImageSpace(file, caption, spacePhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('spacePhotoId', spacePhotoId);
            formData.append('caption', caption);
            formData.append('file', file);
            return this.http
                .post(`${this.spaceEndpoint}/cropped/`, formData)
                .pipe(map(val => val))
                .toPromise();
        });
    }
    updateOrderOrCaptionFrom(buildingListingPhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .patch(`${this.buildingListingEndpoint}/patch/${buildingListingPhotoId}`, { orderId: orderId, caption: caption })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    updateOrderOrCaptionFromSpace(spacePhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .patch(`${this.spaceEndpoint}/patch/${spacePhotoId}`, { orderId: orderId, caption: caption })
                .pipe(map(val => val))
                .toPromise();
        });
    }
    setFloorPlant(spacePhotoId, floorplant) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .patch(`${this.spaceEndpoint}/patch/${spacePhotoId}`, { floorplant: floorplant })
                .pipe(map(val => val))
                .toPromise();
        });
    }
}
