import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
export class ListingModalService {
    constructor(modalCtrl, router, platform) {
        this.modalCtrl = modalCtrl;
        this.router = router;
        this.platform = platform;
    }
    ngOnInit() { }
    ngOnDestroy() { }
    presentModal(modalOptions, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create(modalOptions);
            modal.onDidDismiss().then(() => {
                if (callback) {
                    callback();
                }
            });
            return yield modal.present();
        });
    }
    dismiss() {
        this.modalCtrl.dismiss();
    }
}
