import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { ImageService } from '@app/core';
import { RegisterListingService } from '@app/core/register-listing.service';
export class EditImageModalComponent {
    constructor(modalCtrl, imgService, registerListinService) {
        this.modalCtrl = modalCtrl;
        this.imgService = imgService;
        this.registerListinService = registerListinService;
        this.UPLOAD_FILE_SIZE_LIMIT = 20000000;
        this.imageCropperOptions = {};
        this.imageChangedEvent = {};
        this.originalImage = null;
        this.croppedImage = null;
        this.filename = null;
        this.originalImageId = null;
        this.uploadingNewPhoto = false;
        this.isProcessingImg = null;
        this.existingImgURL = null;
        this.canvasRotation = 0;
        this.rotation = 0;
        this.transform = {};
    }
    convertToDataURL(imageUrl, callback) {
        let xhr = new XMLHttpRequest();
        this.filename = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = () => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    }
    closeModal() {
        this.modalCtrl.dismiss();
    }
    resetFileInput(ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    fileUploadErrorHandler(action) {
        const infoModalParams = {
            headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
            bodyMessage: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
        };
        this.imageChangedEvent = null;
        this.originalImage = null;
        this.isProcessingImg = null;
        this.filename = null;
        this.resetFileInput(document.getElementById('inputImageFile'));
        this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
    }
    uploadOriginalImage(login, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.userHomeService
                .uploadUserProfileImage(login, file)
                .toPromise()
                .then((result) => {
                return { originalImageId: result.imageId, error: false };
            })
                .catch((error) => {
                return { response: error, error: true };
            });
        });
    }
    updateProfileImage(originalImageId, login, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.userHomeService
                .updateUserProfileImage(login, file, this.uploadingNewPhoto)
                .toPromise()
                .then((result) => {
                const profileImageId = { originalImageId: originalImageId, profileImageId: result.imageId };
                const params = this.userHomeService.getUploadImageInfoModalParam(true, profileImageId);
                this.closeModal();
                this.openInfoModal(params.headerTitle, params.bodyMessage, params.action, params.extraData);
            })
                .catch((error) => {
                const params = this.userHomeService.getUploadImageInfoModalParam(false);
                this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
            });
        });
    }
    ngOnInit() {
        this.imageCropperOptions = this.editLogo
            ? this.userHomeService.getLogoCropperOptions()
            : this.userHomeService.getImageCropperOptions();
        let imgURL;
        this.isProcessingImg = false;
        if (this.profileImage) {
            if (this.profileImage.originalImage) {
                imgURL = this.profileImage.originalImage;
            }
            else {
                imgURL = this.profileImageUrl;
            }
        }
        else {
            imgURL = this.profileImageUrl;
        }
        this.existingImgURL = imgURL;
        this.convertToDataURL(imgURL, (dataURL) => {
            this.profileImgBase64 = dataURL;
        });
    }
    imageCropped(image) {
        this.croppedImage = image.base64;
    }
    setNewImageFlag(flag) {
        this.uploadingNewPhoto = true;
    }
    fileChangedEvent(event, remove) {
        this.uploadingNewPhoto = false;
        this.originalImage = null;
        this.isProcessingImg = true;
        this.profileImgBase64 = null;
        this.existingImgURL = null;
        if (remove) {
            this.croppedImage = null;
            this.imageChangedEvent = null;
        }
        else {
            const imageExtensions = this.commonService.getValidImageExension();
            const isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);
            if (!isValidImage) {
                this.fileUploadErrorHandler('invalid-file');
                return;
            }
            else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
                this.fileUploadErrorHandler('exceed-limit');
                return;
            }
            this.imageChangedEvent = event;
            const file = event.srcElement.files[0];
            this.filename = event.srcElement.files[0].name;
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            if (this.editLogo) {
                fileReader.onload = () => {
                    const originalImageFile = this.convertImageToFile(fileReader.result);
                    this.registerListinService.uploadNewImage(originalImageFile, `Logo_cropped`).then((createdImages) => {
                        this.originalImageId = createdImages[0].id;
                        const imgURL = this.imgService.mediumThumbnail(this.originalImageId);
                        this.convertToDataURL(imgURL, (dataURL) => {
                            this.originalImage = dataURL;
                        });
                    });
                };
            }
            else {
                fileReader.onload = () => {
                    const originalImageFile = this.convertImageToFile(fileReader.result);
                    this.uploadOriginalImage(this.login, originalImageFile).then((result) => {
                        if (result.error) {
                            const params = this.userHomeService.getUploadImageInfoModalParam(false);
                            this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
                        }
                        else {
                            this.originalImageId = result.originalImageId;
                            this.isProcessingImg = false;
                        }
                    });
                };
            }
        }
    }
    cancel() {
        this.closeModal();
    }
    convertImageToFile(imgBase64) {
        return this.commonService.convertImageBase64ToFileSync(imgBase64, this.filename);
    }
    saveImage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const croppedImage = this.croppedImage == null ? this.croppedImage : this.convertImageToFile(this.croppedImage);
            if (this.editLogo) {
                this.updateLogoImage(croppedImage);
            }
            else {
                this.updateProfileImage(this.originalImageId, this.login, croppedImage);
            }
        });
    }
    updateLogoImage(croppedImage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (croppedImage != null) {
                yield this.registerListinService.uploadNewImage(croppedImage, `Logo_cropped`).then((createdImages) => {
                    if (createdImages) {
                        this.closeModal();
                        this.imgService.setLogoId(createdImages[0].id);
                    }
                });
            }
            else {
                this.closeModal();
                this.imgService.setLogoId(croppedImage);
            }
        });
    }
    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }
    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }
    flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = Object.assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    }
    flipHorizontal() {
        this.transform = Object.assign({}, this.transform, { flipH: !this.transform.flipH });
    }
    flipVertical() {
        this.transform = Object.assign({}, this.transform, { flipV: !this.transform.flipV });
    }
    resetImage() {
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }
}
