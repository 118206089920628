import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService, PlatformHelperService } from '@app/core';
import { AlertController } from '@ionic/angular';
import { InstallPromptDialogService } from '@app/core/install-prompt.service';
import { Observable } from 'rxjs';
import { PWAService } from '../pwa-service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DeviceDetectorService } from 'ngx-device-detector';
export class DownloadComponent {
    constructor(platformHelper, alertCtrl, promptService, pwaService, gaService$, deviceDetector, i18NService) {
        this.platformHelper = platformHelper;
        this.alertCtrl = alertCtrl;
        this.promptService = promptService;
        this.pwaService = pwaService;
        this.gaService$ = gaService$;
        this.deviceDetector = deviceDetector;
        this.i18NService = i18NService;
        this.appInstalled$ = new Observable();
        this.isDesk = false;
    }
    ngOnInit() {
        this.isIos = this.platformHelper.isIosChrome() || this.platformHelper.isIosSafari();
        this.appInstalled$ = this.pwaService.appInstalled$;
        this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
        this.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
            this.isDesk = true;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
    }
    isAppInstalled() {
        return this.pwaService.appInstalled$;
    }
    promptInstallPWA() {
        if (this.isAppInstalled()) {
            this.pwaService.promptPWAInstall();
        }
        this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
    }
    promptIos() {
        if (this.isIphone && (this.isChrome || this.platformIsIosChrome())) {
            this.promptIosChromeDialog();
        }
        else {
            this.promptService.openDialog(null, true);
        }
    }
    platformIsIosChrome() {
        return this.platformHelper.isIosChrome();
    }
    platformIsIosSafari() {
        return this.platformHelper.isIosSafari();
    }
    promptIosChromeDialog() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: 'SiiLA SPOT Mobile',
                cssClass: 'header-alert-prompt',
                message: 'To install the app, please open our site using the Safari browser and click install App to proceed.'
            });
            yield alert.present();
        });
    }
    promptIsInstalled() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const okText = this.i18NService.get('global.OK');
            const alert = yield this.alertCtrl.create({
                header: this.isDesktop ? 'SiiLA SPOT Desktop' : 'SiiLA SPOT Mobile',
                cssClass: 'header-alert-prompt',
                message: this.i18NService.get('global.siilaAppInstalled'),
                buttons: [okText]
            });
            yield alert.present();
        });
    }
}
