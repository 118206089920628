import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, MenuController, ModalController, NavController, PickerController, Platform, PopoverController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
export class BackwardGuard {
    constructor(platform, router, location, navController, menuController, alertController, pickerController, actionSheetController, popoverController, modalController) {
        this.platform = platform;
        this.router = router;
        this.location = location;
        this.navController = navController;
        this.menuController = menuController;
        this.alertController = alertController;
        this.pickerController = pickerController;
        this.actionSheetController = actionSheetController;
        this.popoverController = popoverController;
        this.modalController = modalController;
    }
    canDeactivate(component, currentRoute, currentState) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.platform.is('mobileweb')) {
                return true;
            }
            const currentNavigation = this.router.getCurrentNavigation();
            const backButtonPressed = currentNavigation.trigger === 'popstate';
            if (!backButtonPressed || this.isPop) {
                return true;
            }
            // Workaround for history being broken
            // https://github.com/angular/angular/issues/13586#issuecomment-458241789
            this.location.go(currentState.url);
            // Hide menu
            const isMenuOpen = yield this.menuController.isOpen();
            if (isMenuOpen) {
                this.menuController.close();
                return false; // Prevent navigation
            }
            // Hide overlays
            const activeController = (yield this.alertController.getTop()) ||
                (yield this.pickerController.getTop()) ||
                (yield this.actionSheetController.getTop()) ||
                (yield this.popoverController.getTop()) ||
                (yield this.modalController.getTop());
            if (activeController) {
                activeController.dismiss();
                return false; // Prevent navigation
            }
            // Pop
            this.isPop = true;
            this.navController.pop().then(() => {
                this.isPop = false;
            });
            return false; // Prevent navigation
        });
    }
}
BackwardGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackwardGuard_Factory() { return new BackwardGuard(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i1.MenuController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.PickerController), i0.ɵɵinject(i1.ActionSheetController), i0.ɵɵinject(i1.PopoverController), i0.ɵɵinject(i1.ModalController)); }, token: BackwardGuard, providedIn: "root" });
