import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Observable, SubscriptionLike, Subject, Observer, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { share, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { AccountService } from '@app/core/auth/account.service';
import { guiid } from '@app/websockets/socket-hash.helper';
import { Router } from '@angular/router';
import { isString } from 'lodash';
import { HttpService } from '@app/core';
import * as i0 from "@angular/core";
import * as i1 from "./websocket.config";
import * as i2 from "@angular/router";
import * as i3 from "../core/auth/account.service";
import * as i4 from "../core/http/http.service";
export var SiilaBusAuthActions;
(function (SiilaBusAuthActions) {
    SiilaBusAuthActions["HANDSHAKE"] = "handshake";
    SiilaBusAuthActions["KICK"] = "kick";
    SiilaBusAuthActions["AUTHORIZE"] = "authorize";
    SiilaBusAuthActions["DISCOVER"] = "discover";
    SiilaBusAuthActions["DEPOSIT"] = "deposit";
})(SiilaBusAuthActions || (SiilaBusAuthActions = {}));
export class WebsocketService {
    constructor(wsConfig, router, account, http) {
        this.wsConfig = wsConfig;
        this.router = router;
        this.account = account;
        this.http = http;
        this.wsMessages$ = new Subject();
        this.targetSource$ = new Subject();
        this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
        this.reconnectAttempts = wsConfig.reconnectAttempts || 10; // number of connection attempts
        this.config = {
            url: wsConfig.url,
            closeObserver: {
                next: (event) => {
                    this.websocket$ = null;
                    this.connection$.next(false);
                }
            },
            openObserver: {
                next: (event) => {
                    this.connection$.next(true);
                    this.handshake();
                }
            }
        };
        // connection status
        this.status = new Observable(observer => {
            this.connection$ = observer;
        }).pipe(share(), distinctUntilChanged());
        // run reconnect if not connection
        this.statusSub = this.status.subscribe(isConnected => {
            this.isConnected = isConnected;
            if (!this.reconnection$ && typeof isConnected === 'boolean' && !isConnected) {
                this.reconnect();
            }
        });
        this.websocketSub = this.wsMessages$.subscribe(null, (error) => console.error('WebSocket error!', error));
        this.wsMessages$.subscribe((message) => {
            if (isString(message)) {
                try {
                    message = JSON.parse(message);
                }
                catch (e) { }
            }
            if (message.Items && message.Items.length) {
                const authorizer = message.Items.find((i) => {
                    return i.authorizer_token;
                });
                if (authorizer) {
                    if (authorizer && authorizer.authorizer_token) {
                        try {
                            const authValue = isString(authorizer.authorizer_token)
                                ? JSON.parse(authorizer.authorizer_token)
                                : authorizer.authorizer_token;
                            if (authValue.tokenValue && authValue.user) {
                                if (!localStorage.getItem('user') && !localStorage.getItem('token')) {
                                    localStorage.setItem('token', JSON.stringify(authValue.tokenValue));
                                    localStorage.setItem('ls.token', JSON.stringify(authValue.tokenValue));
                                    localStorage.setItem('user', JSON.stringify(authValue.user));
                                    this.account.currentUser$.next(authValue.user);
                                    window.location.reload();
                                }
                            }
                        }
                        catch (e) {
                            console.warn('discovery failed parsing #->', e);
                        }
                    }
                }
            }
            if (message.Attributes) {
                localStorage.setItem('client_guiid', JSON.stringify(message.Attributes['client_guiid']));
                this.discoveryBroker();
            }
            else {
                const messageContent = message;
                if (messageContent.action === SiilaBusAuthActions.AUTHORIZE &&
                    messageContent.data &&
                    messageContent.data.tokenValue &&
                    messageContent.data.user) {
                    // if (!localStorage.getItem('token') && !localStorage.getItem('user')) {
                    //all check if tokens are still valid
                    localStorage.setItem('token', JSON.stringify(messageContent.data.tokenValue));
                    localStorage.setItem('user', JSON.stringify(messageContent.data.user));
                    //right here
                    this.account.currentUser$.next(messageContent.data.user);
                    window.location.reload();
                    // }
                }
                else if (message.action === SiilaBusAuthActions.KICK) {
                    if (localStorage.getItem('token') || localStorage.getItem('user')) {
                        // localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        this.account.currentUser$.next(null);
                        this.router.navigate(['']);
                    }
                    // window.location.reload();
                }
            }
        });
        //this prevents the socket connetion from ddos over iframe
        // if (window.location === window.parent.location)
        // this.connect();
    }
    ngOnDestroy() {
        this.websocketSub.unsubscribe();
        this.statusSub.unsubscribe();
    }
    /*
     * connect to WebSocked
     * */
    connect() {
        if (window.location === window.parent.location) {
            this.websocket$ = new WebSocketSubject(this.config);
            this.websocket$.subscribe(message => this.wsMessages$.next(message), (error) => {
                if (!this.websocket$) {
                    // run reconnect if errors
                    this.reconnect();
                }
            });
        }
    }
    /*
     * reconnect if not connecting or errors
     * */
    reconnect() {
        this.reconnection$ = interval(this.reconnectInterval).pipe(takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$));
        this.reconnection$.subscribe(() => this.connect(), null, () => {
            // Subject complete if reconnect attemts ending
            this.reconnection$ = null;
            if (!this.websocket$) {
                this.wsMessages$.complete();
                this.connection$.complete();
            }
        });
    }
    /*
     * on message event
     * */
    on(event) {
        if (event) {
            return this.wsMessages$.pipe(filter((message) => message.event === event), map((message) => message.data));
        }
    }
    /*
     * on message to server
     * */
    send(action, data = {}) {
        if (event && this.isConnected) {
            this.websocket$.next({ action, data });
        }
        else {
            console.error('Send error!');
        }
    }
    kick() {
        this.send(SiilaBusAuthActions.KICK, JSON.stringify({}));
    }
    /*
     * after connect exchange guiid
     * */
    handshake() {
        return this.send(SiilaBusAuthActions.HANDSHAKE, guiid());
    }
    /*
     * discovers clients in bus and exchanges token with provider id
     * */
    discover() {
        return this.send(SiilaBusAuthActions.DISCOVER, 'client_discovery');
    }
    discoveryBroker() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (localStorage.getItem('token') && localStorage.getItem('user')) {
                // try {
                const sessionIsValid = yield this.isSessionDestroyed();
                sessionIsValid.username ? this.deposit() : this.clearCredentialsFromSocket();
            }
            else {
                this.discover();
            }
        });
    }
    clearCredentialsFromSocket() {
        if (localStorage.getItem('token') && localStorage.getItem('user')) {
            // localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.kick();
            window.location.reload();
        }
    }
    isSessionDestroyed() {
        return this.http.get('api/authenticate').toPromise();
    }
    deposit() {
        this.send(SiilaBusAuthActions.DEPOSIT, {
            tokenValue: JSON.parse(localStorage.getItem('token')),
            user: JSON.parse(localStorage.getItem('user'))
        });
    }
}
WebsocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(i0.ɵɵinject(i1.config), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i4.HttpService)); }, token: WebsocketService, providedIn: "root" });
