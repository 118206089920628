import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { CommonGenericService } from '@app/core/common.service';
import * as MyListingModel from '@app/models/my-listing.model';
import { PopoverController } from '@ionic/angular';
export class MyListingFilterComponent {
    constructor(commonSvc, popoverCtrl) {
        this.commonSvc = commonSvc;
        this.popoverCtrl = popoverCtrl;
        this.updateFilter = new EventEmitter();
    }
    setButtonColor(width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    }
    overrideColor(width) {
        return this.screenWidth >= width ? 'filter-btn-color' : 'filter-btn-white';
    }
    onModalDismiss(modal) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            modal.onWillDismiss().then((result) => {
                this.updateFilter.emit(result.data);
            });
        });
    }
}
