import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CartService {
    constructor(http) {
        this.http = http;
    }
    loadCart() {
        return this.http.get('/api/id/carts');
    }
    saveCart() {
        return this.http.get('/api/id/carts');
    }
    updateCart() {
        return this.http.get('/api/id/carts');
    }
    deleteCart() {
        return this.http.get('/api/id/carts');
    }
    addItem() {
        return this.http.get('/api/id/carts');
    }
    removeItem() {
        return this.http.get('/api/id/carts');
    }
    updateItem() {
        return this.http.get('/api/id/carts');
    }
}
CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.HttpClient)); }, token: CartService, providedIn: "root" });
