import * as tslib_1 from "tslib";
import { Meta, Title } from '@angular/platform-browser';
import { CommonGenericService } from '../common.service';
import { PropertyTypeHelperService } from '../helpers';
import { I18nService } from '../i18n.service';
import { ImageService } from '../image.service';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AppRouteNamesService, CustomSpotSearchType } from '../helpers/app-route-names.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
const metaLog = new Logger('Search-Spots');
export class MetaService {
    constructor(title, meta, i18NService, propertyHelperService, commonService, imgService, route, appRouteNames, http) {
        this.title = title;
        this.meta = meta;
        this.i18NService = i18NService;
        this.propertyHelperService = propertyHelperService;
        this.commonService = commonService;
        this.imgService = imgService;
        this.route = route;
        this.appRouteNames = appRouteNames;
        this.http = http;
        this.metatagsInfoSearchAPI = `/api/metatags/spotsearch`;
        this.metatagsOnlyhAPI = `/api/metatags/prerender/sent`;
    }
    setDynamicTags(tags, title) {
        this.title.setTitle(title || this.title.getTitle());
        this.meta.updateTag({ lang: this.getLangContent() });
        document.querySelector('html').setAttribute('lang', this.getLangContent());
        tags.forEach(t => {
            if (t.name) {
                if (t.name.includes('og:')) {
                    this.meta.removeTag(`property='${t.name}'`);
                    this.meta.updateTag({ property: t.name, content: t.content });
                }
                else {
                    this.meta.removeTag(`name='${t.name}'`);
                    this.meta.updateTag({ name: t.name, content: t.content });
                }
            }
        });
    }
    setNonFoundTags() {
        this.meta.addTags([{ name: 'prerender-status-code', content: '404' }]);
    }
    getMetaTagsFrom(title, description, keywords) {
        const tags = [
            // general
            { name: 'name', content: encodeURIComponent(title) },
            { name: 'lang', content: this.getLangContent() },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(window.location.href)}` },
            { name: 'og:locale', content: [this.i18NService.getCurrentLocale()] },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:site_url', content: `${encodeURI(window.location.href)}` },
            { name: 'twitter:description', content: [description] },
            // Title
            { name: 'og:title', content: [title] },
            { name: 'title', content: [title] },
            { name: 'twitter:title', content: [title] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(title)
            },
            // Description
            { name: 'og:description', content: [description] },
            { name: 'description', content: [description] }
        ];
        if (keywords) {
            tags.push({ name: 'keywords', content: [keywords] });
        }
        return tags;
    }
    setCompanyMetaTags(company, agentsDTO, spotBuildingNames) {
        const detailsMetaTags = this.buildCompanyMetatag(company, agentsDTO, spotBuildingNames);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
        const locale = this.i18NService.getCurrentLocale();
        const companyTitle = this.i18NService.getTranslation(company.name);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'subject', content: detailsMetaTags.subject },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_ncompanyTitleTagame', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            {
                name: 'image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    setCompanyMetaTagsLight(company) {
        const detailsMetaTags = this.buildCompanyMetatagLight(company);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = company.biography ? this.removeHTMLCode(this.i18NService.getTranslation(company.biography)) : '';
        const locale = this.i18NService.getCurrentLocale();
        const companyTitle = this.i18NService.getTranslation(company.companyName);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'subject', content: detailsMetaTags.subject },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_ncompanyTitleTagame', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [companyTitle] },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            {
                name: 'image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [companyTitle] },
            {
                name: 'twitter:image',
                content: company.mainImageId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(company.mainImageId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    setBrokerMetaTags(brokerDTO, brokerBIO, spotBuildingNames) {
        const detailsMetaTags = this.buildBrokerMetatag(brokerDTO, spotBuildingNames);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(brokerBIO)) : '';
        const locale = this.i18NService.getCurrentLocale();
        const brokerTitle = this.i18NService.getTranslation(brokerDTO.firstNameAndLastName);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: detailsMetaTags.subjectTag },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            {
                name: 'image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    setBrokerMetaTagsLight(brokerDTO) {
        const detailsMetaTags = this.buildBrokerMetatagLight(brokerDTO);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const descText = brokerDTO.biography
            ? this.removeHTMLCode(this.i18NService.getTranslation(brokerDTO.biography))
            : '';
        const locale = this.i18NService.getCurrentLocale();
        const brokerTitle = this.i18NService.getTranslation(brokerDTO.brokerName);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: detailsMetaTags.subjectTag },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            {
                name: 'image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: brokerDTO.photoId != undefined
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(brokerDTO.photoId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    buildBrokerMetatag(broker, spotBuildingNames) {
        let metaTagTitle = '';
        const brokerTitleTag = broker.firstNameAndLastName;
        metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let subjectTag = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(broker.companyName);
        keywordsMetaTags.push(brokerTitleTag);
        if (spotBuildingNames) {
            spotBuildingNames.forEach((item, index) => {
                keywordsMetaTags.push(item);
            });
        }
        const leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        const saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        keywordsMetaTags.push(broker.userEmail);
        subjectTag = `${brokerTitleTag} | ${broker.companyName} | SiiLA SPOT`;
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, subjectTag };
    }
    buildBrokerMetatagLight(broker) {
        let metaTagTitle = '';
        const brokerTitleTag = broker.brokerName;
        metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let subjectTag = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(broker.companyName);
        keywordsMetaTags.push(brokerTitleTag);
        if (broker.spotBuildingNames) {
            broker.spotBuildingNames.forEach((item, index) => {
                keywordsMetaTags.push(item);
            });
        }
        const leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        const saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        keywordsMetaTags.push(broker.userEmail);
        subjectTag = `${brokerTitleTag} | ${broker.companyName} | SiiLA SPOT`;
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, subjectTag };
    }
    buildCompanyMetatag(company, agentsDTO, spotBuildingNames) {
        let metaTagTitle = '';
        const companyTitleTag = company.name;
        const subject = `${companyTitleTag} | SiiLA SPOT`;
        metaTagTitle = subject;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        const leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        const saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(companyTitleTag);
        if (spotBuildingNames) {
            spotBuildingNames.forEach((item, index) => {
                keywordsMetaTags.push(item);
            });
        }
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        if (agentsDTO) {
            agentsDTO.forEach((item, index) => {
                keywordsMetaTags.push(item.firstNameAndLastName);
            });
        }
        if (company.websiteURL) {
            keywordsMetaTags.push(company.websiteURL);
        }
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, subject };
    }
    buildCompanyMetatagLight(company) {
        let metaTagTitle = '';
        const companyTitleTag = company.companyName;
        const subject = `${companyTitleTag} | SiiLA SPOT`;
        metaTagTitle = subject;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        const leaseVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.lease').toLowerCase());
        const saleVal = this.commonService.capitalizeFirstLetter(this.i18NService.get('global.list-your-property.sale').toLowerCase());
        keywordsMetaTags.push(companyTitleTag);
        if (company.spotBuildingNames) {
            company.spotBuildingNames.forEach((item, index) => {
                keywordsMetaTags.push(item);
            });
        }
        keywordsMetaTags.push(leaseVal);
        keywordsMetaTags.push(saleVal);
        if (company.agentNames) {
            company.agentNames.forEach((item, index) => {
                keywordsMetaTags.push(item);
            });
        }
        if (company.website) {
            keywordsMetaTags.push(company.website);
        }
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, subject };
    }
    buildSpotBuildingMetatag(listing, locale) {
        let metaTagTitle = '';
        let metaTagSubject = '';
        // Listing Type
        const listingTypeTag = this.i18NService.get(this.propertyHelperService.getSpotBuildingTypeLongTranslation(listing.type));
        // Property Type
        let propertyTypeTag = '';
        const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
        propertyTypeTag = this.i18NService.get(propertyTypeKey);
        const propertyTypeTitleTag = propertyTypeTag;
        // Building Name
        const buildingName = this.i18NService.getTranslation(listing.propertyName);
        const neighborhood = listing.neighborhood;
        const cityName = listing.building.city ? this.i18NService.getTranslation(listing.building.city.name) : undefined;
        const stateName = listing.building.city
            ? this.i18NService.getTranslation(listing.building.city.countryState.name)
            : undefined;
        const address = this.i18NService.getTranslation(listing.building.address);
        const company = listing.offeredByCompany.name;
        const subMarket = listing.building.subMarket ? listing.building.subMarket.name : '';
        //metaTagTitle = 'Logo SiiLA SPOT';
        metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;
        metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
        if (subMarket) {
            metaTagSubject += ', ' + subMarket;
        }
        metaTagSubject += ` ${listingTypeTag}`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(buildingName);
        keywordsMetaTags.push(propertyTypeTag);
        keywordsMetaTags.push(listingTypeTag);
        keywordsMetaTags.push(stateName);
        keywordsMetaTags.push(cityName);
        keywordsMetaTags.push(subMarket);
        keywordsMetaTags.push(address);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, metaTagSubject };
    }
    getLangContent() {
        return this.i18NService.getCurrentLanguage() == 'en' ? 'en' : 'es';
    }
    findMetatagsFrom(searchUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.metatagsInfoSearchAPI + searchUrl).toPromise();
        });
    }
    onlyMetatags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(this.metatagsOnlyhAPI).toPromise();
        });
    }
    setHomeMetatags() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const fullDetailsURL = window.location.href;
            const locale = this.i18NService.getCurrentLocale();
            const detailsMetaTags = yield this.buildHomeMetatag(this.route.url, locale);
            const fullTitleText = detailsMetaTags.metaTagTitle;
            const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
            const subject = detailsMetaTags.metaTagSubject;
            let descText = `Mexico's largest and most comprehensive database of available industrial, office and coworking spaces.`;
            if (locale !== 'en-US') {
                descText =
                    'Base de datos más extensa y detallada de México para espacios disponibles industriales, de oficina y coworking.';
            }
            descText = detailsMetaTags.metaDescription ? detailsMetaTags.metaDescription : descText;
            /*
            metaLog.debug(
              `Search Meta Tags: ${detailsMetaTags.metaTagTitle} - ${detailsMetaTags.metaTagSubject} - ${descText}`
            );
            */
            this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
            const tags = [
                //change to be same as title
                { name: 'lang', content: this.getLangContent() },
                { name: 'name', content: encodeURIComponent(fullTitleText) },
                //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
                { name: 'og:locale', content: [locale] },
                { name: 'keywords', content: keywordsMetaTag },
                //{ name: 'title', content: [fullTitleText] },
                { name: 'subject', content: subject },
                {
                    name: 'mobile-web-app-title',
                    content: encodeURIComponent(fullTitleText)
                },
                { name: 'description', content: [descText] },
                { name: 'author', content: 'SiiLA' },
                { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
                { name: 'og:site_name', content: fullTitleText },
                { name: 'og:email', content: 'spot@siila.com.mx' },
                { name: 'og:type', content: 'article' },
                { name: 'og:title', content: [fullTitleText] },
                { name: 'og:description', property: 'og:description', content: descText },
                { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
                { name: 'twitter:card', content: `summary_large_image` },
                { name: 'twitter:title', content: [fullTitleText] },
                { name: 'twitter:description', content: descText },
                {
                    name: 'og:image',
                    property: 'og:image',
                    content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                },
                {
                    name: 'image',
                    content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                },
                { name: 'twitter:card', content: `summary_large_image` },
                { name: 'twitter:title', content: [fullTitleText] },
                {
                    name: 'twitter:image',
                    content: encodeURI(environment.spotServerUrl + '/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png')
                }
            ];
            this.setDynamicTags(tags, fullTitleText);
        });
    }
    buildHomeMetatag(url, locale) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const customSearchType = this.appRouteNames.findCustomSearchType(url);
            let metaTagTitle = null;
            let metaTagSubject = null;
            let keywordsMetaTag = null;
            const lang = locale == 'en-US' ? 'en' : 'es';
            if (customSearchType) {
                let metatagSearchAPI = this.i18NService.removeLanguageFromURL(url);
                metatagSearchAPI = metatagSearchAPI.replace(`/${this.appRouteNames.getSearchRouteName()}`, '');
                if (customSearchType == CustomSpotSearchType.STATE) {
                    metatagSearchAPI += '/default';
                }
                const response = yield this.findMetatagsFrom(`${metatagSearchAPI}?lang=${lang}&customSearchType=${customSearchType}`);
                metaLog.debug(`Contains Search Custom ${customSearchType} - ${response}`);
                metaTagTitle = response.title;
                keywordsMetaTag = response.title;
                metaTagSubject = response.title;
                let metaDescription = response.description;
                return { metaTagTitle, keywordsMetaTag, metaTagSubject, metaDescription };
            }
            metaTagTitle = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
            const spLocale = locale !== 'en-US';
            metaTagSubject = 'SiiLA SPOT | Commercial Properties for Lease and Sale';
            keywordsMetaTag =
                'coworking cdmx, coworking mty, coworking polanco, coworking, wework, regus, reforma, polanco, santa fe, insurgentes, SiiLA SPOT, lease, siila.com.mx, SiiLA, offices, last mile, last mile delivery, compra y venta de inmuebles, inmuebles industriales, naves industriales, centro de distribucion, cedis,  nave industrial, CTT, renta de nave, Cuautitlan, Naucalpan, nave industrial en venta';
            if (locale !== 'en-US') {
                metaTagTitle = 'SiiLA SPOT | Propiedades comerciales en renta y venta';
                keywordsMetaTag =
                    'coworking cdmx, coworking mty, coworking polanco, coworking, wework, regus, reforma, polanco, santa fe, insurgentes, SiiLA SPOT, lease, siila.com.mx, SiiLA, offices, last mile, last mile delivery, compra y venta de inmuebles, inmuebles industriales, naves industriales, centro de distribucion, cedis,  nave industrial, CTT, renta de nave, Cuautitlan, Naucalpan, nave industrial en venta';
                metaTagSubject = 'SiiLA SPOT | Propiedades comerciales en renta y venta';
            }
            return { metaTagTitle, keywordsMetaTag, metaTagSubject };
        });
    }
    setSpotBuildingMetaTags(buildingDetail, spotPhotos) {
        const locale = this.i18NService.getCurrentLocale();
        const detailsMetaTags = this.buildSpotBuildingMetatag(buildingDetail, locale);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const subject = detailsMetaTags.metaTagSubject;
        const descText = buildingDetail.comments
            ? this.removeHTMLCode(this.i18NService.getTranslation(buildingDetail.comments))
            : '';
        const listingTitle = this.i18NService.getTranslation(buildingDetail.title);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            { name: 'lang', content: this.getLangContent() },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: subject },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:email', content: 'spot@siila.com.mx' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [fullTitleText] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId)}`)
                    : null
            },
            {
                name: 'image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [fullTitleText] },
            {
                name: 'twitter:image',
                content: spotPhotos && spotPhotos.length
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(spotPhotos[0].croppedImageId ? spotPhotos[0].croppedImageId : spotPhotos[0].imageId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    setSpotBuildingMetaTags2(listingDetail, spotPhotos) {
        const locale = this.i18NService.getCurrentLocale();
        const detailsMetaTags = this.buildSpotListingMetatag(listingDetail, locale);
        const fullTitleText = detailsMetaTags.metaTagTitle;
        const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        const subject = detailsMetaTags.metaTagSubject;
        const descText = listingDetail.comments
            ? this.removeHTMLCode(this.i18NService.getTranslation(listingDetail.comments))
            : '';
        const listingTitle = this.i18NService.getTranslation(listingDetail.title);
        const fullDetailsURL = window.location.href;
        this.addCanonicalTag(`${encodeURI(fullDetailsURL)}`);
        const tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            { name: 'subject', content: subject },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:email', content: 'spot@siila.com.br' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [fullTitleText] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
            {
                name: 'og:image',
                property: 'og:image',
                content: listingDetail.photoId > 0
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
                    : null
            },
            {
                name: 'image',
                content: listingDetail.photoId > 0
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
                    : null
            },
            { name: 'twitter:card', content: `summary_large_image` },
            { name: 'twitter:title', content: [fullTitleText] },
            {
                name: 'twitter:image',
                content: listingDetail.photoId > 0
                    ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(listingDetail.photoId)}`)
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.setDynamicTags(tags, fullTitleText);
    }
    buildSpotListingMetatag(listing, locale) {
        let metaTagTitle = '';
        let metaTagSubject = '';
        // Listing Type
        const listingTypeKey = listing.listingType === 'L'
            ? 'global.list-your-property.lease'
            : listing.listingType === 'S'
                ? 'global.list-your-property.sale'
                : listing.listingType === 'C'
                    ? 'global.list-your-property.coworking'
                    : '';
        const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());
        // Property Type
        let propertyTypeTag = '';
        const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.buildingTypeId);
        propertyTypeTag = this.i18NService.get(propertyTypeKey);
        const propertyTypeTitleTag = propertyTypeTag;
        // Building Name
        const buildingName = this.i18NService.getTranslation(listing.title);
        const neighborhood = listing.neighborhood;
        const cityName = listing.city ? this.i18NService.getTranslation(listing.city) : undefined;
        const stateName = listing.state ? this.i18NService.getTranslation(listing.state) : undefined;
        const address = this.i18NService.getTranslation(listing.address);
        const company = listing.companyName;
        metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;
        metaTagSubject = buildingName + ', ' + stateName + ', ' + cityName;
        if (neighborhood) {
            metaTagSubject += ', ' + neighborhood;
        }
        metaTagSubject += ' - ' + company + ' - ' + listingTypeTag;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(buildingName);
        keywordsMetaTags.push(listingTypeTag);
        keywordsMetaTags.push(propertyTypeTag);
        keywordsMetaTags.push(cityName);
        keywordsMetaTags.push(stateName);
        keywordsMetaTags.push(neighborhood);
        keywordsMetaTags.push(address);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag, metaTagSubject };
    }
    removeHTMLCode(htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    }
    getMediumImg(id) {
        return this.imgService.mediumThumbnail(id);
    }
    addCanonicalTag(currentPath) {
        let path = currentPath;
        if (path == null || path === undefined) {
            return path;
        }
        //if (path.endsWith("/en")) {
        //  path = path.slice(0, path.length - 8);
        //}
        /* const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = path;
        document.head.appendChild(linkTag); */
        var existingCanonicalLink = document.querySelector(`link[rel='canonical']`);
        if (!existingCanonicalLink) {
            const linkTag = document.createElement('link');
            linkTag.setAttribute('rel', 'canonical');
            linkTag.href = path;
            document.head.appendChild(linkTag);
        }
        else {
            existingCanonicalLink.href = path;
        }
    }
}
