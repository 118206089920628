import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import { RegisterService } from '../register.service';
export class StatusHelperService {
    constructor(i18NService, registerService) {
        this.i18NService = i18NService;
        this.registerService = registerService;
    }
    updateSelectedStatusesBasedOnDTO(listingSearchDto) {
        this.selectStatus = [];
        if (listingSearchDto.listingStatuses && this.statuses) {
            if (listingSearchDto.listingStatuses.length > 0) {
                listingSearchDto.listingStatuses.forEach(statusTag => {
                    const statusFound = this.statuses.find(status => status.id == statusTag);
                    if (statusFound) {
                        this.selectStatus.push(statusFound);
                    }
                });
            }
        }
        return this.selectStatus;
    }
    /**
     // tslint:disable-next-line: jsdoc-format
     * Get the current selected status Ids
     */
    getStatusIds() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let arr = [];
            Object.keys(this.selectStatus).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                arr.push(this.selectStatus[key].id);
            }));
            return arr;
        });
    }
    removeStatus(status) {
        this.selectStatus.forEach((item, index) => {
            if (item.id == status.id) {
                this.selectStatus.splice(index, 1);
                return;
            }
        });
    }
    /**
     * Returns translated statuses.
     */
    getStatuses() {
        return this.getStatusType().then(statusType => {
            this.statuses = statusType.map((item) => {
                const clonedStatus = { status: item.status };
                const clonedItem = Object.assign({}, item, { status: this.i18NService.getTranslation(clonedStatus.status) });
                return clonedItem;
            });
        });
    }
    getStatusType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.statusType) {
                this.statusType = yield this.registerService.getListingStatus().toPromise();
            }
            return this.statusType;
        });
    }
}
