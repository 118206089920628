import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageService, I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
import { FormBuilder, Validators } from '@angular/forms';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { AgentNotificationComponent } from '@app/search-navigation/components/agent-notification/agent-notification.component';
import { PopoverController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AuthenticationService } from '@app/core/auth';
import { LoginFormDialogService } from '@app/shared/login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
export class ListUserComponent {
    constructor(breakpointObserver, route, router, fb, listingDetailService, location, _ts, imgService, listingService, userActivityService, i18nService, whatsAppService, agentNotPopOverCtrl, analyticsService, deviceDetector, auth, loginFormDialog, dialog) {
        this.breakpointObserver = breakpointObserver;
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.listingDetailService = listingDetailService;
        this.location = location;
        this._ts = _ts;
        this.imgService = imgService;
        this.listingService = listingService;
        this.userActivityService = userActivityService;
        this.i18nService = i18nService;
        this.whatsAppService = whatsAppService;
        this.agentNotPopOverCtrl = agentNotPopOverCtrl;
        this.analyticsService = analyticsService;
        this.deviceDetector = deviceDetector;
        this.auth = auth;
        this.loginFormDialog = loginFormDialog;
        this.dialog = dialog;
        this.sendMessage = false;
        this.loading = true;
        this.submitted = false;
        this.brokerEmail = false;
        this.brokerPhone = false;
        this.unsetBioOverflow = true;
        this.currentPage = 1;
        this.totalListingsCount = 0;
        this.isLoggedIn = false;
        this.whatsappPhone = false;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
            this.isMobile = result.matches;
        });
        const navigation = this.router.getCurrentNavigation();
        this.listingId =
            navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
    }
    ngOnInit() {
        try {
            this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
            this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
            this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
            if (this.isDesktop.length) {
                this.currentDevice = this.isDesktop;
            }
            else if (this.isMobilePhone.length) {
                this.currentDevice = this.isMobilePhone;
            }
            else if (this.isTablet.length) {
                this.currentDevice = this.isTablet;
            }
            this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
            this.loading = true;
            this.userId = this.route.snapshot.params.id;
            this.createForm();
            this.getUserInfo(this.userId)
                .then((result) => {
                this.broker = result;
                11;
                let id = { offeredByUserId: this.broker.userId };
                this.listingService.searchListings(id, this.currentPage).then(response => {
                    this.totalListingsCount = parseInt(response.headers.get('x-total-count'));
                    this.listings = response.body.listings;
                    this.listingIds = response.body.allListingIds;
                    this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
                    this.loading = false;
                });
            })
                .catch((e) => {
                const state = {
                    badURL: `${environment.spotServerUrl}/listing-user/${this.userId}`
                };
                console.error('Listing User page', e, this.userId);
                this.router.navigateByUrl('/not-found', { state: state });
            });
        }
        catch (e) {
            const state = {
                badURL: `${environment.spotServerUrl}/listing-user/${this.userId}`
            };
            console.error('Listing User page', e, this.userId);
            this.router.navigateByUrl('/not-found', { state: state });
        }
    }
    ngOnDestroy() {
        this.loginSubscription.unsubscribe();
    }
    unsetOverflow() {
        this.unsetBioOverflow = true;
    }
    get form() {
        return this.angForm.controls;
    }
    checkOverflow() {
        let el = document.getElementById('#bio');
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === 'visible')
            el.style.overflow = 'hidden';
        var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
    }
    getMoreListings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            let id = { offeredByUserId: this.broker.userId };
            this.listingService.searchListings(id, ++this.currentPage).then(response => {
                let newListings = response.body.listings;
                this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
                if (newListings && newListings.length) {
                    this.listings = this.listings.concat(newListings);
                }
                this.loading = false;
            });
        });
    }
    createForm() {
        this.angForm = this.fb.group({
            name: ['', Validators.required],
            phone: [''],
            email: [''],
            note: ['', Validators.required]
        }, { validator: this.checkFields });
    }
    checkFields(formGroup) {
        let emailField = formGroup.controls['email'].value;
        let phoneField = formGroup.controls['phone'].value;
        return !emailField.length && !phoneField.length ? { oneRequired: true } : null;
    }
    goBack() {
        this.location.back();
    }
    isHeaderVisible(event) {
        this.displayScrollToTop = event.visible ? false : true;
    }
    getMediumImg(id) {
        return this.imgService.mediumThumbnail(id);
    }
    openShowMoreLogInModal(id) {
        const subscribeDialog = this.dialog.open(SubscribeModalComponent, {
            width: '400px',
            panelClass: 'my-custom-dialog-class',
            data: id
        });
        subscribeDialog.afterClosed().subscribe(val => {
            if (val != undefined) {
                switch (val) {
                    case 1:
                        this.showBrokerPhone(val);
                        break;
                    case 2:
                        this.showBrokerEmail(val);
                        break;
                    case 3:
                        this.showBrokerWhatsapp(val);
                        break;
                    default:
                        return null;
                }
            }
        });
    }
    showBrokerPhone(notSubscribe = null) {
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(1);
                return;
            }
        }
        this.brokerPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
    }
    showBrokerEmail(notSubscribe = null) {
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(2);
                return;
            }
        }
        this.brokerEmail = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    }
    showBrokerWhatsapp(notSubscribe = null) {
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(3);
                return;
            }
        }
        this.whatsappPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    }
    registerUserActivity(userActivityType) {
        const userActivity = {
            module: userActivityType,
            brokerId: this.broker.userId,
            listingId: null
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
    getUserInfo(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.listingDetailService.getUserById(id, null).toPromise();
        });
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
    clickToChat() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headerMsg = this.i18nService.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
            const msgToUserAgent = this.i18nService.get('global.broker-detail.clickToChat.msgToUserAgent');
            const spotURL = environment.spotServerUrl.concat(this.i18nService.getLanguagePathURL());
            const message = `${headerMsg} ${spotURL}

${msgToUserAgent}
    `;
            this.whatsAppService.sendMessageViaWhataspp(this.broker.whatsappNumber, message);
            return false;
        });
    }
    clickToEmail(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const defaultMsg = this.i18nService.get('global.broker-detail.clickToEmail.msgToUserAgent');
            const message = `${defaultMsg}`;
            const popover = yield this.agentNotPopOverCtrl.create({
                component: AgentNotificationComponent,
                event: ev,
                cssClass: 'popover-agent-email',
                componentProps: {
                    defaultMsg: message,
                    brokerId: this.broker.userId,
                    popupCtrl: this.agentNotPopOverCtrl,
                    pageURLFrom: environment.spotServerUrl
                }
            });
            return yield popover.present();
        });
    }
    openEmail() {
        window.location.href = `mailto:${this.broker.email}`;
        return false;
    }
}
