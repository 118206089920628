import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Subject } from 'rxjs';
import { LazySliderService } from '../services/lazy-slider.service';
import { cloneDeep } from 'lodash';
export class LazySliderComponent {
    constructor(lazySliderSvc) {
        this.lazySliderSvc = lazySliderSvc;
        this.noDataMsg = 'No Data Found';
        this.showPager = false;
        this.showScrollbar = false;
        this.loadedItems = []; // Unloaded items should be a falsy value like "null".
        this.loading = false;
        this.changeSlide$ = null;
        this.getMoreItems = new EventEmitter();
        this.onSliderUpdate = new EventEmitter();
        this.pagerSubscription = null;
        this.defaultSliderOptions = cloneDeep(this.lazySliderSvc.defaultSliderOpts);
    }
    ngOnChanges(changes) {
        if (this.slider && changes.loadedItems) {
            this.slider.update();
            this.slider.lockSwipes(false);
        }
    }
    ngOnInit() {
        this.setSliderOptions();
        this.subscribeToSlideChange();
    }
    ngOnDestroy() {
        if (this.pagerSubscription) {
            this.pagerSubscription.unsubscribe();
        }
    }
    setSliderOptions() {
        this.sliderOptions = Object.assign({}, this.defaultSliderOptions, this.slideOptionOverride);
    }
    subscribeToSlideChange() {
        if (!this.changeSlide$)
            return;
        this.pagerSubscription = this.changeSlide$.subscribe(Direction => {
            if (Direction === 'next')
                return this.slideNext();
            if (Direction === 'prev')
                return this.slidePrev();
        });
    }
    onSlidesInit() {
        this.slider.update().then(() => {
            this.setSlidesPerView(window.innerWidth);
            this.notifyChange();
        });
    }
    getPaginationInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const activeIndex = yield this.slider.getActiveIndex();
            const currentPage = Math.ceil((activeIndex + 1) / this.slidesPerView);
            return { activeIndex, currentPage };
        });
    }
    slidePrev() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.slider.slidePrev();
        });
    }
    slideNext() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.slider.slideNext();
        });
    }
    onSlideNextEnd() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { currentPage } = yield this.getPaginationInfo();
            const loadedAmount = this.loadedItems.filter((i) => i).length;
            const missingItems = currentPage * this.slidesPerView > loadedAmount;
            if (missingItems && loadedAmount < this.totalCount) {
                this.fetchItems();
            }
        });
    }
    fetchItems() {
        this.slider.lockSwipes(true);
        this.getMoreItems.emit({
            pageSize: this.queryPageSize,
            pageNumber: this.queryPage + 1
        });
    }
    onResize(event) {
        this.setSlidesPerView(event.target.innerWidth);
    }
    setSlidesPerView(containerWidth) {
        if (this.sliderOptions.breakpoints) {
            const breakpointProps = this.lazySliderSvc.getBreakPointProps(containerWidth, this.sliderOptions.breakpoints);
            if (this.slidesPerView === breakpointProps.slidesPerView)
                return;
            this.slidesPerView = breakpointProps.slidesPerView;
            this.sliderOptions = Object.assign({}, cloneDeep(this.sliderOptions), breakpointProps);
        }
    }
    notifyChange() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isEnd = yield this.slider.isEnd();
            const isBeginning = yield this.slider.isBeginning();
            const sliderState = { isEnd, isBeginning };
            this.onSliderUpdate.emit(sliderState);
        });
    }
}
