import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService, PurchaseCreditsService } from '@app/core';
import { ListingService } from '@app/core/listings';
import { BuildingDTO } from '@app/models/building.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { ListingOfferType, ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import * as Fuse from 'fuse.js';
import { DeviceDetectorService } from 'ngx-device-detector';
export class SpotBuildingsDetailComponent {
    constructor(purchaseService, listingService, i18NService, createSpotBuildingsService, alertCtrl, popoverCtrl, deviceDetector, router, navCtrl) {
        this.purchaseService = purchaseService;
        this.listingService = listingService;
        this.i18NService = i18NService;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.alertCtrl = alertCtrl;
        this.popoverCtrl = popoverCtrl;
        this.deviceDetector = deviceDetector;
        this.router = router;
        this.navCtrl = navCtrl;
        this.building = new BuildingDTO();
        this.inputBuilding = '';
        this.stopFuzzy = false;
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: false,
            zoom: 15
        };
        this.changePropertyType = false;
        this.count = 0;
        this.isUpdate = false;
    }
    ngOnInit() {
        this.checkBuildingType(2001);
        this.multiLangInit();
        this.checkBuildingOffer(1, 1);
        this.checkListingType(1);
        this.checkBuildingTypeSale(false);
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
        this.spotBuildings.preferredCurrency = 'MXN';
        this.spotBuildings.validCurrency = null;
    }
    ngOnChanges(changes) {
        if (this.buildingDetail) {
            this.isUpdate = true;
            this.checkBuildingType(this.buildingDetail.building.buildingType.id);
            this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
            this.checkListingType(this.buildingDetail.listingType == ListingType.Featured
                ? 1
                : this.buildingDetail.listingType == ListingType.Premium
                    ? 2
                    : this.buildingDetail.listingType == ListingType.Promoted
                        ? 3
                        : 4);
            this.setProperty(this.buildingDetail.building, true);
            this.checkBuildingTypeSale(this.buildingDetail.entireSale);
            if (this.buildingDetail.offMarket) {
                this.spotBuildings.offMarket = this.buildingDetail.offMarket;
            }
            if (this.buildingDetail.featured) {
                this.spotBuildings.featured = this.buildingDetail.featured;
            }
            if (this.buildingDetail.enabled) {
                this.spotBuildings.enabled = this.buildingDetail.enabled;
            }
            this.spotBuildings.preferredCurrency = this.buildingDetail.preferredCurrency;
            this.spotBuildings.validCurrency = this.buildingDetail.validCurrency;
        }
    }
    clearInput() {
        this.inputBuilding = '';
        this.building.id = null;
        this.spotBuildings.building = this.building;
        this.spotBuildings.propertyName = '';
        this.stopFuzzy = true;
    }
    initBuildingType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                keys: ['name'],
                shouldSort: true,
                caseSensitive: false,
                threshold: 0.5,
                findAllMatches: true
            };
            this.fuse = new Fuse(this.buildings, options);
        });
    }
    checkBuildingOffer(typeOffer, init = 0) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spotBuildings.type = '';
            let isValid = init != 0
                ? true
                : yield this.isValidProperty(this.spotBuildings.building ? this.spotBuildings.building : null, typeOffer == 1
                    ? ListingOfferType.Lease
                    : typeOffer == 2
                        ? ListingOfferType.Coworking
                        : ListingOfferType.Sale, 1);
            if (isValid) {
                if (typeOffer == 1) {
                    this.spotBuildings.type = ListingOfferType.Lease;
                    this.spotBuildings.entireSale = false;
                }
                if (typeOffer == 2) {
                    this.spotBuildings.type = ListingOfferType.Coworking;
                    this.spotBuildings.entireSale = false;
                    if (this.propertyType[0] != 2001) {
                        this.checkBuildingType(2001);
                    }
                }
                if (typeOffer == 3) {
                    this.spotBuildings.type = ListingOfferType.Sale;
                }
            }
        });
    }
    checkListingType(listingType) {
        if (listingType == 1) {
            this.spotBuildings.listingType = ListingType.Featured;
            return;
        }
        if (listingType == 2) {
            this.spotBuildings.listingType = ListingType.Premium;
            return;
        }
        if (listingType == 3) {
            this.spotBuildings.listingType = ListingType.Promoted;
            return;
        }
        if (listingType == 4) {
            this.spotBuildings.listingType = ListingType.Basic;
            return;
        }
    }
    checkBuildingType(buildingType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.clearInput();
            if (buildingType === 1001) {
                if (!this.industrialBuildings) {
                    this.industrialBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(1001);
                    this.buildingNames = this.industrialBuildings;
                    this.buildings = this.industrialBuildings.values;
                }
                else {
                    this.buildingNames = this.industrialBuildings;
                    this.buildings = this.industrialBuildings.values;
                }
                this.propertyType[0] = 1001;
                this.stopFuzzy = false;
            }
            if (buildingType === 2001) {
                if (!this.officeBuildings) {
                    this.officeBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(2001);
                    this.buildingNames = this.officeBuildings;
                    this.buildings = this.officeBuildings.values;
                }
                else {
                    this.buildingNames = this.officeBuildings;
                    this.buildings = this.officeBuildings.values;
                }
                this.propertyType[0] = 2001;
                this.stopFuzzy = false;
            }
            if (buildingType === 3001) {
                if (!this.retailBuildings) {
                    this.retailBuildings = yield this.purchaseService.getBuildingNamesByPropertyType(3001);
                    this.buildingNames = this.retailBuildings;
                    this.buildings = this.retailBuildings.values;
                }
                else {
                    this.buildingNames = this.retailBuildings;
                    this.buildings = this.retailBuildings.values;
                }
                this.propertyType[0] = 3001;
                this.stopFuzzy = false;
            }
            this.initBuildingType();
        });
    }
    checkBuildingTypeSale(isTypeOfSale) {
        this.spotBuildings.entireSale = isTypeOfSale;
    }
    suggest_property(term) {
        if (term) {
            var q = term;
            return this.fuse
                .search(q)
                .slice(0, 10)
                .map((element) => {
                return element;
            });
        }
    }
    changeFuzzySearch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.changePropertyType) {
                this.stopFuzzy = this.inputBuilding == '' ? false : true;
                this.buildingNames = this.suggest_property(this.inputBuilding);
            }
            else {
                this.changePropertyType = false;
                this.stopFuzzy = false;
            }
        });
    }
    setProperty(building, update) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let isValid = yield this.isValidProperty(building, this.spotBuildings.type, 2, update);
            if (isValid) {
                this.stopFuzzy = true;
                this.inputBuilding = update ? this.i18NService.getTranslation(building.title) : building.name;
                this.building.id = building.id;
                this.spotBuildings.building = this.building;
                this.propertyName = update
                    ? building.title
                    : `{"en":"${building.name.replace(/"/g, '\\"')}","es":"${building.name.replace(/"/g, '\\"')}"}`;
                let property = `{"property":[${this.propertyName}]}`;
                this.propertyNameChange(JSON.parse(property));
                this.buildingNames = null;
                this.listingService.getBuilding(building.id).then(resp => {
                    this.actualBuilding = resp;
                    this.marker = { lat: this.actualBuilding.latitude, lng: this.actualBuilding.longitude };
                });
                this.changePropertyType = true;
                if (!update) {
                    this.listingService.getBuilding(building.id).then((resp) => {
                        this.checkFeaturesRequest(resp);
                    });
                }
            }
        });
    }
    isValidProperty(building, type, opt, update) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let resp;
            if (update) {
                return true;
            }
            if (building.id && type) {
                resp = yield this.createSpotBuildingsService
                    .validateProperty(building.id, type, this.i18NService.getCurrentLanguage())
                    .then((resp) => {
                    if (resp == null) {
                        return true;
                    }
                    else {
                        this.alertCtrl
                            .create({
                            header: this.i18NService.get('global.form.sendMsgToAgent.notification'),
                            message: `<span>${this.i18NService.get('buildinsDetail.validateProperty')} 
                <p class="color-p">${resp.detailsURL}</p></span>`,
                            buttons: [
                                {
                                    text: this.i18NService.get('global.form.sendMsgToAgent.ok'),
                                    handler: () => {
                                        /* this.popoverCtrl.dismiss(); */
                                        this.initBuilding(opt);
                                    }
                                },
                                {
                                    text: 'Go to Building',
                                    handler: () => {
                                        this.initBuilding(opt);
                                        this.openUrlBuilding(resp.detailsURL);
                                    }
                                }
                            ]
                        })
                            .then(alert => {
                            alert.present();
                        });
                        return false;
                    }
                });
            }
            else {
                resp = true;
            }
            return resp;
        });
    }
    openUrlBuilding(url) {
        if (this.isDesktop.length) {
            window.open(url, '_blank');
        }
        else {
            this.router.navigateByUrl(url);
        }
    }
    initBuilding(opt) {
        if (this.buildingDetail) {
            if (opt == 1) {
                this.checkBuildingOffer(this.buildingDetail.forLease ? 1 : this.buildingDetail.forSale ? 3 : 2, 1);
            }
            else {
                this.setProperty(this.buildingDetail.building, true);
            }
        }
        else {
            this.clearInput();
            this.checkBuildingOffer(1, 1);
        }
    }
    checkFeaturesRequest(features) {
        this.spotBuildings.twentyFourHourSecurity = features.twentyFourHourSecurity;
        this.spotBuildings.electricGenerator = features.electricGenerator;
        this.spotBuildings.heliport = features.heliport;
        this.spotBuildings.sprinklers = features.sprinklers;
        this.spotBuildings.leedStatus = features.leedStatus;
        this.spotBuildings.singleTenant = features.singleTenant;
        this.spotBuildings.multiTenant = features.multiTenant;
        this.spotBuildings.bicycleRack = features.bicycleRack;
        this.spotBuildings.fiberOptic = features.fiberOptic;
        this.spotBuildings.centralAirConditioning = features.centralAirConditioning;
        this.spotBuildings.petFriendly = features.petFriendly;
        this.spotBuildings.truckYard = features.truckYard;
        this.spotBuildings.crossDocking = features.crossDocking;
        this.spotBuildings.sluiceGate = features.sluiceGate;
        this.spotBuildings.blockAndSteelConstruction = features.blockAndSteelConstruction;
        this.spotBuildings.blockConstruction = features.blockConstruction;
        this.spotBuildings.steelConstruction = features.steelConstruction;
        this.spotBuildings.rentableArea = features.rentableArea;
        this.spotBuildings.ceilingHeight = features.ceilingHeight;
        this.spotBuildings.parkingSpaces = features.parkingSpaces;
        this.spotBuildings.industrialStatus = features.industrialStatus;
        this.spotBuildings.yearBuilt = features.yearBuilt;
        this.spotBuildings.carParkingSpaces = features.carParkingSpaces;
        this.spotBuildings.truckParkingSpaces = features.industrialParkingSpaces;
        this.spotBuildings.landArea = features.landArea;
        this.spotBuildings.numberStories = features.numberStories;
        this.spotBuildings.averageFloorPrivateArea = features.averageFloorPrivateArea;
        this.spotBuildings.address = this.validateAddress(features.address);
    }
    validateAddress(BuildingAddress) {
        let address = JSON.parse(BuildingAddress);
        return address['es'] && address['en']
            ? BuildingAddress
            : address['es']
                ? `{"en":"${address['es']}","es":"${address['es']}"}`
                : `{"en":"${address['en']}","es":"${address['en']}"}`;
    }
    multiLangInit() {
        this.propertyNameFieldOptions = {
            columnLabel: 'global.list-your-property.propertyNameDisplay',
            element: { input: true },
            formArrayName: 'property',
            required: true
        };
    }
    propertyNameChange(value) {
        if (value.property.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.property).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.property[key];
                if (obj['en'] === '' || obj['es'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? (this.spotBuildings.propertyName = '')
                : (this.spotBuildings.propertyName = JSON.stringify(mergedObject));
        }
        else {
            this.spotBuildings.propertyName = '';
        }
    }
    searchByCurrencyType(currencyType) {
        this.spotBuildings.preferredCurrency = currencyType;
        this.spotBuildings.validCurrency = null;
    }
    setValidCurrency(validVurrency) {
        this.spotBuildings.validCurrency = validVurrency;
    }
}
