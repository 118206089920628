import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { ListingService } from './listing.service';
import { TranslateService } from '@ngx-translate/core';
export class CreateListingService {
    constructor(http, listingService, _ts, fb) {
        this.http = http;
        this.listingService = listingService;
        this._ts = _ts;
        this.fb = fb;
        this.arr = {
            armoredCabin: false,
            manufacturing: false,
            //logistics: false,
            officeSpaceAvailable: false,
            //fireProtectionSystem: false,
            blockConstruction: false,
            steelConstruction: false,
            blockAndSteelConstruction: false,
            skyLights: false,
            multiTenant: false,
            singleTenant: false,
            //hasCarParkingSpaces: false,
            //hasTruckParkingSpaces: false,
            //industrialPark: false,
            //industrialZone: false,
            truckYard: false,
            crossDocking: false,
            refrigerated: false,
            sluiceGate: false,
            hasLockerRooms: false,
            twentyFourHourSecurity: false,
            //hasCeilingHeight: false,
            heliport: false,
            sprinklers: false,
            electricGenerator: false,
            //hasParkingSpaces: false,
            plugAndPlay: false,
            //finishedOut: false,
            furnished: false,
            coreAndShel: false,
            centralAirConditioning: false,
            miniSplitAirConditioning: false,
            bicycleRack: false,
            leedStatus: false,
            raisedFloor: false,
            hourSecurity: false,
            neverUsed: false,
            hasRestrooms: false
        };
        this.offeringPrice = false;
        this.offeringPricePerArea = false;
        this.monthlyRent = false;
        this.monthlyRentPerArea = false;
        this.rentableArea = false;
        this.ceilingHeight = false;
        this.hasParkingSpaces = false;
        this.hasDockRatio = false;
        this.hasLoadCapacity = false;
        this.trckParkingSpaces = false;
        this.parkingSpots = false;
        this.baySize = false;
    }
    initCheckboxFields() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let obj = {
                offeringPrice: this.offeringPrice,
                offeringPricePerArea: this.offeringPricePerArea,
                monthlyRent: this.monthlyRent,
                monthlyRentPerArea: this.monthlyRentPerArea,
                rentableArea: this.rentableArea,
                ceilingHeight: this.ceilingHeight,
                hasParkingSpaces: this.hasParkingSpaces,
                hasDockRatio: this.hasDockRatio,
                hasLoadCapacity: this.hasLoadCapacity,
                trckParkingSpaces: this.trckParkingSpaces,
                parkingSpots: this.parkingSpots,
                baySize: this.baySize
            };
            return obj;
        });
    }
    checkFields(allFields) {
        this.arr = [];
        Object.keys(allFields).forEach(key => {
            if (allFields[key].fieldName === 'armoredCabin') {
                this.arr.armoredCabin = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'manufacturing') {
                this.arr.manufacturing = allFields[key].selected;
            }
            /*if (allFields[key].fieldName === 'logistics') {
              this.arr.logistics = true;
            }*/
            if (allFields[key].fieldName === 'officeSpaceAvailable') {
                this.arr.officeSpaceAvailable = allFields[key].selected;
            }
            /*if (allFields[key].fieldName === 'fireProtectionSystem') {
              this.arr.fireProtectionSystem = true;
            }*/
            if (allFields[key].fieldName === 'blockConstruction') {
                this.arr.blockConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'steelConstruction') {
                this.arr.steelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'blockAndSteelConstruction') {
                this.arr.blockAndSteelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'skyLights') {
                this.arr.skyLights = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'multiTenant') {
                this.arr.multiTenant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'singleTenant') {
                this.arr.singleTenant = allFields[key].selected;
            }
            /*if (allFields[key].fieldName === 'hasCarParkingSpaces') {
              this.arr.hasCarParkingSpaces = true;
            }
            if (allFields[key].fieldName === 'hasTruckParkingSpaces') {
              this.arr.hasTruckParkingSpaces = true;
            }*/
            /*if (allFields[key].fieldName === 'industrialZone') {
              this.arr.industrialZone = true;
            }*/
            if (allFields[key].fieldName === 'truckYard') {
                this.arr.truckYard = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'crossDocking') {
                this.arr.crossDocking = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'refrigerated') {
                this.arr.refrigerated = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sluiceGate') {
                this.arr.sluiceGate = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'hasLockerRooms') {
                this.arr.hasLockerRooms = allFields[key].selected;
            }
            //Common Filters
            if (allFields[key].fieldName === 'twentyFourHourSecurity') {
                this.arr.twentyFourHourSecurity = allFields[key].selected;
            }
            /*if (allFields[key].fieldName === 'hasCeilingHeight') {
              this.arr.hasCeilingHeight = true;
            }*/
            if (allFields[key].fieldName === 'heliport') {
                this.arr.heliport = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sprinklers') {
                this.arr.sprinklers = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'electricGenerator') {
                this.arr.electricGenerator = allFields[key].selected;
            }
            //Office filter
            /*if (allFields[key].fieldName === 'hasParkingSpaces') {
              this.arr.hasParkingSpaces = true;
            }*/
            if (allFields[key].fieldName === 'plugAndPlay') {
                this.arr.plugAndPlay = allFields[key].selected;
            }
            /*if (allFields[key].fieldName === 'finishedOut') {
              this.arr.finishedOut = true;
            }*/
            if (allFields[key].fieldName === 'furnished') {
                this.arr.furnished = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'coreAndShel') {
                this.arr.coreAndShel = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'centralAirConditioning') {
                this.arr.centralAirConditioning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'miniSplitAirConditioning') {
                this.arr.miniSplitAirConditioning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'bicycleRack') {
                this.arr.bicycleRack = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'leedStatus') {
                this.arr.leedStatus = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'raisedFloor') {
                this.arr.raisedFloor = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'hourSecurity') {
                this.arr.hourSecurity = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'neverUsed') {
                this.arr.neverUsed = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'hasRestrooms') {
                this.arr.hasRestrooms = allFields[key].selected;
            }
        });
        let filters = this.arr;
        return filters;
    }
    removeFalseValues(filters) {
        Object.keys(filters).forEach(key => {
            if (!filters[key]) {
                delete filters[key];
            }
        });
        return filters;
    }
}
