import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, IonInput, AlertController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
export class RemoveCreditsPopoverComponent {
    constructor(navParams, popoverCtrl, listingService, alertCtrl, i18nService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.btnFilter = false;
        this.loading = true;
        this.propertySubTypes = [];
        this.popDataCalled = false;
    }
    ngOnInit() {
        this.getPopData();
        //this.setMinOptions(this.rangeFilters, this.searchDto);
    }
    coworkingTypeChanged(event) {
        if (this.popDataCalled) {
            this.popDataCalled = false;
            return;
        }
        this.coworkingType = event.detail.value;
    }
    getPopData() {
        this.popDataCalled = true;
        this.coworkingType = 'shared';
        this.listingPurchaseId = this.navParams.data.listingPurchaseId;
        this.maxCredits = this.navParams.data.availableCredits;
        this.credits = 1;
    }
    remove() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.credits > this.maxCredits) {
                let paymentProcessedHeader = this.i18nService.get('global.my-payment.cannotRemoveCredits');
                let paymentProcessedSubHeader = this.i18nService.get('global.my-payment.cannotRemoveCreditsBody');
                let paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
                const alert = yield this.alertCtrl.create({
                    header: paymentProcessedHeader,
                    subHeader: paymentProcessedSubHeader,
                    buttons: [paymentProcessedOk]
                });
                yield alert.present();
                this.popoverCtrl.dismiss({});
                return;
            }
            this.listingService
                .removeListingCredits(this.listingPurchaseId, this.credits)
                .toPromise()
                .then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let paymentProcessedHeader = this.i18nService.get('global.my-payment.creditsRemoved');
                let paymentProcessedSubHeader = this.i18nService.get('global.my-payment.creditsRemovedBody');
                let paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
                const alert = yield this.alertCtrl.create({
                    header: paymentProcessedHeader,
                    subHeader: paymentProcessedSubHeader,
                    buttons: [paymentProcessedOk]
                });
                yield alert.present();
                this.popoverCtrl.dismiss({});
            }));
        });
    }
    cancel() {
        this.popoverCtrl.dismiss();
    }
    validateCredits() {
        //if (this.credits > this.maxCredits) {
        //  this.credits = 1;
        //}
    }
    handleKeyboardEvent(event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    }
}
