import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { ListingService } from '@app/core/listings';
import { SearchTagsService } from '@app/core/search-tags.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
export class NearbyListingMapComponent {
    constructor(breakpointObserver, nearbyListingSvc, listingSearchService, listingSvc, searchTagSvc) {
        this.breakpointObserver = breakpointObserver;
        this.nearbyListingSvc = nearbyListingSvc;
        this.listingSearchService = listingSearchService;
        this.listingSvc = listingSvc;
        this.searchTagSvc = searchTagSvc;
        this.ORANGE_PIN = this.nearbyListingSvc.orangePin;
        this.BLUE_PIN = this.nearbyListingSvc.bluePin;
        this.mapOptions = {};
        this.markers = [];
        this.filterStyle = 'filter-tag-container';
        this.isIos = false;
        this.getSelectPropertyListing = new EventEmitter();
        this.refetchListing = new EventEmitter();
        this.subscribe();
    }
    ngOnInit() {
        if (!this.searchTags) {
            this.findSearchTagsFromLocalStorage();
        }
        this.mapOptions = {
            singleMarker: false,
            ignoreZoom: false
        };
    }
    findSearchTagsFromLocalStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
            if (selectedFilterKey) {
                const searchDTO = this.listingSearchService.readSearchDTOFromStorage(selectedFilterKey, {});
                this.updateSearchTags(searchDTO);
            }
        });
    }
    ngOnChanges(changes) {
        const isListingChange = this.isListingChange(changes);
        if (isListingChange) {
            this.generateNearByMarker();
        }
    }
    subscribe() {
        this.listingSvc.currentModel.subscribe(model => {
            this.searchModel = model;
        });
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
            this.filterStyle = this.isMobile ? 'filter-tag-mobile' : 'filter-tag-container';
        });
    }
    isListingChange(changes) {
        return [changes['listings'] && changes['listings'].currentValue && changes['listings'].currentValue.length].every(condition => condition);
    }
    setMarkerEvent(markers, marker) {
        marker.addListener('click', () => {
            const prevSelectedMarker = this.nearbyListingSvc.prevSelectedMarker(markers, marker);
            if (prevSelectedMarker.length) {
                prevSelectedMarker[0].infoWindow.close();
                prevSelectedMarker[0].set('isSelected', false);
            }
            marker.infoWindow.open(marker.getMap(), marker);
            marker.set('isSelected', true);
            marker.infoWindow.addListener('closeclick', () => {
                marker.set('isSelected', false);
                this.getSelectPropertyListing.emit({ selectedBuilding: null, listings: [] });
            });
            this.getBuildingListing(marker);
        });
    }
    generateNearByMarker() {
        const currentListingMarker = this.nearbyListingSvc.createBasicMarker(this.currentListing.building, this.ORANGE_PIN);
        const listingMarkers = this.listings.reduce((accumulator, item) => {
            const isLatLngValid = this.nearbyListingSvc.hasValidCoordinate(item, 'lat', 'lng');
            if (isLatLngValid && item.id !== this.currentListing.building.id) {
                const infoWindow = this.nearbyListingSvc.createNearByListingInfoWindow(item);
                let marker = this.nearbyListingSvc.createMarker(item, infoWindow, this.BLUE_PIN, true);
                marker.set('buildingTitle', item.titleOnListing);
                this.setMarkerEvent(accumulator, marker);
                accumulator.push(marker);
            }
            return accumulator;
        }, []);
        this.markers = [currentListingMarker, ...listingMarkers];
    }
    getBuildingListing(selectedBuilding) {
        let searchDto = this.nearbyListingSvc.setNearbyListingSearchCriteria(this.searchCriteria.searchDTO, selectedBuilding.location);
        searchDto.listingIds = selectedBuilding.listingIds;
        this.listingSvc.getAllNearByPropertyListing(searchDto).then((result) => {
            this.getSelectPropertyListing.emit({
                listings: result.body.listings,
                selectedBuilding: selectedBuilding.buildingTitle
            });
        });
    }
    resetSearchFilter(searchDTO, checkBoxValues) {
        const searchProps = this.nearbyListingSvc.getClearSearchProperty();
        let checkboxFilters = Object.assign({}, checkBoxValues);
        searchProps.forEach(prop => {
            searchDTO[prop] = null;
        });
        Object.keys(checkboxFilters).forEach(key => {
            checkBoxValues[key] = false;
        });
        this.listingSvc.updateModel('clearsearch');
        this.refetchListing.emit({
            searchDTO: searchDTO,
            checkBoxValues: checkboxFilters
        });
    }
    updateSearchTags(searchDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.searchTags = yield this.searchTagSvc.updateSearchTags(searchDTO, false);
            return this.searchTags;
        });
    }
    setListingFilter(filters, fieldName) {
        const copyOfFilter = Object.assign({}, filters);
        const isLisingTypeArray = this.nearbyListingSvc.isArray(copyOfFilter, 'listingLeaseOrSale');
        if (fieldName.toLowerCase() === 'propertytype' || fieldName.toLowerCase() === 'officetype') {
            copyOfFilter['propertySubTypes'] = null;
            copyOfFilter['propertySubtypeIds'] = null;
            this.listingSvc.propertySubTypesFromHomepage = [];
        }
        copyOfFilter.listingLeaseOrSale = isLisingTypeArray ? copyOfFilter.listingLeaseOrSale : null;
        copyOfFilter.listingIds = null;
        return copyOfFilter;
    }
    isTagFieldExist(fieldName) {
        return [
            fieldName.toLowerCase() === 'rangeceilingheight',
            fieldName.toLowerCase() === 'baysize',
            fieldName.toLowerCase() === 'dockratio'
        ].some(condition => condition);
    }
    getMinRangeLocale(tag) {
        const isTagExist = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
        return !isTagExist || tag.min < 1 ? '1.0-0' : '1.2-2';
    }
    getMaxRangeLocale(tag) {
        const isTagExist = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
        return !isTagExist ? '1.0-0' : '1.2-2';
    }
    hasMinMaxRange(tag) {
        return tag.min || tag.max;
    }
    getNewListing(index, fieldName) {
        let checkBoxValues = this.nearbyListingSvc.setCheckboxesFilter(fieldName, this.searchCriteria.checkBoxValues);
        let searchDTO = this.searchTagSvc.removeTagFromSearchDTO(this.searchCriteria.searchDTO, fieldName, checkBoxValues);
        searchDTO = this.setListingFilter(searchDTO, fieldName);
        const selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
        this.listingSearchService.saveSearchDtoOnStorage(selectedFilterKey, searchDTO);
        this.searchModel.updateModelFromListingDetail = true;
        this.searchModel = this.nearbyListingSvc.resetSearchModelOnChange(fieldName, this.searchModel);
        this.listingSvc.updateModel(this.searchModel);
        this.searchTags.splice(index, 1);
        this.updateSearchTags(searchDTO).then(updatedTags => {
            this.refetchListing.emit({
                searchDTO: searchDTO,
                checkBoxValues: checkBoxValues,
                searchTags: updatedTags
            });
        });
    }
    clearSearch() {
        let searchDTO = this.nearbyListingSvc.resetNearbyFilterToDefault(this.searchCriteria.searchDTO);
        searchDTO = this.searchTagSvc.clearSearchTagsFromDTO(searchDTO);
        this.listingSearchService.saveSearchDtoOnStorage(SelectedFilterKey.REGULAR_SEARCH, searchDTO);
        this.searchTags = [];
        this.resetSearchFilter(searchDTO, this.searchCriteria.checkBoxValues);
    }
    isRestricted(tagName) {
        return [tagName.toLowerCase() === 'positions: 1'].some((condition) => condition);
    }
}
