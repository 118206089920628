import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
export class BackStepperGuard {
    //   @ViewChild('stepper', { static: true }) stepper: MatStepper;
    constructor(platform, router, location) {
        this.platform = platform;
        this.router = router;
        this.location = location;
    }
    canDeactivate(component, currentRoute, currentState) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.platform.is('mobileweb')) {
                return true;
            }
            const currentNavigation = this.router.getCurrentNavigation();
            const backButtonPressed = currentNavigation.trigger === 'popstate';
            if (!backButtonPressed || this.isPop) {
                return true;
            }
            this.location.go(currentState.url);
            if (component.isCompleted && component.lastStep) {
                component.lastStep = false;
                component.stepper.previous();
                return false;
            }
            if (component.isCompleted && !component.lastStep) {
                component.isCompleted = false;
                component.stepper.previous();
                return false;
            }
        });
    }
}
BackStepperGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackStepperGuard_Factory() { return new BackStepperGuard(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: BackStepperGuard, providedIn: "root" });
