import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import { GenericMapService } from '../generic-map.service';
import { ImageService } from '../image.service';
import { CommonGenericService } from '../common.service';
import { PlatformHelperService } from '@app/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { SizeIcon } from '@app/models/shared/shared-enums.model';
import { RoutingHelperService } from '../helpers/routing-helper.service';
import { FavoriteSpotService } from '../../shared/services/favorite-spot.service';
import { icon } from '@fortawesome/fontawesome-svg-core';
export class NearbyListingSvc {
    constructor(genericMapSvc, i18n, imageService, commonService, platformConfigHelper, routerHelper, favSpotService) {
        this.genericMapSvc = genericMapSvc;
        this.i18n = i18n;
        this.imageService = imageService;
        this.commonService = commonService;
        this.platformConfigHelper = platformConfigHelper;
        this.routerHelper = routerHelper;
        this.favSpotService = favSpotService;
        this.DEFAULT_LNG_LAT = { latitude: 19.43277777777, longitude: -99.133055555556 };
        this.DEFAULT_RADIUS = 5000;
        this.DEFAULT_LISTING_DETAIL_RADIUS = 5000;
        this.favIconSize = SizeIcon.LG;
        this.faHeart = faHeart;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    }
    findCountryByCoordinates(coordinate) {
        this.geoCoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
        let promise = new Promise((resolve, reject) => {
            this.geoCoder.geocode({ latLng: latlng }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                            if (results[0].address_components[i].types[b] == 'country') {
                                resolve(results[0].address_components[i].short_name);
                            }
                        }
                    }
                    resolve('nowhere');
                }
            });
        });
        return promise;
    }
    isValidLatitude(latitude) {
        return latitude >= -90 && latitude <= 90;
    }
    isValidLongitude(longitude) {
        return longitude >= -180 && longitude <= 180;
    }
    hasProperty(obj, prop) {
        return obj.hasOwnProperty(prop) && !isNaN(obj[prop]);
    }
    searchModelField() {
        return [
            'propertytype',
            'officetype',
            'sale',
            'lease',
            'keyword',
            'citystate',
            'coworkingtype',
            'coworkingpositions'
        ];
    }
    isArray(object, attr) {
        return object[attr] && object[attr].length;
    }
    createNearByListingInfoWindow(listing) {
        const infoWindowContent = `
     <div style="height:25px; width: fit-content; height: fit-content">
      <div style="display: flex; flex-flow:column nowrap">
        <div style="color: #043650; font-weight:600"> ${this.i18n.getTranslation(listing.titleOnListing)} </div>
        <div> ${this.i18n.getTranslation(listing.address)} </div>
        <div style="color: #ff9900; padding-top: 10px; padding-bottom: 5px; font-weight: 500">
          ${listing.listingIds.length} ${listing.listingIds.length === 1 ? this.i18n.get('global.singleListing') : this.i18n.get('global.pluralListing')}
        </div>
      <div>
    </div>`;
        return new google.maps.InfoWindow({
            content: infoWindowContent
        });
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getCityState(listing) {
        return `${this.i18n.getTranslation(listing.cityName)} - ${this.i18n.getTranslation(listing.stateName)}`;
    }
    infoWindowAction(listing) { }
    createSpotBuildingInfoWindow(listing, isLoggedIn) {
        let infoWindowContent = '';
        if (listing) {
            const imgURL = listing.mainImgId ? this.getMediumImg(listing.mainImgId) : this.defaultImage;
            infoWindowContent = `
      <div id="spotInfoWindowContainer" (click)="openDetailsPage()"  class="spot-info-window__container grid-two-cols">
       <div style="display: flex; flex-flow:column nowrap">
            <img
            class="spot-info-window__img"
            [defaultImage]="defaultImage"
            src="${imgURL}"
            lazyLoad="${imgURL}"
          />
       </div>
       <div class="flex-col-nowrap spot-info-window__datacontainer">
            <div class="flex spot-info-window__title">
              <div class="ellipsis__text">${this.i18n.getTranslation(listing.titleOnListing)} </div>
              <div style="margin-right: 10px" class="${this.buildFavBtnClassContainer(listing)}">
                  <ion-button id="favButton"
                  class="${this.buildFavBtnClass(listing)}"
                  expand="full"
                  size="small"
                  fill="clear"
                >
                ${icon(faHeart).html}
            </ion-button>
              </div>
            </div>
            <div class="ellipsis__text spot-info-window__header">${this.i18n.getTranslation(listing.address)} </div>
            <div>
              <span class="spot-info-window__header">
                ${this.getCityState(listing)}
              </span>
            </div>

            <div class="spot-info-window__topseparator">
              <div class="${this.showIfNot(listing.promoted)}">
                <div class="flex spot-info-window__subheader" style="margin-top: 4px;">
                    <span class="nowrap bold">${this.commonService.getAvailableAreaLabel(listing)}</span>
                    <span class="ellipsis__text padding-left-10">${this.getAvailableAreaText(listing)}</span>
                </div>
                <div class="flex spot-info-window__subheader">
                    <span class="nowrap bold">${this.commonService.getAskingRentPriceLabel(listing)} </span>
                    <span class="ellipsis__text padding-left-10">${this.getAvgAskingRent(listing)}</span>
                </div>
              </div>
              <div class="${this.showIf(listing.promoted)}">
                    <div style="display: flex;align-items: center; margin-top: 5px">                        
                    </div>
                </div>
            </div>
       </div>
     </div>`;
        }
        const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
        });
        infoWindow.addListener('domready', () => {
            let favBtnElement = document.getElementById('favButton');
            favBtnElement.addEventListener('click', (event) => {
                event.stopImmediatePropagation();
                const spot = Object.assign({}, listing, { id: listing.listingIds[0] });
                this.favSpotService.setFavoriteSpot(event, spot).then((resp) => {
                    listing.favorite = spot.favorite;
                    let currentFavClass = favBtnElement.className;
                    currentFavClass = currentFavClass.replace('is-favorite', '');
                    currentFavClass = currentFavClass.replace('no-favorite', '');
                    favBtnElement.className = `${currentFavClass} ${this.getFavClass(spot)}`;
                });
            });
            document.getElementById('spotInfoWindowContainer').addEventListener('click', (event) => {
                this.routerHelper.navigateTo(listing.spotURL);
            });
        });
        return infoWindow;
    }
    buildFavBtnClassContainer(listing) {
        const customClasses = `flex-align-content-end `;
        return customClasses;
    }
    buildFavBtnClass(listing) {
        const favClass = this.getFavClass(listing);
        const customClasses = `spot-info-window__title__favorite-button ${favClass} `;
        return customClasses;
    }
    showIfNot(condition) {
        return this.showIf(!condition);
    }
    showIf(condition) {
        return condition ? '' : 'spot-info-not-visible';
    }
    getFavClass(listing) {
        return listing.favorite ? 'is-favorite' : 'no-favorite';
    }
    getAvailableAreaText(spot) {
        return this.commonService.getAvailableAreaText(spot.spaceRangesDTO, spot.type);
    }
    getAvgAskingRent(spot) {
        return this.commonService.getAvgAskingRentNearby(spot, spot.type);
    }
    prevSelectedMarker(markers, marker) {
        return markers.filter(item => {
            return item.id !== marker.id && item.isSelected;
        });
    }
    updatePrevSelectedMarker(marker) {
        marker.infoWindow.close();
        marker.setIcon(this.genericMapSvc.BLUE_PIN);
        marker.set('isSelected', false);
    }
    createMarker(listing, infoWindow, icon, isListingDetail) {
        let marker = new google.maps.Marker({
            position: {
                lat: listing.lat,
                lng: listing.lng
            },
            map: null,
            icon: icon ? icon : this.genericMapSvc.BLUE_PIN,
            draggable: false
        });
        if (isListingDetail) {
            marker.set('location', {
                latitude: listing.lat,
                longitude: listing.lng,
                radius: this.DEFAULT_LISTING_DETAIL_RADIUS
            });
        }
        marker.set('infoWindow', infoWindow);
        marker.set('id', listing.id);
        marker.set('listingIds', listing.listingIds);
        return marker;
    }
    createBasicMarker(listing, icon) {
        let marker = new google.maps.Marker({
            position: {
                lat: listing.latitude,
                lng: listing.longitude
            },
            map: null,
            icon: icon,
            draggable: false
        });
        marker.set('id', listing.id);
        marker.set('isCurrentListing', true);
        return marker;
    }
    get bluePin() {
        return this.genericMapSvc.BLUE_PIN;
    }
    get orangePin() {
        return this.genericMapSvc.ORANGE_PIN;
    }
    getNoResultMessage(resultCount) {
        return resultCount > 0 ? '' : this.i18n.get('global.search.noListingsFound');
    }
    getSpotNearMeMapMode(activatedRoute) {
        return [
            activatedRoute.snapshot.queryParams,
            activatedRoute.snapshot.queryParams.type &&
                activatedRoute.snapshot.queryParams.type.toLowerCase() === 'spotsnearme'
        ].every(condition => condition);
    }
    getSpotRadius(propertyType) {
        if (!propertyType) {
            return this.DEFAULT_RADIUS;
        }
        if (propertyType == '1001') {
            return 30000;
        }
        else {
            return this.DEFAULT_RADIUS;
        }
    }
    getUserLocationCoordinate(coordinate, propertyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let geoLocation = { latitude: -1, longitude: -1 };
            let promise = new Promise((resolve, reject) => {
                geoLocation.latitude = coordinate.latitude;
                geoLocation.longitude = coordinate.longitude;
                this.findCountryByCoordinates(geoLocation).then(country => {
                    const radius = this.getSpotRadius(propertyType);
                    let coord = country == 'MX'
                        ? {
                            latitude: coordinate.latitude,
                            longitude: coordinate.longitude,
                            radius: radius
                        }
                        : Object.assign({ radius: radius }, this.DEFAULT_LNG_LAT);
                    resolve(coord);
                });
            });
            return promise;
        });
    }
    isSpotsNearMeSearch(state, attr, stateName) {
        return state.hasOwnProperty(attr) && state[attr].toLowerCase() === stateName.toLowerCase();
    }
    unsetListingSearchDTO(searchDTO, attrs) {
        let newDTO = Object.assign({}, searchDTO);
        attrs.forEach(attr => {
            newDTO[attr] = null;
        });
        return newDTO;
    }
    setNearbySpotParam(dto, listings) {
        return {
            searchDTO: dto,
            listingIds: listings
        };
    }
    hasValidCoordinate(coordinate, lat, lng) {
        const hasLat = this.hasProperty(coordinate, lat);
        const hasLng = this.hasProperty(coordinate, lng);
        const isValidLat = hasLat ? this.isValidLatitude(coordinate[lat]) : false;
        const isValidLng = hasLng ? this.isValidLongitude(coordinate[lng]) : false;
        return isValidLat && isValidLng;
    }
    setRegularSearchActiveRoute(currentRoute, isSpotNearMe) {
        let activeRoute = '';
        if (isSpotNearMe !== null) {
            activeRoute = isSpotNearMe && currentRoute.toLowerCase() === '/search' ? '' : 'active-route';
        }
        return activeRoute;
    }
    setSpotNearMeActiveRoute(currentRoute, isSpotNearMe) {
        let activeRoute = '';
        if (isSpotNearMe !== null) {
            activeRoute = isSpotNearMe && currentRoute.toLowerCase() === '/search' ? 'active-route' : '';
        }
        return activeRoute;
    }
    resetActiveRoute(currentRoute, currentActiveRoute) {
        return currentRoute === '/' ? '' : currentActiveRoute;
    }
    getDefaultUserCoordinate() {
        return Object.assign({ radius: 1000 }, this.DEFAULT_LNG_LAT);
    }
    getGeoLocationErrorCode(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                return 'global.geoLocation.permissionDenied';
            case error.POSITION_UNAVAILABLE:
                return 'global.geoLocation.positionUnavailable';
            case error.TIMEOUT:
                return 'global.geoLocation.timeout';
            case error.UNKNOWN_ERROR:
                return 'global.geoLocation.unknownError';
        }
    }
    getSearchCriteriaState(searchState, stateAttr) {
        return searchState && searchState.hasOwnProperty(stateAttr) && searchState[stateAttr] ? searchState[stateAttr] : {};
    }
    setNearbyListingSearchCriteria(currentSearchDto, listing) {
        return Object.assign(currentSearchDto, {
            latitude: listing.latitude,
            longitude: listing.longitude,
            listingIds: null,
            radius: this.DEFAULT_LISTING_DETAIL_RADIUS
        });
    }
    scrollIntoViewElem(elem) {
        setTimeout(() => {
            elem.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }
    setCheckboxesFilter(filterName, filter) {
        let copyOfFilter = Object.assign({}, filter);
        const isFilterPropertyExist = copyOfFilter.hasOwnProperty(filterName);
        if (isFilterPropertyExist) {
            copyOfFilter[filterName] = false;
        }
        return copyOfFilter;
    }
    isTagFieldExist(fieldName) {
        return [
            fieldName.toLowerCase() === 'rangeceilingheight',
            fieldName.toLowerCase() === 'baysize',
            fieldName.toLowerCase() === 'dockratio'
        ].some(condition => condition);
    }
    getClearSearchProperty() {
        return [
            'propertySubTypes',
            'propertySubtypeId',
            'coworkingPositions',
            'coworkingType',
            'buildingTypes',
            'listingLeaseOrSale',
            'stateId',
            'cityId',
            'keyword'
        ];
    }
    resetNearbyFilterToDefault(filters) {
        const permanentProps = ['latitude', 'longitude', 'radius'];
        const copyOfFilter = Object.assign({}, filters);
        Object.keys(copyOfFilter).forEach(key => {
            const isKeyPermanent = permanentProps.includes(key);
            if (!isKeyPermanent) {
                copyOfFilter[key] = null;
            }
        });
        return copyOfFilter;
    }
    resetSearchModelOnChange(fieldName, models) {
        const searchModels = this.searchModelField();
        Object.keys(models).forEach(key => {
            if (searchModels.includes(fieldName.toLowerCase())) {
                models[key] = null;
            }
        });
        return models;
    }
}
function country(country, string) {
    throw new Error('Function not implemented.');
}
