import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, AfterViewChecked } from '@angular/core';
import { LoginFormDialogService } from '../login';
import { AccountService } from '@app/core/auth';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PWAService } from '../pwa-service';
import { PlatformHelperService, I18nService, ImageService } from '@app/core';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { InstallPromptDialogService } from '@app/core/install-prompt.service';
import { faUserCircle, faBell, faHeart, faSyncAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AuthenticationService } from '../../core/auth/auth.service';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { ListingDetailService, ListingService } from '@app/core/listings';
import { MatDialog } from '@angular/material';
import { RegisterFormComponent } from '../register';
import { Store } from '@ngrx/store';
import * as CartActions from '@app/store/actions';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CommonGenericService } from '@app/core/common.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
export class HeaderComponent {
    constructor(loginFormDialog, router, pwaService, platformHelper, alertCtrl, promptService, deviceDetector, gaService$, i18NService, auth, accountService, location, messageService, listingDetailService, dialog, userActivityService, imageService, listingService, store, commonService, routingHelperSrv) {
        this.loginFormDialog = loginFormDialog;
        this.router = router;
        this.pwaService = pwaService;
        this.platformHelper = platformHelper;
        this.alertCtrl = alertCtrl;
        this.promptService = promptService;
        this.deviceDetector = deviceDetector;
        this.gaService$ = gaService$;
        this.i18NService = i18NService;
        this.auth = auth;
        this.accountService = accountService;
        this.location = location;
        this.messageService = messageService;
        this.listingDetailService = listingDetailService;
        this.dialog = dialog;
        this.userActivityService = userActivityService;
        this.imageService = imageService;
        this.listingService = listingService;
        this.store = store;
        this.commonService = commonService;
        this.routingHelperSrv = routingHelperSrv;
        this.toggleMenu = new EventEmitter();
        this.appInstalled$ = new Observable();
        this.sideMenuOpen = false;
        this.showBorder = false;
        this.userName = '';
        this.messageCount = 0;
        this.favoritesCount = 0;
        this.loadUpdate = false;
        this.userAccount = {};
        this.screenWidth = window.innerWidth;
    }
    ngOnInit() {
        this.customLoginText = this.i18NService.get('global.form.defaultSignInMessage');
        this.isIos = this.platformHelper.isIosChrome() || this.platformHelper.isIosSafari();
        this.appInstalled$ = this.pwaService.appInstalled$;
        this.faUserCircle = faUserCircle;
        this.faBell = faBell;
        this.faHeart = faHeart;
        this.faSyncAlt = faSyncAlt;
        this.downArrow = faChevronDown;
        this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
        this.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.userNameSubscription = this.accountService.currentUser$.subscribe(user => {
            if (user) {
                this.userName = `${user.firstName} ${user.lastName}`;
                this.getFavoritesAndMessagesCount();
                if (!user.profileImage) {
                    this.getUserProfile(user);
                }
            }
            else {
                this.userName = '';
                this.resetFavoritesAndMessagesCount();
            }
        });
    }
    getUserProfile(userAccount) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userPhotos = yield this.accountService.getUserPhotoList().toPromise();
            const image = this.accountService.getUploadedImageId(userPhotos);
            this.userAccount = {
                profile: userAccount,
                profileImage: this.getMediumImg(image.profileImageId),
                originalImage: this.getMediumImg(image.originalImageId),
                name: userAccount.firstName + ' ' + userAccount.lastName
            };
            //localStorage.setItem('user', this.userAccount);
            // this.accountService.currentUser$.next(this.userAccount);
        });
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    ngAfterViewChecked() {
        if (localStorage.getItem('isDataAvailableReload') != null) {
            this.loadUpdate = true;
        }
    }
    loadNewUpdate() {
        localStorage.removeItem('isDataAvailableReload');
        window.location.reload();
    }
    removeTooltips() {
        while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
            document.getElementsByTagName('mat-tooltip-component')[0].remove();
        }
    }
    getFavoritesAndMessagesCount() {
        this.listingDetailService.updateFavoritesCount();
        this.favoritesCountSubscription = this.listingDetailService.favoritesCountSubject.subscribe((val) => {
            this.favoritesCount = val;
        });
        this.msgCountSubscription = this.messageService.unreadMsgSubject.subscribe((val) => {
            this.messageCount = val;
        });
    }
    resetFavoritesAndMessagesCount() {
        this.favoritesCount = 0;
        this.messageCount = 0;
    }
    ngOnDestroy() {
        this.userNameSubscription.unsubscribe();
        if (this.favoritesCountSubscription) {
            this.favoritesCountSubscription.unsubscribe();
        }
        if (this.msgCountSubscription) {
            this.msgCountSubscription.unsubscribe();
        }
    }
    goToUserHome() {
        this.removeTooltips();
        return this.router.navigateByUrl('/user-home');
    }
    goToMyAccount() {
        this.removeTooltips();
        return this.router.navigateByUrl('/user-home/my-account');
    }
    goToFavorites() {
        this.removeTooltips();
        this.routingHelperSrv.navigateFavoritesPage();
    }
    isAppInstalled() {
        return this.pwaService.appInstalled$;
    }
    toggleSideMenu() {
        this.sideMenuOpen = !this.sideMenuOpen;
        this.toggleMenu.emit(this.sideMenuOpen);
    }
    promptInstallPWA() {
        this.userActivityService.trackInstallApp();
        if (this.isAppInstalled()) {
            this.pwaService.promptPWAInstall();
        }
        this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
    }
    promptIos() {
        this.userActivityService.trackInstallApp();
        this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
        if (this.isIphone && (this.isChrome || this.platformIsIosChrome())) {
            this.promptIosChromeDialog();
        }
        else {
            this.promptService.openDialog(null, true);
        }
    }
    promptIosChromeDialog() {
        this.presentAlert();
    }
    isMobile() {
        return this.deviceDetector.isMobile();
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
    }
    showH1HomeTitle() {
        return this.screenWidth >= 999;
    }
    platformIsIosChrome() {
        return this.platformHelper.isIosChrome();
    }
    platformIsIosSafari() {
        return this.platformHelper.isIosSafari();
    }
    presentAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: 'SiiLA SPOT Mobile',
                cssClass: 'header-alert-prompt',
                message: 'To install the app, please open our site using the Safari browser and click install App to proceed.'
            });
            yield alert.present();
        });
    }
    openLoginDialog() {
        const loginData = {
            url: this.router.url,
            customText: this.customLoginText
        };
        this.loginFormDialog.openDialog(loginData);
    }
    back() {
        window.history.back();
    }
    goToHomePage() {
        return this.commonService.goToHomePage();
    }
    changeBorder() {
        this.showBorder = true;
        setTimeout(() => {
            this.showBorder = false;
        }, 1000);
    }
    navigateToRegister() {
        //this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        /* if (this.router.url !== '/register') {
          this.router.navigateByUrl('/register');
        } */
        this.dialog.open(RegisterFormComponent, {
            height: 'auto',
            width: '350px',
            maxWidth: 'none'
        });
    }
    checkRoute() {
        if (this.router.url !== '/register') {
            return false;
        }
        else {
            return true;
        }
    }
    logOut(event) {
        // Clear search on landing page
        this.listingService.updateModel({
            propertyType: null,
            listingType: null,
            stateId: null,
            cityId: null,
            keywordText: ''
        });
        this.auth.logout();
        this.clearBuySpotCart();
        if (this.router.url === '/') {
            this.toggleSideMenu();
        }
    }
    clearBuySpotCart() {
        this.store.dispatch(CartActions.ClearCart({ payload: { items: [] } }));
    }
    isStandAlone() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
}
