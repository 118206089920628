import * as tslib_1 from "tslib";
import { PlatformHelperService } from './helpers/platform-helper.service';
export class WhatsAppService {
    constructor(platformHelperService) {
        this.platformHelperService = platformHelperService;
        this.whatsAppAPI = 'https://wa.me';
    }
    sendMessageViaWhataspp(phoneNumber, defaultMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //const fullPhoneInternationalFormat = phoneNumber.replace(/[+()\s-]+/g, "");
            const fullPhoneInternationalFormat = phoneNumber.replace(/[(\s\D+)]/g, '');
            const encodedText = encodeURIComponent(defaultMsg);
            const phone = Number(fullPhoneInternationalFormat);
            const whatsAppLink = this.buildWhatasppLink(phone, encodedText);
            const whatsAppWindow = window.open(whatsAppLink, '_system');
            setTimeout(() => {
                if (!this.platformHelperService.isDesktopMode()) {
                    whatsAppWindow.close();
                }
            }, 1000);
        });
    }
    buildWhatasppLink(phoneNumber, preFilledMsg) {
        return `${this.whatsAppAPI}/${phoneNumber}?text=${preFilledMsg}&time=${new Date()}`;
    }
}
