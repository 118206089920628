import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImageService } from '@app/core/image.service';
import { faTimes, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';
export class PreviewImagesComponent {
    constructor(data, dialog, imageService) {
        this.data = data;
        this.dialog = dialog;
        this.imageService = imageService;
        this.pag = 0;
        this.slideOptions = {
            initialSlide: 0,
            speed: 400
        };
        this.faTimes = faTimes;
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
        if (this.data._containerInstance._config.data) {
            this.listing = this.data._containerInstance._config.data;
        }
        if (this.listing.listingPhotos) {
            this.photos = this.listing.listingPhotos;
        }
        if (this.listing.buildingListingPhotos) {
            this.photos = this.listing.buildingListingPhotos;
        }
    }
    ngOnInit() { }
    getCroppedOrOriginalImage(listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getPreviousPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const index = yield this.photoSlider.getActiveIndex();
            if (index === 0) {
                this.photoSlider.slideTo(this.photos.length);
                this.pag = this.photos.length - 1;
            }
            else {
                this.pag--;
                this.photoSlider.slidePrev();
            }
        });
    }
    getNextPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const index = yield this.photoSlider.getActiveIndex();
            if (index + 1 === this.photos.length) {
                this.photoSlider.slideTo(0);
                this.pag = 0;
            }
            else {
                this.pag++;
                this.photoSlider.slideNext();
            }
        });
    }
    close() {
        this.dialog.closeAll();
    }
}
