import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '../../core/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { SizeIcon } from '../../models/shared/shared-enums.model';
export class FavoriteSpotComponent {
    constructor(loginFormDialog, favSpotService, router, _ts, auth) {
        this.loginFormDialog = loginFormDialog;
        this.favSpotService = favSpotService;
        this.router = router;
        this._ts = _ts;
        this.auth = auth;
        this.favoriteChange = new EventEmitter();
    }
    ngOnInit() {
        this.faHeart = faHeart;
        this.size = this.size ? this.size : SizeIcon.MD;
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res) => {
            this.customLoginText = res;
        });
    }
    isFavoriteListing() {
        return this.spot ? this.spot.favorite : false;
    }
    setFavoriteLisitng(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopPropagation();
            if (!this.isLoggedIn) {
                this.openLoginDialog()
                    .afterClosed()
                    .subscribe(() => {
                    if (this.isLoggedIn) {
                        this.toggleFavorite();
                    }
                });
            }
            else {
                this.toggleFavorite();
            }
        });
    }
    toggleFavorite() {
        this.favSpotService.toggleFavoriteListing(this.spot, this.spot.favorite || false).subscribe(() => {
            this.favoriteChange.emit({ id: this.spot.id, isFavorite: this.spot.favorite });
        });
    }
    openLoginDialog() {
        const currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    }
    isMdSize() {
        return this.size == SizeIcon.MD;
    }
    isLgSize() {
        return this.size == SizeIcon.LG;
    }
}
