import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { UserActivityType } from '../../models/user-activity.model';
export class UserActivityService {
    constructor(http) {
        this.http = http;
        this.endpoint = '/api/user-activities/';
        this.listingsSearchEndpoint = '/api/user-activities/listings-search';
    }
    saveUserActivity(userActivity) {
        try {
            userActivity.application = 'SPOT';
            return this.http.post(this.endpoint, userActivity).toPromise();
        }
        catch (error) {
            console.error(error);
        }
    }
    trackListingShare(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_SHARE,
                listingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackListingsSearch(listingSearchDTO, searchTagsDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const listingSearchTrackingDTO = {
                    searchDTO: listingSearchDTO,
                    searchTagsDTO: searchTagsDTO
                };
                return yield this.http.post(this.listingsSearchEndpoint, listingSearchTrackingDTO).toPromise();
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    trackVisitedPages(userActivityType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userActivity = {
                module: userActivityType
            };
            this.saveUserActivity(userActivity);
        });
    }
    trackSpotBuildingShare(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_SHARE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackCompanyShare(companyId) {
        if (companyId) {
            const userActivity = {
                module: UserActivityType.COMPANY_SHARE,
                companyId: companyId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackSpotBuildingRequestVisit(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_REQUEST_VISIT,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackSpotBuildingMessage(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_MESSAGE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackSpotBuildingVirtualTour(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_VIRTUAL_TOUR,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackSpotBuildingFavorite(listingId) {
        if (listingId) {
            const userActivity = {
                module: UserActivityType.LISTING_FAVORITE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    }
    trackInstallApp() {
        const userActivity = {
            module: UserActivityType.INSTALL_APP
        };
        this.saveUserActivity(userActivity);
    }
    trackBrokerShare(brokerId) {
        if (brokerId) {
            const userActivity = {
                module: UserActivityType.BROKER_SHARE,
                brokerId: brokerId
            };
            this.saveUserActivity(userActivity);
        }
    }
}
