import * as tslib_1 from "tslib";
import { BuildingFeaturesDTO } from '@app/models/spot-buildings/spot-buildings-features.model';
export class SpotBuildingsUtilService {
    constructor() {
        this.arr = new BuildingFeaturesDTO();
    }
    getAmenitiesBuildings(type, offer, isEntire) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (type === 'industrialBuildings') {
                if (offer == 'L') {
                    let industrialFilters = [
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.twentyFourHourSecurity',
                            fieldName: 'twentyFourHourSecurity'
                        },
                        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                        { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                        { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                        { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                        { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                        { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
                        { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
                        { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.blockAndSteelConstruction',
                            fieldName: 'blockAndSteelConstruction'
                        },
                        { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
                        { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
                        { selected: false, name: 'global.addtSearchFilters.waterHydrant', fieldName: 'waterHydrant' },
                        { selected: false, name: 'global.addtSearchFilters.withinIndustrialPark', fieldName: 'withinIndustrialPark' }
                    ];
                    return industrialFilters;
                }
                else {
                    let industrialFilters = [
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.twentyFourHourSecurity',
                            fieldName: 'twentyFourHourSecurity'
                        },
                        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                        { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                        { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                        { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                        { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                        { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
                        { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
                        { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.blockAndSteelConstruction',
                            fieldName: 'blockAndSteelConstruction'
                        },
                        { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
                        { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
                        { selected: false, name: 'global.addtSearchFilters.bulletProofCabin', fieldName: 'bulletProofCabin' },
                        { selected: false, name: 'global.addtSearchFilters.waterHydrant', fieldName: 'waterHydrant' },
                        { selected: false, name: 'global.addtSearchFilters.withinIndustrialPark', fieldName: 'withinIndustrialPark' }
                    ];
                    if (isEntire) {
                        industrialFilters.push({
                            selected: false,
                            name: 'global.addtSearchFilters.includesOffice',
                            fieldName: 'includesOffice'
                        }, {
                            selected: false,
                            name: 'global.addtSearchFilters.includesRetail',
                            fieldName: 'includesRetail'
                        }, {
                            selected: false,
                            name: 'global.addtSearchFilters.coldStorage',
                            fieldName: 'coldStorage'
                        }, {
                            selected: false,
                            name: 'global.addtSearchFilters.fullBuildOut',
                            fieldName: 'fullBuildOut'
                        }, {
                            selected: false,
                            name: 'global.addtSearchFilters.skyLights',
                            fieldName: 'skyLights'
                        });
                    }
                    return industrialFilters;
                }
            }
            if (type === 'officeBuildings') {
                if (offer == 'L') {
                    let buildingsOfficeFilters = [
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.twentyFourHourSecurity',
                            fieldName: 'twentyFourHourSecurity'
                        },
                        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                        { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                        { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                        { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                        { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                        { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.centralAirConditioning',
                            fieldName: 'centralAirConditioning'
                        },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.petFriendly',
                            fieldName: 'petFriendly'
                        },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.accessToShowers',
                            fieldName: 'accessToShowers'
                        }
                    ];
                    return buildingsOfficeFilters;
                }
                else {
                    if (offer == 'C') {
                        let buildingsOfficeFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                            { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.centralAirConditioning',
                                fieldName: 'centralAirConditioning'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.petFriendly',
                                fieldName: 'petFriendly'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.mailAndPackage',
                                fieldName: 'mailAndPackage'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.enhancedCleaning',
                                fieldName: 'enhancedCleaning'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.barista',
                                fieldName: 'barista'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.cafeteria',
                                fieldName: 'cafeteria'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.accessToShowers',
                                fieldName: 'accessToShowers'
                            }
                        ];
                        return buildingsOfficeFilters;
                    }
                    else {
                        let buildingsOfficeFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.centralAirConditioning',
                                fieldName: 'centralAirConditioning'
                            }
                        ];
                        return buildingsOfficeFilters;
                    }
                }
            }
        });
    }
    checkFields(allFields) {
        this.arr = new BuildingFeaturesDTO();
        Object.keys(allFields).forEach(key => {
            if (allFields[key].fieldName === 'twentyFourHourSecurity') {
                this.arr.twentyFourHourSecurity = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'electricGenerator') {
                this.arr.electricGenerator = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'heliport') {
                this.arr.heliport = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sprinklers') {
                this.arr.sprinklers = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'leedStatus') {
                this.arr.leedStatus = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'singleTenant') {
                this.arr.singleTenant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'multiTenant') {
                this.arr.multiTenant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'bicycleRack') {
                this.arr.bicycleRack = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'fiberOptic') {
                this.arr.fiberOptic = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'centralAirConditioning') {
                this.arr.centralAirConditioning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'petFriendly') {
                this.arr.petFriendly = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'truckYard') {
                this.arr.truckYard = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'crossDocking') {
                this.arr.crossDocking = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sluiceGate') {
                this.arr.sluiceGate = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'blockAndSteelConstruction') {
                this.arr.blockAndSteelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'blockConstruction') {
                this.arr.blockConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'steelConstruction') {
                this.arr.steelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'includesOffice') {
                this.arr.includesOffice = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'includesRetail') {
                this.arr.includesRetail = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'coldStorage') {
                this.arr.coldStorage = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'fullBuildOut') {
                this.arr.fullBuildOut = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'skyLights') {
                this.arr.skyLights = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'bulletProofCabin') {
                this.arr.bulletProofCabin = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'mailAndPackage') {
                this.arr.mailPackageHandling = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'enhancedCleaning') {
                this.arr.enhancedCleaning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'barista') {
                this.arr.barista = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'cafeteria') {
                this.arr.cafeteria = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'waterHydrant') {
                this.arr.waterHydrant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'withinIndustrialPark') {
                this.arr.withinIndustrialPark = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'accessToShowers') {
                this.arr.accessToShowers = allFields[key].selected;
            }
        });
        let filters = this.arr;
        return filters;
    }
    highlightWrap(s, className = '') {
        return `<span class="highlight ${className}">${s}</span>`;
    }
    normalize(s) {
        return s.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    }
    scapeRegexCharacters(search = '') {
        const pattern = search
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
            .split(' ')
            .filter((t) => {
            return t.length > 0;
        })
            .map((word) => this.normalize(word))
            .join('|');
        return pattern;
    }
}
